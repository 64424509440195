import React, { useState, useEffect } from 'react';
import { Modal } from '@mantine/core';
import * as api from '../../redux/api';
import moment from 'moment';

const ModalTemuanDetail = ({ kode, openModalDetail, setOpenModalDetail }) => {
  const [dataTemuan, setDataTemuan] = useState({});

  useEffect(() => {
    if (kode !== '') {
      const getDataTemuanByKode = async () => {
        await api.temuanByKode({ satpamtemuankode: kode }).then((res) => {
          setDataTemuan(res.data.Data[0]);
        });
      };

      getDataTemuanByKode();
    }
  }, [kode]);

  return (
    <Modal
      opened={openModalDetail}
      size="xl"
      overflow="inside"
      centered
      closeOnClickOutside
      onClose={() => setOpenModalDetail(false)}>
      <div className="text-center text-lg font-bold mb-5">Detail Temuan</div>
      <div>
        <div className="text-dark scrollbar-thin px-5">
          <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
            <div className="md:w-3/5 w-full font-bold">Kode Temuan</div>
            <div className="w-full">{dataTemuan?.satpamtemuankode}</div>
          </div>
          <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
            <div className="md:w-3/5 w-full font-bold">Kode Perusahaan</div>
            <div className="w-full">{dataTemuan?.satpamtemuansatpamperusahaankode}</div>
          </div>
          <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
            <div className="md:w-3/5 w-full font-bold">Nama Perusahaan</div>
            <div className="w-full">{dataTemuan?.satpamperusahaannama}</div>
          </div>
          <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
            <div className="md:w-3/5 w-full font-bold">Kode Personil</div>
            <div className="w-full">{dataTemuan?.satpamtemuansatpamkode}</div>
          </div>
          <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
            <div className="md:w-3/5 w-full font-bold">Nama Personil</div>
            <div className="w-full">{dataTemuan?.satpamnamalengkap}</div>
          </div>
          <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
            <div className="md:w-3/5 w-full font-bold">Tanggal Melaporkan</div>
            <div className="w-full">
              {moment(dataTemuan?.satpamtemuantanggalmelaporkan).format('DD MMM YYYY HH:mm') || '-'}
            </div>
          </div>
          <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
            <div className="md:w-3/5 w-full font-bold">Tanggal Aktifitas</div>
            <div className="w-full">
              {moment(dataTemuan?.satpamtemuantanggalaktifitas).format('DD MMM YYYY HH:mm') || '-'}
            </div>
          </div>
          <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
            <div className="md:w-3/5 w-full font-bold">Kategori</div>
            <div className="w-full">{dataTemuan?.satpamtemuankategorinama}</div>
          </div>
          <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
            <div className="md:w-3/5 w-full font-bold">Uraian temuan</div>
            <div className="w-full">{dataTemuan?.satpamtemuanuraian}</div>
          </div>
          <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
            <div className="md:w-3/5 w-full font-bold">Uraian Tindakan</div>
            <div className="w-full">{dataTemuan?.satpamtemuantindakan}</div>
          </div>
          <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
            <div className="md:w-3/5 w-full font-bold">Hasil Tindakan</div>
            <div className="w-full">{dataTemuan?.satpamtemuanhasil}</div>
          </div>
          <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
            <div className="md:w-3/5 w-full font-bold">Potensi Temuan</div>
            <div className="w-full">
              {dataTemuan?.satpamtemuanpotensi !== null
                ? dataTemuan?.satpamtemuanpotensi
                : 'Belum ada potensi'}
            </div>
          </div>
          <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
            <div className="md:w-3/5 w-full font-bold">Analisis Temuan</div>
            <div className="w-full">
              {dataTemuan?.satpamtemuananalisis !== null
                ? dataTemuan?.satpamtemuananalisis
                : 'Belum ada analisis'}
            </div>
          </div>
          <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
            <div className="md:w-3/5 w-full font-bold">Kesimpulan Temuan</div>
            <div className="w-full">
              {dataTemuan?.satpamtemuankesimpulan !== null
                ? dataTemuan?.satpamtemuankesimpulan
                : 'Belum ada kesimpulan'}
            </div>
          </div>
          <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
            <div className="md:w-3/5 w-full font-bold">Saran dan Rekomendasi</div>
            <div className="w-full">
              {dataTemuan?.satpamtemuanrekomendasi !== null
                ? dataTemuan?.satpamtemuanrekomendasi
                : 'Belum ada rekomendasi'}
            </div>
          </div>
          {/* <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
            <div className="md:w-3/5 w-full font-bold">Latitude</div>
            <div className="w-full">{dataTemuan?.satpamtemuanlatitude || '-'}</div>
          </div>
          <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
            <div className="md:w-3/5 w-full font-bold">Longitude</div>
            <div className="w-full">{dataTemuan?.satpamtemuanlongitude || '-'}</div>
          </div> */}
          <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
            <div className="md:w-3/5 w-full font-bold">Alamat temuan</div>
            <div className="w-full">{dataTemuan?.satpamtemuanalamat}</div>
          </div>
          <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
            <div className="md:w-3/5 w-full font-bold">Patokan</div>
            <div className="w-full">{dataTemuan?.satpamtemuanalamatpatokan}</div>
          </div>
          {dataTemuan?.satpamtemuanfotosatu?.split('/data-temuan/')[1].length > 0 && (
            <>
              <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
                <div className="md:w-3/5 w-full font-bold">Foto 1</div>
                <div className="w-full">
                  <img
                    className="w-full h-64 object-contain"
                    src={dataTemuan?.satpamtemuanfotosatu}
                    alt=""
                  />
                </div>
              </div>
              <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
                <div className="md:w-3/5 w-full font-bold">Ket. Foto 1</div>
                <div className="w-full">{dataTemuan?.satpamtemuanketeranganfotosatu}</div>
              </div>
            </>
          )}
          {dataTemuan?.satpamtemuanfotodua?.split('/data-temuan/')[1].length > 0 && (
            <>
              <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
                <div className="md:w-3/5 w-full font-bold">Foto 1</div>
                <div className="w-full">
                  <img
                    className="w-full h-64 object-contain"
                    src={dataTemuan?.satpamtemuanfotodua}
                    alt=""
                  />
                </div>
              </div>
              <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
                <div className="md:w-3/5 w-full font-bold">Ket. Foto 2</div>
                <div className="w-full">{dataTemuan?.satpamtemuanketeranganfotodua}</div>
              </div>
            </>
          )}
          {dataTemuan?.satpamtemuanfototiga?.split('/data-temuan/')[1].length > 0 && (
            <>
              <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
                <div className="md:w-3/5 w-full font-bold">Foto 3</div>
                <div className="w-full">
                  <img
                    className="w-full h-64 object-contain"
                    src={dataTemuan?.satpamtemuanfototiga}
                    alt=""
                  />
                </div>
              </div>
              <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
                <div className="md:w-3/5 w-full font-bold">Ket. Foto 3</div>
                <div className="w-full">{dataTemuan?.satpamtemuanketeranganfototiga}</div>
              </div>
            </>
          )}
          {dataTemuan?.satpamtemuanfotoempat?.split('/data-temuan/')[1].length > 0 && (
            <>
              <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
                <div className="md:w-3/5 w-full font-bold">Foto 4</div>
                <div className="w-full">
                  <img
                    className="w-full h-64 object-contain"
                    src={dataTemuan?.satpamtemuanfotoempat}
                    alt=""
                  />
                </div>
              </div>
              <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
                <div className="md:w-3/5 w-full font-bold">Ket. Foto 4</div>
                <div className="w-full">{dataTemuan?.satpamtemuanketeranganfotoempat}</div>
              </div>
            </>
          )}
          {dataTemuan?.satpamtemuanfotolima?.split('/data-temuan/')[1].length > 0 && (
            <>
              <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
                <div className="md:w-3/5 w-full font-bold">Foto 5</div>
                <div className="w-full">
                  <img
                    className="w-full h-64 object-contain"
                    src={dataTemuan?.satpamtemuanfotolima}
                    alt=""
                  />
                </div>
              </div>
              <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
                <div className="md:w-3/5 w-full font-bold">Ket. Foto 5</div>
                <div className="w-full">{dataTemuan?.satpamtemuanketeranganfotolima}</div>
              </div>
            </>
          )}
          <div className="flex justify-end mt-5">
            <div
              className="w-fit text-gray-50 py-2 px-3 rounded-md text-xs bg-dark hover:opacity-80 cursor-pointer"
              onClick={() => setOpenModalDetail(false)}>
              Kembali
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ModalTemuanDetail;
