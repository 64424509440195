import moment from 'moment';
import React from 'react';
import Headerlaporan from '../general/HeaderLaporan';
import TimelineSatpamProses from '../general/TimelineSatpamProses';

const TableCetakKejadianPerKej = ({ data }) => {
  return (
    <div className="w-full">
      <div className="flex w-full">
        <Headerlaporan />
        <div className="w-full px-5 bg-black flex flex-col items-end justify-center text-white">
          <div className="text-[18px] font-bold">LAPORAN KEJADIAN</div>
          <div className="text-[14px]">{data.satpamperusahaannama}</div>
        </div>
      </div>
      <div className="m-5">
        <table className="border-gray-50" id="kegiatanHarianPrint">
          <thead className="bg-primary font-bold text-white text-[12px]">
            <tr>
              <th colSpan="5" className="border border-gray-300 py-2 px-2 text-left">
                <p className="font-semibold text-[18px]">#{data?.satpamkejadiankode}</p>
              </th>
            </tr>
          </thead>
          <tbody className="text-black">
            <tr>
              <td
                colSpan="2"
                className="w-[20%] border border-gray-300 py-2 px-2 text-left text-primary font-semibold"
                height="5px">
                {moment(data?.satpamkejadiantanggalmelaporkan).format('DD MMM YYYY HH:mm')}
              </td>
              <td
                colSpan="2"
                rowSpan="4"
                className="border border-gray-300 py-2 px-2 text-left align-top">
                <h4 className="font-semibold">DETAIL KEJADIAN</h4>
                <p className="mb-2">{data?.satpamkejadianuraian}</p>
                <h4 className="font-semibold">TINDAKAN</h4>
                <p className="mb-2">{data?.satpamkejadiantindakan}</p>
                <h4 className="font-semibold">HASIL TINDAKAN</h4>
                <p>{data?.satpamkejadianhasil}</p>
              </td>
              <td
                rowSpan="5"
                className="w-[20%] border border-gray-300 py-2 px-2 text-left align-top">
                <h4 className="font-semibold mb-2">DETAIL PROSES</h4>
                <TimelineSatpamProses data={data?.satpam_proses ? data?.satpam_proses : ''} />
              </td>
            </tr>
            <tr>
              <td colSpan="2" className="border border-gray-300 py-2 px-2 text-left">
                <span className="font-semibold">PATOKAN </span>: {data?.satpamkejadianalamatpatokan}
                <br />
                <span className="font-semibold">ALAMAT LENGKAP</span> : {data?.satpamkejadianalamat}
              </td>
            </tr>
            <tr>
              <td className="border border-gray-300 py-2 px-2 text-left w-[25%] align-top">
                <p className="font-semibold">KATEGORI :</p>
                <p className="mb-2">{data?.satpamkejadiankategori}</p>
                <p className="font-semibold">SUB KATEGORI :</p>
                <p>{data?.satpamkejadiankategoridetail}</p>
              </td>
              <td
                rowSpan="2"
                className="border border-gray-300 py-2 px-2 text-left w-[15%] align-top">
                <h4 className="text-md font-semibold">PELAKU</h4>
                <p className="mb-2">{data?.satpamkejadianpelaku}</p>
                <h4 className="text-md font-semibold">KORBAN</h4>
                <p className="mb-2">{data?.satpamkejadiankorban}</p>
                <h4 className="text-md font-semibold">SAKSI</h4>
                <p>{data?.satpamkejadiansaksi}</p>
              </td>
            </tr>
            <tr>
              <td className="border border-gray-300 py-2 px-2 text-left align-top">
                <h4 className="text-md"><span className='font-semibold'>PELAPOR</span> : {data?.satpamnamalengkap}</h4>
              </td>
            </tr>
            <tr>
              <td colSpan="3" className="border border-gray-300 p-5">
                <div className="mx-auto">
                  <div className="flex flex-wrap -m-1 md:-m-2">
                    {data?.satpamkejadianfotosatu?.split('/data-kejadian/')[1] && (
                      <div className="flex flex-wrap w-1/5">
                        <div className="w-full p-1 md:p-2">
                          <div className="min-h-52 max-w-sm bg-white rounded-md border border-gray-200 shadow-md break-all">
                            <img
                              className="rounded-t-md h-36 object-cover w-full"
                              src={data?.satpamkejadianfotosatu}
                              alt=""
                            />
                            <div className="p-3">
                              <p className="mb-2 text-xs">{data?.satpamkejadianketeranganfotosatu}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {data?.satpamkejadianfotodua?.split('/data-kejadian/')[1] && (
                      <div className="flex flex-wrap w-1/5">
                        <div className="w-full p-1 md:p-2">
                          <div className="min-h-52 max-w-sm bg-white rounded-md border border-gray-200 shadow-md break-all">
                            <img
                              className="rounded-t-md h-36 object-cover w-full"
                              src={data?.satpamkejadianfotodua}
                              alt=""
                            />
                            <div className="p-3">
                              <p className="mb-2 text-xs">{data?.satpamkejadianketeranganfotodua}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {data?.satpamkejadianfototiga?.split('/data-kejadian/')[1] && (
                      <div className="flex flex-wrap w-1/5">
                        <div className="w-full p-1 md:p-2">
                          <div className="min-h-52 max-w-sm bg-white rounded-md border border-gray-200 shadow-md break-all">
                            <img
                              className="rounded-t-md h-36 object-cover w-full"
                              src={data?.satpamkejadianfototiga}
                              alt=""
                            />
                            <div className="p-3">
                              <p className="mb-2 text-xs">{data?.satpamkejadianketeranganfototiga}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {data?.satpamkejadianfotoempat?.split('/data-kejadian/')[1] && (
                      <div className="flex flex-wrap w-1/5">
                        <div className="w-full p-1 md:p-2">
                          <div className="min-h-52 max-w-sm bg-white rounded-md border border-gray-200 shadow-md break-all">
                            <img
                              className="rounded-t-md h-36 object-cover w-full"
                              src={data?.satpamkejadianfotoempat}
                              alt=""
                            />
                            <div className="p-3">
                              <p className="mb-2 text-xs">{data?.satpamkejadianketeranganfotoempat}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {data?.satpamkejadianfotolima?.split('/data-kejadian/')[1] && (
                      <div className="flex flex-wrap w-1/5">
                        <div className="w-full p-1 md:p-2">
                          <div className="min-h-52 max-w-sm bg-white rounded-md border border-gray-200 shadow-md break-all">
                            <img
                              className="rounded-t-md h-36 object-cover w-full"
                              src={data?.satpamkejadianfotolima}
                              alt=""
                            />
                            <div className="p-3">
                              <p className="mb-2 text-xs">{data?.satpamkejadianketeranganfotolima}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </td>
            </tr>
            <tr className="w-full">
              <td colSpan="2" className="border border-gray-300 p-2 text-left align-top">
                <p className="font-semibold">POTENSI KEJADIAN :</p>
                <p>
                  {data?.satpamkejadianpotensi !== null
                    ? data?.satpamkejadianpotensi
                    : 'Belum ada potensi'}
                </p>
                <p className="font-semibold mt-2">ANALISIS KEJADIAN :</p>
                <p>
                  {data?.satpamkejadiananalisis !== null
                    ? data?.satpamkejadiananalisis
                    : 'Belum ada analisis'}
                </p>
              </td>
              <td colSpan="2" className="border border-gray-300 p-2 text-left align-top">
                <p className="font-semibold">KESIMPULAN KEJADIAN :</p>
                <p>
                  {data?.satpamkejadiankesimpulan !== null
                    ? data?.satpamkejadiankesimpulan
                    : 'Belum ada kesimpulan'}
                </p>
                <p className="font-semibold mt-2">SARAN DAN REKOMENDASI :</p>
                <p>
                  {data?.satpamkejadianrekomendasi !== null
                    ? data?.satpamkejadianrekomendasi
                    : 'Belum ada rekomendasi'}
                </p>
              </td>
              <td className="border border-gray-300 px-2 py-2 text-center align-middle">
                <div>Tanda Tangan</div>
                <div className="border mt-28 mb-10 mx-5" />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="text-center w-full my-2 text-black">
        <b>Copyright@2022</b>
        <br />
        <b>PT. Kalpika Loka Persada</b>
      </div>
    </div>
  );
};

export default TableCetakKejadianPerKej;
