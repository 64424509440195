import React, { useState, useRef } from 'react';
import { Modal } from '@mantine/core';
import QRCode from 'react-qr-code';
import html2canvas from 'html2canvas';
import { ClipLoader } from 'react-spinners';

export default function ModalPerusahaanQR({ qrDetail, openModalQR, setOpenModalQR }) {
  const [loading, setLoading] = useState(false);

  const printRef = useRef();

  const handleDownloadImage = async () => {
    setLoading(true);
    const element = printRef.current;
    const canvas = await html2canvas(element);

    const data = canvas.toDataURL('image/png');
    const link = document.createElement('a');

    if (typeof link.download === 'string') {
      link.href = data;
      link.download = `Checkpoint - ${qrDetail.nama}.png`;

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      window.open(data);
    }
    setLoading(false);
  };

  return (
    <Modal
      opened={openModalQR}
      size="md"
      title={qrDetail.nama}
      centered
      closeOnClickOutside
      onClose={() => setOpenModalQR(false)}>
      <div
        ref={printRef}
        className="flex flex-col h-[400px] md:h-[500px] w-full bg-qrDetail bg-contain bg-no-repeat bg-center justify-between rounded-lg">
        <div className="flex flex-col justify-center items-center h-3/4">
          <div className="flex flex-col justify-center items-center mx-auto w-fit h-fit p-2 mt-36 bg-white rounded">
            <QRCode size={180} value={qrDetail.kode} viewBox={`0 0 180 180`} />
            <p className="text-center text-sm font-semibold uppercase my-1">{qrDetail.nama}</p>
          </div>
        </div>
        <div className="w-full flex flex-col gap-3 justify-end h-1/4">
          <div className="flex w-full h-7 bg-primary/50"></div>
          <div className="flex flex-col w-full pt-1 pb-3 bg-primary/50 rounded-b-2xl text-white justify-center items-center">
            <p className="text-xs">Copyright</p>
            <p className="text-sm font-semibold">PT. Kalpika Loka Persada</p>
          </div>
        </div>
      </div>
      <div className="flex justify-center mt-2">
        <button type="button" className="btnAksi py-1 text-sm" onClick={handleDownloadImage}>
          {loading ? (
            <ClipLoader color="white" loading={true} size={20} speedMultiplier={0.7} />
          ) : (
            'Download as Image'
          )}
        </button>
      </div>
    </Modal>
  );
}
