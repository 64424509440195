import { useState, useEffect } from 'react';

export default function FilterPersonil({ data, setFilterData }) {
  const [status, setStatus] = useState('Aktif');

  useEffect(() => {
    if (status === 'Aktif') setFilterData(data?.filter((item) => item.satpamstatus === 'Aktif'));
    else if (status === 'Tidak Aktif')
      setFilterData(data?.filter((item) => item.satpamstatus === 'Tidak Aktif'));
    else setFilterData(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  return (
    <div className="flex flex-col w-full md:w-auto gap-1 md:my-3 my-2 pr-5 md:pr-2 pl-5">
      <div className="font-semibold text-xs mr-2 mb-1 md:mb-0">Filter personil</div>
      <div className="md:flex md:flex-row gap-2">
        <div className="flex flex-col justify-center items-center">
          <select
            className="text-xs cursor-pointer inputForm w-full"
            name="type"
            defaultValue="Aktif"
            onChange={(e) => setStatus(e.target.value)}>
            <option value="">All</option>
            <option value="Aktif">Aktif</option>
            <option value="Tidak Aktif">Tidak Aktif</option>
          </select>
        </div>
      </div>
    </div>
  );
}
