import React, { useState, useEffect } from 'react';
import * as api from '../../redux/api';
import Map, {
  FullscreenControl,
  Marker,
  NavigationControl,
  Popup,
  ScaleControl
} from 'react-map-gl';
import { MdLocationOn } from 'react-icons/md';
import mapboxgl from 'mapbox-gl'; // This is a dependency of react-map-gl even if you didn't explicitly install it

import 'mapbox-gl/dist/mapbox-gl.css';

// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

const initialViewState = {
  latitude: '-6.273934189776867',
  longitude: '106.773589623861',
  zoom: '14'
};

export default function MapBox({ height, data }) {
  // Get Profile
  const profile = localStorage.profile && JSON.parse(localStorage.profile);

  const [currentPlaceId, setCurrentPlaceId] = useState('');
  const [openPopup, setOpenPopup] = useState(false);
  const [viewState, setViewState] = useState(initialViewState);

  useEffect(() => {
    const getDetailPerusahaan = async () => {
      await api
        .getDetailPerusahaan(profile.user.adminpetugasperusahaankode)
        .then((res) => {
          if (res.data.Status) {
            setViewState((prev) => ({
              ...prev,
              latitude: res.data.Data[0].satpamperusahaanlatitude,
              longitude: res.data.Data[0].satpamperusahaanlongitude
            }));
          }
        })
        .catch((err) => {});
    };
    getDetailPerusahaan();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleMarkerClick = (id) => {
    setCurrentPlaceId(isNaN(id) ? parseInt(id) : id);
    setOpenPopup(true);
  };

  return (
    <div className="relative rounded-lg overflow-hidden">
      <Map
        {...viewState}
        initialViewState={viewState}
        onViewportChange={(viewport) => setViewState(viewport)}
        onMove={(evt) => setViewState(evt.viewState)}
        style={{ width: '100%', height }}
        mapStyle="mapbox://styles/mapbox/streets-v12"
        mapboxAccessToken={process.env.REACT_APP_MAP_TOKEN}>
        <FullscreenControl />
        <NavigationControl />
        <ScaleControl position="top-left" />
        {data?.map((item) => (
          <div key={item.satpamid}>
            {/* Marker */}
            <Marker longitude={item.satpamlongitude} latitude={item.satpamlatitude} anchor="bottom">
              <MdLocationOn
                size={44}
                className="text-primary z-10 cursor-pointer"
                onClick={() => handleMarkerClick(item.satpamid)}
              />
            </Marker>
            {/* Pop Up */}
            {openPopup && currentPlaceId === item.satpamid && (
              <Popup
                longitude={item.satpamlongitude}
                latitude={item.satpamlatitude}
                anchor="bottom"
                closeButton={false}
                closeOnClick={false}
                closeOnMove={true}
                onClose={() => setOpenPopup(false)}>
                <div className="flex gap-1 flex-col">
                  <div className="flex gap-2">
                    <div>
                      <i className="fa-solid fa-circle-user"></i>
                    </div>
                    <div>{item.satpamnamalengkap}</div>
                  </div>
                  <div className="flex gap-2">
                    <div>
                      <i className="fa-solid fa-phone"></i>
                    </div>
                    <div>{item.satpamnohp}</div>
                  </div>
                </div>
              </Popup>
            )}
          </div>
        ))}
      </Map>
      <div className="bg-gray-50 text-dark pb-6 py-4 px-10 font-bold rounded-tr-lg absolute bottom-0 left-0">
        Man Power <span className="">{data?.length}</span>
      </div>
    </div>
  );
}
