import React, { useState } from 'react';
import * as api from '../../redux/api';
import Swal from 'sweetalert2';
import { ClipLoader } from 'react-spinners';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { satpamSchema } from '../../utils/schemaValidation';
import useImagePreview from '../../hooks/useImagePreview';
import imageCompression from 'browser-image-compression';

export default function SatpamCreate() {
  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    reset
  } = useForm({
    resolver: yupResolver(satpamSchema)
  });

  const compressOptions = {
    maxSizeMB: 1,
    maxWidthOrHeight: 1920,
    useWebWorker: true
  };

  const [fotoSatpamPreview] = useImagePreview(watch('satpamfoto'));
  const [fotoBankSatpamPreview] = useImagePreview(watch('satpambankfoto'));

  const onSubmit = async (data) => {
    setLoading(true);
    const formData = new FormData();

    if (data.satpamfoto.length > 0) {
      const compressedFile = await imageCompression(data.satpamfoto[0], compressOptions);
      formData.append('satpamfoto', compressedFile, compressedFile.name);
    }

    if (data.satpambankfoto.length > 0) {
      const compressedFile = await imageCompression(data.satpambankfoto[0], compressOptions);
      formData.append('satpambankfoto', compressedFile, compressedFile.name);
    }

    for (const key in data) {
      if (key === 'field') {
        formData.append(key, data[key][1]);
      } else {
        formData.append(key, data[key]);
      }
    }

    await api
      .createSatpam(formData)
      .then((res) => {
        Swal.fire('Good job!', res.data.message, 'success');
        setLoading(false);
        reset();
        reset({ satpamfoto: '', satpambankfoto: '' });
      })
      .catch((error) => {
        setLoading(false);
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: error.response.data.message
        });
      });
  };

  return (
    <div className="relative px-4 md:px-10 mx-auto w-full h-full -mt-8 bg-gray-50">
      <div className="w-full">
        <div className="w-full py-5 bg-light rounded-lg shadow-black/20 shadow-lg flex justify-center items-center mb-5 text-lg text-center font-semibold">
          Registrasi Personil
        </div>
        <form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data" className="w-full">
          <div className="flex flex-wrap md:flex-nowrap gap-5 text-sm">
            <div className="w-full md:w-1/2 bg-light p-5 rounded-lg shadowAll flex flex-col">
              {/* NIP */}
              <div className="md:flex mb-3 justify-arround items-center">
                <div className="md:w-1/3">
                  <label htmlFor="satpamnip" className="block font-semibold mb-1 md:mb-0">
                    NIP
                  </label>
                </div>
                <div className="md:w-2/3">
                  <input
                    type="text"
                    id="satpamnip"
                    placeholder="Tulis NIP personil"
                    className={`w-full text-sm ${errors.satpamnip ? 'invalidForm' : 'inputForm'}`}
                    {...register('satpamnip')}
                  />
                  <p className="text-errorText text-xs">{errors.satpamnip?.message}</p>
                </div>
              </div>

              {/* Nama lengkap */}
              <div className="md:flex mb-3 justify-arround items-center">
                <div className="md:w-1/3">
                  <label htmlFor="satpamnamalengkap" className="block font-semibold mb-1 md:mb-0">
                    Nama Lengkap
                  </label>
                </div>
                <div className="md:w-2/3">
                  <input
                    type="text"
                    id="satpamnamalengkap"
                    placeholder="Tulis nama lengkap"
                    className={`w-full text-sm ${
                      errors.satpamnamalengkap ? 'invalidForm' : 'inputForm'
                    }`}
                    {...register('satpamnamalengkap')}
                  />
                  <p className="text-errorText text-xs">{errors.satpamnamalengkap?.message}</p>
                </div>
              </div>

              {/* Nomor Hp */}
              <div className="md:flex mb-3 justify-arround items-center">
                <div className="md:w-1/3">
                  <label htmlFor="satpamnohp" className="block font-semibold mb-1 md:mb-0">
                    Nomor HP
                  </label>
                </div>
                <div className="md:w-2/3">
                  <input
                    type="text"
                    placeholder="Tulis nomor hp"
                    className={`w-full text-sm ${errors.satpamnohp ? 'invalidForm' : 'inputForm'}`}
                    {...register('satpamnohp')}
                  />
                  <p className="text-errorText text-xs">{errors.satpamnohp?.message}</p>
                </div>
              </div>

              {/* Email */}
              <div className="md:flex mb-3 justify-arround items-center">
                <div className="md:w-1/3">
                  <label htmlFor="satpamemail" className="block font-semibold mb-1 md:mb-0">
                    Email
                  </label>
                </div>
                <div className="md:w-2/3">
                  <input
                    type="email"
                    placeholder="Tulis email personil"
                    className={`w-full text-sm ${errors.satpamemail ? 'invalidForm' : 'inputForm'}`}
                    {...register('satpamemail')}
                  />
                  <p className="text-errorText text-xs">{errors.satpamemail?.message}</p>
                </div>
              </div>

              {/* Jenis Kelamin */}
              <div className="md:flex mb-3 justify-arround items-center">
                <div className="md:w-1/3">
                  <label htmlFor="satpamjeniskelamin" className="block font-semibold mb-1 md:mb-0">
                    Jenis Kelamin
                  </label>
                </div>
                <div className="md:w-2/3">
                  <select
                    id="satpamjeniskelamin"
                    defaultValue="Laki-laki"
                    className={`w-full text-sm ${
                      errors.satpamjeniskelamin ? 'invalidForm' : 'inputForm'
                    }`}
                    {...register('satpamjeniskelamin')}>
                    <option value="" disabled>
                      Pilih jenis kelamin
                    </option>
                    <option value="Laki-laki">Laki-laki</option>
                    <option value="Perempuan">Perempuan</option>
                  </select>
                  <p className="text-primary text-xs">{errors.satpamjeniskelamin?.message}</p>
                </div>
              </div>

              {/* Foto Profil */}
              <div className="md:flex justify-arround items-center">
                <div className="md:w-1/3">
                  <label htmlFor="satpamfoto" className="block font-semibold mb-1 md:mb-0">
                    Foto Profil (Opsional)
                  </label>
                </div>
                <div className="flex flex-wrap md:w-2/3 w-full">
                  <div className="w-full">
                    <div className="h-auto p-3 bg-white rounded-md border border-gray-200 shadow-md">
                      <div className="flex flex-col w-full">
                        <label className="p-2 flex flex-col w-full h-56 items-center justify-center border-4 border-dashed hover:bg-gray-100 hover:border-gray-300 cursor-pointer">
                          {fotoSatpamPreview ? (
                            <div className="flex flex-col items-center justify-center">
                              <img
                                src={fotoSatpamPreview}
                                className="w-full h-24 object-contain"
                                alt="preview"
                              />
                            </div>
                          ) : (
                            <div className="flex flex-col items-center justify-center pt-2">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="w-12 h-12 text-gray-400 group-hover:text-gray-600"
                                viewBox="0 0 20 20"
                                fill="currentColor">
                                <path
                                  fillRule="evenodd"
                                  d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z"
                                  clipRule="evenodd"
                                />
                              </svg>
                              <p className="pt-1 text-sm tracking-wider text-gray-400 group-hover:text-gray-600">
                                Click here
                              </p>
                            </div>
                          )}
                          <input
                            type="file"
                            className="opacity-0"
                            {...register('satpamfoto')}
                            accept="image/*"
                          />
                        </label>
                      </div>
                      <p className="text-primary text-xs">{errors.satpamfoto?.message}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Right Section */}
            <div className="w-full md:w-1/2 bg-light p-5 rounded-lg shadowAll flex flex-col ">
              {/* Tipe */}
              <div className="md:flex mb-3 justify-arround items-center">
                <div className="md:w-1/3">
                  <label htmlFor="satpamtipe" className="block font-semibold mb-1 md:mb-0">
                    Tipe Satpam
                  </label>
                </div>
                <div className="md:w-2/3">
                  <select
                    className={`w-full text-sm cursor-pointer ${
                      errors.satpamtipe ? 'invalidForm' : 'inputForm'
                    }`}
                    {...register('satpamtipe')}>
                    <option value="" disabled>
                      Pilih tipe personil
                    </option>
                    <option value="Utama">Utama</option>
                    <option value="Cadangan">Cadangan</option>
                  </select>
                  <p className="text-primary text-xs">{errors.satpamtipe?.message}</p>
                </div>
              </div>

              {/* Jabatan */}
              <div className="md:flex mb-3 justify-arround items-center">
                <div className="md:w-1/3">
                  <label htmlFor="satpamjabatan" className="block font-semibold mb-1 md:mb-0">
                    Jabatan (Opsional)
                  </label>
                </div>
                <div className="md:w-2/3">
                  <input
                    type="text"
                    placeholder="Tulis jabatan personil"
                    className={`w-full text-sm ${
                      errors.satpamjabatan ? 'invalidForm' : 'inputForm'
                    }`}
                    {...register('satpamjabatan')}
                  />
                  <p className="text-primary text-xs">{errors.satpamjabatan?.message}</p>
                </div>
              </div>

              {/* Pendidikan */}
              <div className="md:flex mb-3 justify-arround items-center">
                <div className="md:w-1/3">
                  <label
                    htmlFor="satpampendidikanjenjang"
                    className="block font-semibold mb-1 md:mb-0">
                    Pendidikan (Opsional)
                  </label>
                </div>
                <div className="md:w-2/3">
                  <select
                    id="satpampendidikanjenjang"
                    defaultValue=""
                    className={`w-full text-sm ${
                      errors.satpampendidikanjenjang ? 'invalidForm' : 'inputForm'
                    }`}
                    {...register('satpampendidikanjenjang')}>
                    <option value="" disabled>
                      Pilih jenjang pendidikan
                    </option>
                    <option value="Gada Utama">Gada Utama</option>
                    <option value="Gada Madya">Gada Madya</option>
                    <option value="Gada Pratama">Gada Pratama</option>
                  </select>
                  <p className="text-primary text-xs">{errors.satpampendidikanjenjang?.message}</p>
                </div>
              </div>

              {/* Nama Akun Bank */}
              <div className="md:flex mb-3 justify-arround items-center">
                <div className="md:w-1/3">
                  <label htmlFor="satpambanknama" className="block font-semibold mb-1 md:mb-0">
                    Nama Akun Bank (Opsional)
                  </label>
                </div>
                <div className="md:w-2/3">
                  <input
                    type="text"
                    placeholder="Nama akun bank"
                    className={`w-full text-sm ${
                      errors.satpambanknama ? 'invalidForm' : 'inputForm'
                    }`}
                    {...register('satpambanknama')}
                  />
                  <p className="text-primary text-xs">{errors.satpambanknama?.message}</p>
                </div>
              </div>

              {/* No. Rekening Bank */}
              <div className="md:flex mb-3 justify-arround items-center">
                <div className="md:w-1/3">
                  <label
                    htmlFor="satpambanknomorrekening"
                    className="block font-semibold mb-1 md:mb-0">
                    Nomor Rekening (Opsional)
                  </label>
                </div>
                <div className="md:w-2/3">
                  <input
                    type="text"
                    placeholder="Nomor rekening bank"
                    className={`w-full text-sm ${
                      errors.satpambanknomorrekening ? 'invalidForm' : 'inputForm'
                    }`}
                    {...register('satpambanknomorrekening')}
                  />
                  <p className="text-primary text-xs">{errors.satpambanknomorrekening?.message}</p>
                </div>
              </div>

              {/* Foto Rekening Bank */}
              <div>
                <div>
                  <label htmlFor="satpamfoto" className="block font-semibold mb-1">
                    Foto Rekening (Opsional)
                  </label>
                </div>
                <div className="flex flex-wrap w-full">
                  <div className="w-full">
                    <div className="h-auto p-3 bg-white rounded-md border border-gray-200 shadow-md">
                      <div className="flex flex-col w-full">
                        <label className="p-2 flex flex-col w-full h-48 items-center justify-center border-4 border-dashed hover:bg-gray-100 hover:border-gray-300 cursor-pointer">
                          {fotoBankSatpamPreview ? (
                            <div className="flex flex-col items-center justify-center">
                              <img
                                src={fotoBankSatpamPreview}
                                className="w-full h-24 object-contain"
                                alt="preview"
                              />
                            </div>
                          ) : (
                            <div className="flex flex-col items-center justify-center pt-2">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="w-12 h-12 text-gray-400 group-hover:text-gray-600"
                                viewBox="0 0 20 20"
                                fill="currentColor">
                                <path
                                  fillRule="evenodd"
                                  d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z"
                                  clipRule="evenodd"
                                />
                              </svg>
                              <p className="pt-1 text-sm tracking-wider text-gray-400 group-hover:text-gray-600">
                                Click here
                              </p>
                            </div>
                          )}
                          <input
                            type="file"
                            className="opacity-0"
                            {...register('satpambankfoto')}
                            accept="image/*"
                          />
                        </label>
                      </div>
                      <p className="text-primary text-xs">{errors.satpambankfoto?.message}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Buttons */}
          <div className="flex justify-between items-center w-full py-5 px-10 mt-5 mb-5 md:mb-10 shadowAll rounded-lg">
            <button
              type="submit"
              className="text-light py-2 px-5 rounded-md text-sm bg-dark hover:opacity-80"
              disabled={loading === true ? true : false}>
              {loading ? (
                <ClipLoader color="white" loading={true} size={20} speedMultiplier={0.7} />
              ) : (
                'Simpan'
              )}
            </button>
            <Link to="/personil/data">
              <button className="text-light py-2 px-5 rounded-md text-sm bg-dark hover:opacity-80">
                Kembali
              </button>
            </Link>
            {/* </div> */}
          </div>
        </form>
      </div>
    </div>
  );
}
