import DatePicker from 'react-datepicker';
import id from 'date-fns/locale/id';
import moment from 'moment';
import Swal from 'sweetalert2';
import { ClipLoader } from 'react-spinners';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useState, useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
// Import Files
import * as api from '../../redux/api';
import { jadwalSatpamSchema } from '../../utils/schemaValidation';

export default function JadwalCreate() {
  const [errorJam, setErrorJam] = useState({ message: '' });
  const [jamJadwal, setJamJadwal] = useState({ masuk: '', keluar: '' });
  const [loading, setLoading] = useState(false);
  const [satpamDataOptions, setSatpamDataOptions] = useState([]);
  const [satpamSelected, setSatpamSelected] = useState([]);
  const [searchSatpam, setSearchSatpam] = useState('');
  const [submitDisabled, setSubmitDisabled] = useState(true);
  // const [tanggalJadwal, setTanggalJadwal] = useState([{ masuk: '', keluar: '' }]);
  // const [errorTanggal, setErrorTanggal] = useState([{ message: '' }]);

  const [bulanTahun, setBulanTahun] = useState(new Date());
  const [errorTanggalFormated, setErrorTanggalFormated] = useState('');
  const [shiftKerja, setShiftKerja] = useState('siang');
  const [tanggal, setTanggal] = useState('');
  const [tanggalFormated, setTanggalFormated] = useState([{ masuk: '', keluar: '' }]);

  const renderMonthContent = (month, shortMonth, longMonth) => {
    const tooltipText = `Tooltip for month: ${longMonth}`;
    return <span title={tooltipText}>{shortMonth}</span>;
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    // reset,
    setValue,
    watch
  } = useForm({
    resolver: yupResolver(jadwalSatpamSchema)
  });

  useEffect(() => {
    const getDataSatpam = async () => {
      await api.satpamData().then((res) => {
        res.data.data
          .filter((item) => item.satpamstatus === 'Aktif')
          .map((data) => {
            return setSatpamDataOptions((prev) => [
              ...prev,
              { value: data.satpamkode, label: data.satpamnamalengkap }
            ]);
          });
      });
    };
    getDataSatpam();
  }, []);

  useEffect(() => {
    if (satpamSelected?.length > 0) {
      setSubmitDisabled(false);
    } else {
      setSubmitDisabled(true);
    }
  }, [satpamSelected]);

  useEffect(() => {
    const masuk = new Date();
    const keluar = new Date();

    if (watch('satpamjadwalstatus') === '5') {
      setShiftKerja('siang');
      setValue('satpamjadwalketerangan', 'Off');
      masuk.setHours(0);
      masuk.setMinutes(0);
      masuk.setSeconds(0);
      keluar.setHours(23);
      keluar.setMinutes(59);
      keluar.setSeconds(59);
      setJamJadwal({ masuk, keluar });
    } else {
      if (shiftKerja === 'siang') {
        setValue('satpamjadwalketerangan', 'Day shift');
        masuk.setHours(6);
        masuk.setMinutes(0);
        masuk.setSeconds(0);
        keluar.setHours(18);
        keluar.setMinutes(0);
        keluar.setSeconds(0);
      } else {
        setValue('satpamjadwalketerangan', 'Night shift');
        masuk.setHours(18);
        masuk.setMinutes(0);
        masuk.setSeconds(0);
        keluar.setHours(6);
        keluar.setMinutes(0);
        keluar.setSeconds(0);
      }
      setJamJadwal({ masuk, keluar });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch('satpamjadwalstatus'), shiftKerja]);

  useEffect(() => {
    const ranges = tanggal.split(',');

    const result = [];
    ranges.forEach((range) => {
      if (range.includes('-')) {
        const [start, end] = range.split('-').map(Number);
        for (let i = start; i <= end; i++) {
          result.push(i.toString());
        }
      } else {
        result.push(range);
      }
    });

    const parsedTanggal = result.filter((data) => data !== '');

    const isValidTanggal = parsedTanggal
      .filter((range) => !range.includes('-'))
      .every(
        (item) =>
          item > 0 && item <= getDaysInMonth(bulanTahun.getFullYear(), bulanTahun.getMonth() + 1)
      );

    if (!isValidTanggal) {
      setErrorTanggalFormated('Inputlah tanggal yang valid!');
      return;
    }

    const duplicateValues = parsedTanggal.filter(
      (item, index, self) => self.indexOf(item) !== index
    );

    if (duplicateValues.length > 0) {
      setErrorTanggalFormated(`Hapus angka ${duplicateValues.join(', ')} karena sudah diinput!`);
      return;
    }

    setErrorTanggalFormated('');

    const newDate = new Date(bulanTahun);
    const date = parsedTanggal.map((item) => ({
      masuk: new Date(newDate.setDate(item)),
      keluar:
        shiftKerja === 'siang'
          ? new Date(newDate.setDate(item))
          : new Date(newDate.setDate(Number(item) + 1))
    }));
    setTanggalFormated(date);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tanggal, shiftKerja]);

  function getDaysInMonth(year, month) {
    const lastDayOfPreviousMonth = new Date(year, month, 0);
    return lastDayOfPreviousMonth.getDate();
  }

  const handleSatpamSelected = (e) => {
    if (e.target.checked) {
      setSatpamSelected([...satpamSelected, { satpamkode: e.target.value }]);
    } else {
      setSatpamSelected(
        satpamSelected.filter((personil) => personil.satpamkode !== e.target.value)
      );
    }
  };

  const selectAll = () => {
    setSatpamSelected([]);
    filteredSatpamOptions.map((option) => {
      return setSatpamSelected((prev) => [...prev, { satpamkode: option.value }]);
    });
  };

  const filteredSatpamOptions = satpamDataOptions.filter((personil) => {
    return personil.label.toLowerCase().includes(searchSatpam.toLowerCase());
  });

  // const addTanggalJadwal = () => {
  //   setTanggalJadwal([...tanggalJadwal, { masuk: '', keluar: '' }]);
  //   setErrorTanggal([...errorTanggal, { message: '' }]);
  // };

  // Menghapus data tanggal berdasarkan index
  // const removeTanggalJadwal = (indexJadwal) => {
  //   if (indexJadwal > -1) {
  //     const removedTanggalJadwal = tanggalJadwal.splice(indexJadwal, 1);

  //     const removeTanggal = tanggalJadwal.filter(
  //       (tanggalfilter) =>
  //         tanggalfilter.masuk !==
  //           removedTanggalJadwal.map((removedtanggal) => removedtanggal.masuk) &&
  //         tanggalfilter.keluar !==
  //           removedTanggalJadwal.map((removedtanggal) => removedtanggal.keluar)
  //     );

  //     setTanggalJadwal(removeTanggal);

  //     // Hapus error by tanggal
  //     const newErrorTanggal = [...errorTanggal];
  //     let valueToRemove = [newErrorTanggal[indexJadwal]];
  //     const newErrorTanggalFilter = newErrorTanggal.filter(
  //       (element) => !valueToRemove.includes(element)
  //     );

  //     setErrorTanggal(newErrorTanggalFilter);
  //   }
  // };

  const onSubmit = async (data) => {
    setLoading(true);
    data.satpamjadwalsatpamkode = satpamSelected;
    const jamJadwalMasukFormatted = moment(jamJadwal.masuk).format('HH:mm');
    const jamJadwalKeluarFormatted = moment(jamJadwal.keluar).format('HH:mm');

    // if (jamJadwalMasukFormatted === jamJadwalKeluarFormatted) {
    //   setLoading(false);
    //   Swal.fire({
    //     icon: 'error',
    //     title: 'Oops...',
    //     text: 'Jam masuk dan jam keluar tidak boleh sama!'
    //   });
    // } else {
    if (tanggalFormated.length > 0) {
      tanggalFormated.map(async (jadwal, index) => {
        let tanggalMasukFormatted = moment(jadwal.masuk).format('YYYY-MM-DD');
        let tanggalKeluarFormatted = moment(jadwal.keluar).format('YYYY-MM-DD');

        if (tanggalMasukFormatted > tanggalKeluarFormatted) {
          setLoading(false);
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Tanggal masuk tidak boleh lebih besar dari tanggal keluar!'
          });
        } else {
          data.satpamjadwalmasuktanggal = tanggalMasukFormatted + ' ' + jamJadwalMasukFormatted;
          data.satpamjadwalkeluartanggal = tanggalKeluarFormatted + ' ' + jamJadwalKeluarFormatted;

          await api
            .createJadwal(data)
            .then((res) => {
              if (index === tanggalFormated.length - 1) {
                Swal.fire('Good job!', 'You successfully submit the form!', 'success');
                // setSearchSatpam('');
                // setSatpamSelected([]);
                // reset();
                // setJamJadwal({ masuk: '', keluar: '' });
                setTanggal('');
                setTanggalFormated([{ masuk: '', keluar: '' }]);
                setLoading(false);
              }
            })
            .catch((error) => {
              if (index === tanggalFormated.length - 1) {
                setLoading(false);
                Swal.fire({
                  icon: 'error',
                  title: 'Oops...',
                  text: error.response.data.message
                });
              }
            });
        }
      });
    } else {
      setLoading(false);
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Belum memasukkan tanggal'
      });
    }
    // }
  };

  return (
    <div className="relative px-4 md:px-10 mx-auto w-full -mt-8 overflow-x-hidden scrollbar-thin scrollbar-track-gray-100 scrollbar-thumb-gray-300">
      <div className="w-full min-h-screen">
        <div className="w-full py-3 bg-gray-50 rounded-lg shadow-black/40 shadow-lg flex justify-center items-center mb-5 text-center text-lg font-semibold">
          Tambah Jadwal Absensi
        </div>
        <form onSubmit={handleSubmit(onSubmit)} className="w-full flex flex-col gap-5">
          <div className="flex flex-wrap lg:flex-nowrap text-sm gap-5">
            <div className="w-full flex flex-col gap-5">
              <div className="bg-gray-50 p-5 rounded-lg shadowAll">
                {/* Status */}
                <div className="md:flex items-center mb-3">
                  <div className="md:w-[40%]">
                    <label htmlFor="tanggal" className="block font-semibold mb-1 md:mb-0">
                      Status
                    </label>
                  </div>
                  <div className="w-full">
                    <select
                      defaultValue="0"
                      className={`w-full ${
                        errors.satpamjadwalstatus ? 'invalidForm' : 'inputForm'
                      }`}
                      {...register('satpamjadwalstatus')}>
                      <option value="" disabled>
                        Pilih Status
                      </option>
                      <option value="0">BELUM ACTION</option>
                      <option value="1">SUDAH CLOCK IN</option>
                      <option value="2">SUDAH CLOCK OUT</option>
                      <option value="5">OFF</option>
                    </select>
                    <p className="text-errorText text-xs">{errors.satpamjadwalstatus?.message}</p>
                  </div>
                </div>

                {/* Shift Kerja */}
                {watch('satpamjadwalstatus') !== '5' && (
                  <div className="sm:flex items-center w-full sm:mb-3">
                    <label htmlFor="siang" className="sm:w-[40%] block font-semibold mb-2 sm:mb-0">
                      Shift kerja
                    </label>
                    <div className="sm:flex items-center w-full gap-5">
                      <div className="flex items-center gap-2">
                        <input
                          type="radio"
                          id="siang"
                          name="shift_kerja"
                          value="siang"
                          className="cursor-pointer"
                          onChange={(e) => setShiftKerja(e.target.value)}
                          defaultChecked
                        />
                        <label htmlFor="siang" className="cursor-pointer">
                          Shift siang
                        </label>
                      </div>
                      <div className="flex items-center gap-2">
                        <input
                          type="radio"
                          id="malam"
                          name="shift_kerja"
                          value="malam"
                          className="cursor-pointer"
                          onChange={(e) => setShiftKerja(e.target.value)}
                        />
                        <label htmlFor="malam" className="cursor-pointer">
                          Shift malam
                        </label>
                      </div>
                      <br />
                    </div>
                  </div>
                )}

                {/* Keterangan */}
                <div className="mb-3">
                  <div>
                    <label className="block font-semibold mb-1">Keterangan</label>
                  </div>
                  <div className="w-full">
                    <input
                      type="text"
                      name="satpamjadwalketerangan"
                      placeholder="Contoh: Day shift"
                      className={`w-full ${
                        errors.satpamjadwalketerangan ? 'invalidForm' : 'inputForm'
                      }`}
                      {...register('satpamjadwalketerangan')}
                    />
                    {/* <textarea
                      rows={2}
                      name="satpamjadwalketerangan"
                      placeholder="Contoh: Day shift"
                      className={`w-full ${
                        errors.satpamjadwalketerangan ? 'invalidForm' : 'inputForm'
                      }`}
                      {...register('satpamjadwalketerangan')}
                    /> */}
                    <p className="text-errorText text-xs">
                      {errors.satpamjadwalketerangan?.message}
                    </p>
                  </div>
                </div>
                {watch('satpamjadwalstatus') !== '5' && (
                  <div className="sm:flex gap-5 items-center">
                    <div className="mb-3 sm:mb-0">
                      <div>
                        <label
                          htmlFor="satpampatrolimasuktanggal"
                          className="block font-semibold mb-1">
                          Jam masuk
                        </label>
                      </div>
                      <div className="relative block">
                        <DatePicker
                          selected={jamJadwal.masuk}
                          className={`w-full text-sm cursor-pointer inputForm`}
                          value={jamJadwal.masuk}
                          onChange={(date) => {
                            setJamJadwal({ ...jamJadwal, masuk: date });

                            // if (jamJadwal.keluar !== '') {
                            //   if (date === jamJadwal.keluar) {
                            //     setErrorJam({
                            //       message: 'jam masuk dan jam keluar tidak boleh sama'
                            //     });
                            //   } else {
                            //     setErrorJam({ message: '' });
                            //   }
                            // }
                          }}
                          locale={id}
                          placeholderText="HH:mm"
                          showTimeSelect
                          showTimeSelectOnly
                          timeIntervals={15}
                          timeCaption="Time"
                          dateFormat="HH:mm"
                          required
                        />
                        <div className="absolute top-1/2 transform -translate-y-1/2 right-3 pointer-events-none">
                          <i className="far fa-clock"></i>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div>
                        <label
                          htmlFor="satpampatrolimasuktanggal"
                          className="block font-semibold mb-1">
                          Jam akhir
                        </label>
                      </div>
                      <div>
                        <div className="relative block">
                          <DatePicker
                            selected={jamJadwal.keluar}
                            className={`w-full text-sm cursor-pointer inputForm`}
                            value={jamJadwal.keluar}
                            onChange={(date) => {
                              setJamJadwal({ ...jamJadwal, keluar: date });

                              // if (jamJadwal.masuk !== '') {
                              //   if (jamJadwal.masuk === date) {
                              //     setErrorJam({
                              //       message: 'jam masuk dan jam keluar tidak boleh sama'
                              //     });
                              //   } else {
                              //     setErrorJam({ message: '' });
                              //   }
                              // }
                            }}
                            locale={id}
                            placeholderText="HH:mm"
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            timeCaption="Time"
                            dateFormat="HH:mm"
                            required
                          />
                          <div className="absolute top-1/2 transform -translate-y-1/2 right-3 pointer-events-none">
                            <i className="far fa-clock"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <p className="text-errorText">{errorJam?.message}</p>
              </div>

              <div className="bg-gray-50 p-5 rounded-lg shadowAll">
                <div className="mb-3 w-full">
                  <label className="block font-semibold mb-1">Bulan & Tahun</label>
                  <DatePicker
                    selected={bulanTahun}
                    renderMonthContent={renderMonthContent}
                    showMonthYearPicker
                    dateFormat="MMMM yyyy"
                    locale={id}
                    value={bulanTahun}
                    onChange={(waktu) => setBulanTahun(waktu)}
                    className="w-full inputForm"
                  />
                </div>
                {bulanTahun && (
                  <>
                    <div>
                      <label htmlFor="tanggal" className="block font-semibold mb-1">
                        Tanggal
                      </label>
                      <input
                        id="tanggal"
                        type="text"
                        className="inputForm w-full"
                        placeholder="Contoh: 1,2,3 (input tgl tanpa spasi!)"
                        value={tanggal}
                        onChange={(e) => setTanggal(e.target.value)}
                      />
                    </div>
                    {errorTanggalFormated && (
                      <p className="mb-2 text-errorText text-sm text-center">
                        {errorTanggalFormated}
                      </p>
                    )}
                  </>
                )}
              </div>
              {/* <div className="bg-gray-50 p-5 rounded-lg shadowAll"> */}
              {/* <div className="flex mb-1 items-center gap-2">
                  <div className="w-[45%]">
                    <label htmlFor="tanggal" className="block font-semibold mb-1 md:mb-0">
                      Tanggal masuk
                    </label>
                  </div>
                  <div className="w-[45%]">
                    <label htmlFor="tanggal" className="block font-semibold mb-1 md:mb-0">
                      Tanggal keluar
                    </label>
                  </div>
                </div> */}
              {/* {tanggalJadwal.length > 0 &&
                  tanggalJadwal.map((tanggal, index) => (
                    <div key={index}>
                      <div className="flex mb-1 items-center gap-2">
                        <div className="w-[45%]">
                          <div className="relative block">
                            <DatePicker
                              selected={tanggal.masuk || tanggal.keluar}
                              className={`w-full text-sm cursor-pointer inputForm`}
                              excludeDates={[...tanggalJadwal.map((tgl) => tgl.masuk)]}
                              value={tanggal.masuk || tanggal.keluar}
                              onChange={(date) => {
                                const newTanggalJadwal = [...tanggalJadwal];
                                if (newTanggalJadwal[index].keluar === '') {
                                  newTanggalJadwal[index] = { masuk: date, keluar: date };
                                } else {
                                  newTanggalJadwal[index] = {
                                    masuk: date,
                                    keluar: newTanggalJadwal[index].keluar
                                  };
                                }
                                setTanggalJadwal(newTanggalJadwal);

                                const newErrorTanggal = [...errorTanggal];

                                if (newTanggalJadwal[index].keluar !== '') {
                                  if (date > newTanggalJadwal[index].keluar) {
                                    newErrorTanggal[index].message =
                                      'tanggal masuk tidak boleh lebih besar dari tanggal keluar';
                                  } else {
                                    newErrorTanggal[index].message = '';
                                  }
                                }

                                setErrorTanggal(newErrorTanggal);
                              }}
                              locale={id}
                              dateFormat="dd/MM/yyyy"
                              placeholderText="dd/MM/yyyy"
                              required
                            />
                            <div className="absolute top-1/2 transform -translate-y-1/2 right-3 pointer-events-none">
                              <i className="far fa-calendar"></i>
                            </div>
                          </div>
                        </div>
                        <div className="w-[45%]">
                          <div className="relative block">
                            <DatePicker
                              selected={tanggal.keluar || tanggal.masuk}
                              className={`w-full text-sm cursor-pointer inputForm`}
                              value={tanggal.keluar || tanggal.masuk}
                              excludeDates={[...tanggalJadwal.map((tgl) => tgl.masuk)]}
                              onChange={(date) => {
                                const newTanggalJadwal = [...tanggalJadwal];
                                if (newTanggalJadwal[index].masuk === '') {
                                  newTanggalJadwal[index] = { masuk: date, keluar: date };
                                } else {
                                  newTanggalJadwal[index] = {
                                    masuk: newTanggalJadwal[index].masuk,
                                    keluar: date
                                  };
                                }
                                setTanggalJadwal(newTanggalJadwal);

                                const newErrorTanggal = [...errorTanggal];

                                if (newTanggalJadwal[index].masuk !== '') {
                                  if (newTanggalJadwal[index].masuk > date) {
                                    newErrorTanggal[index].message =
                                      'tanggal masuk tidak boleh lebih besar dari tanggal keluar';
                                  } else {
                                    newErrorTanggal[index].message = '';
                                  }
                                }

                                setErrorTanggal(newErrorTanggal);
                              }}
                              locale={id}
                              dateFormat="dd/MM/yyyy"
                              placeholderText="dd/MM/yyyy"
                              required
                            />
                            <div className="absolute top-1/2 transform -translate-y-1/2 right-3 pointer-events-none">
                              <i className="far fa-calendar"></i>
                            </div>
                          </div>
                        </div>
                        <div className="w-[10%]">
                          <button
                            type="button"
                            onClick={() => removeTanggalJadwal(index)}
                            className={`block w-full btn text-gray-50 py-2 px-2 rounded-md text-sm bg-errorText hover:opacity-80`}>
                            <i className="fas fa-times-circle"></i>
                          </button>
                        </div>
                      </div>
                      <p className="text-errorText">{errorTanggal[index]?.message}</p>
                    </div>
                  ))} */}
              {/* <button
                  type="button"
                  onClick={addTanggalJadwal}
                  className={`block w-full btn text-gray-50 py-2 px-2 rounded-md text-sm bg-dark hover:opacity-80`}>
                  <i className="fas fa-plus-circle"></i> Tambah Tanggal
                </button> */}
              {/* </div> */}
            </div>
            <div className="w-full flex flex-col gap-5">
              <div className="bg-gray-50 p-5 rounded-lg shadowAll">
                <div className="flex flex-col md:flex-row gap-2 mb-2 items-center">
                  <div className="md:flex md:flex-col w-full">
                    <div className="w-full">
                      <input
                        type="text"
                        className="w-full inputForm py-1.5"
                        placeholder="Cari nama"
                        value={searchSatpam}
                        onChange={(e) => setSearchSatpam(e.target.value)}
                      />
                    </div>
                    <div className="mt-2 sm:flex gap-2">
                      <div className="sm:w-1/2 mb-1 sm:mb-0">
                        <button
                          type="button"
                          onClick={selectAll}
                          className={`block w-full text-gray-50 py-2 px-2 rounded-md text-sm bg-dark hover:opacity-80`}>
                          <i className="fas fa-check mr-2"></i> Pilih semua
                        </button>
                      </div>
                      <div className="sm:w-1/2">
                        <button
                          type="button"
                          onClick={() => setSatpamSelected([])}
                          className={`block w-full text-gray-50 py-2 px-2 rounded-md text-sm ${
                            satpamSelected.length > 0 ? 'bg-errorText' : 'bg-customGray'
                          } hover:opacity-80`}>
                          <i className="fas fa-close mr-2"></i> Batal pilih
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col w-full lg:h-96">
                  <div className="inline-block min-w-full rounded-md overflow-auto scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100">
                    <table className="table-roster table-auto mx-auto w-full z-50">
                      <thead className="z-10 divide-y-2 divide-gray-400 rounded-md">
                        <tr>
                          <th className="w-[5%] bg-gray-300 font-bold text-sm py-1 whitespace-nowrap text-center border border-gray-300 border-t-0 border-l-0 align-middle ">
                            Aksi
                          </th>
                          <th className="bg-gray-300 w-1/3 font-bold text-sm py-1 whitespace-nowrap text-center border border-gray-300 border-t-0 border-l-0 align-middle">
                            Nama Personil
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {filteredSatpamOptions.length > 0 &&
                          filteredSatpamOptions.map((personil, index) => (
                            <tr key={index}>
                              <td className="px-2 py-1 border-b-2 border-gray-200 text-sm text-center">
                                <input
                                  type="checkbox"
                                  className="cursor-pointer"
                                  id={personil.value}
                                  value={personil.value}
                                  checked={
                                    satpamSelected.filter(
                                      (selected) => selected.satpamkode === personil.value
                                    ).length > 0
                                      ? true
                                      : false
                                  }
                                  onChange={handleSatpamSelected}
                                />
                              </td>
                              <td className="px-2 py-1 border-b-2 align-middle border-gray-200 text-sm text-left">
                                <label htmlFor={personil.value} className="cursor-pointer">
                                  {personil.label}
                                </label>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="flex justify-between items-center w-full py-5 px-10 mb-5 md:mb-10 shadowAll rounded-lg">
            <button
              type="submit"
              className="text-gray-50 py-2 px-5 rounded-md text-sm bg-dark hover:opacity-80"
              disabled={
                submitDisabled === true && loading === true && errorJam.message === ''
                  ? // && errorTanggal.some((value) => value.message !== '')?.length > 0
                    true
                  : false
              }>
              {loading ? (
                <ClipLoader color="white" loading={true} size={20} speedMultiplier={0.7} />
              ) : (
                'Simpan'
              )}
            </button>

            <Link to="/personil/jadwal">
              <button className="btn text-gray-50 py-2 px-5 rounded-md text-sm bg-dark hover:opacity-80">
                Kembali
              </button>
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
}
