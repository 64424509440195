import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import * as api from '../../redux/api.js';
import UserDropdown from '../dropdowns/UserDropdown';

export default function Navbar({ profile, handleLogout }) {
  const [childMenuData, setChildMenuData] = useState([]);

  const location = useLocation();

  const [activeMainMenu, setActiveMainMenu] = useState({
    adminmenuutama: 'Dashboard',
    adminmenulink: '/'
  });

  useEffect(() => {
    const getChildMenu = async () => {
      await api.childMenu().then((res) => {
        setChildMenuData(res.data.data);
      });
    };
    getChildMenu();
  }, []);

  useEffect(() => {
    const filteredChildMenu = childMenuData?.filter(
      (childmenu) => childmenu.adminmenulink === location.pathname
    );

    location.pathname === '/'
      ? setActiveMainMenu({
          adminmenuutama: 'Dashboard',
          adminmenulink: '/'
        })
      : filteredChildMenu?.map((childmenu) => setActiveMainMenu(childmenu.adminmenugrup));
  }, [childMenuData, location]);

  return (
    <>
      {/* Navbar */}
      <nav className="w-full bgGradientGAP md:flex-row md:flex-nowrap md:justify-start flex items-center py-4 pb-14">
        <div className="w-full mx-auto items-center flex justify-between md:flex-nowrap flex-wrap md:px-10 px-4">
          {/* Brand */}
          {activeMainMenu !== '' && (
            <Link
              to={activeMainMenu.adminmenulink}
              className="text-gray-100 text-md uppercase hidden md:inline-block font-bold">
              {activeMainMenu.adminmenuutama}
            </Link>
          )}
          {/* User */}
          <ul className="flex-col md:flex-row list-none items-center hidden md:flex">
            <UserDropdown profile={profile} handleLogout={handleLogout} />
          </ul>
        </div>
      </nav>
      {/* End Navbar */}
    </>
  );
}
