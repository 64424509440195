import React, { useState, useEffect } from 'react';

export default function FilterStatus({ laporan, data, setFilterData }) {
  const [status, setStatus] = useState('all');

  useEffect(() => {
    if (status !== 'all') {
      if (laporan === 'kegiatan')
        setFilterData(data?.filter((item) => Number(item.satpamkegiatanstatus) === Number(status)));
      if (laporan === 'kejadian')
        setFilterData(data?.filter((item) => Number(item.satpamkejadianstatus) === Number(status)));
      if (laporan === 'temuan')
        setFilterData(data?.filter((item) => Number(item.satpamtemuanstatus) === Number(status)));
    } else setFilterData(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  return (
    <div className="flex flex-col w-full md:w-auto gap-1 md:my-3 my-2 pr-5 md:pr-2 pl-5">
      <div className="font-semibold text-xs mr-2 mb-1 md:mb-0">Filter status</div>
      <div className="md:flex md:flex-row gap-2">
        <div className="flex flex-col justify-center items-center">
          <select
            className="text-xs cursor-pointer inputForm w-full"
            name="type"
            defaultValue="all"
            onChange={(e) => setStatus(e.target.value)}>
            <option value="all">All</option>
            <option value="0">Open</option>
            <option value="1">Close</option>
          </select>
        </div>
      </div>
    </div>
  );
}
