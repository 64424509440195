// Import packages
import React, { useState, useEffect } from 'react';
import * as api from '../../redux/api';
import { Link } from 'react-router-dom';
import { Modal, Tooltip } from '@mantine/core';
import { Popover } from '@mantine/core';
import Table from '../../components/general/Table';
import { AiOutlineFileAdd } from 'react-icons/ai';
import Swal from 'sweetalert2';
import QRCode from 'react-qr-code';
import ModalPerusahaanDetail from '../../components/perusahaan/ModalPerusahaanDetail';
import ModalPerusahaanQR from '../../components/perusahaan/ModalPerusahaanQR.jsx';
import { distanceFormat } from '../../utils/distanceFormat';
import { useDebouncedValue } from '@mantine/hooks';

export default function PerusahaanData() {
  const [showImage, setShowImage] = useState(false);
  const [imageData, setImageData] = useState(null);
  const [data, setData] = useState([]);
  const [openModalDetail, setOpenModalDetail] = useState(false);
  const [perusahaanDetailKode, setPerusahaanDetailKode] = useState('');
  const [openModalQR, setOpenModalQR] = useState(false);
  const [qrDetail, setQrDetail] = useState({});

  const [filterData, setFilterData] = useState([]);

  const [query, setQuery] = useState('');
  const [debouncedQuery] = useDebouncedValue(query, 200);

  // Get data
  const getAllPerusahaan = async () => {
    await api.getAllPerusahaan().then((res) => {
      setData(res.data.Data);
    });
  };

  useEffect(() => {
    getAllPerusahaan();
  }, []);

  useEffect(() => {
    setFilterData(
      data.filter(
        ({
          satpamperusahaannama,
          satpamperusahaankode,
          satpamperusahaanlatitude,
          satpamperusahaanlongitude,
          satpamperusahaanradius,
          satpamperusahaanalamat
        }) => {
          if (
            debouncedQuery !== '' &&
            !`${satpamperusahaannama} ${satpamperusahaankode} ${satpamperusahaanlatitude} ${satpamperusahaanlongitude} ${satpamperusahaanradius} ${satpamperusahaanalamat}`
              .toLowerCase()
              .includes(debouncedQuery.trim().toLowerCase())
          ) {
            return false;
          }
          return true;
        }
      )
    );
  }, [data, debouncedQuery]);

  const handleImageData = ({ satpamperusahaanfoto }) => {
    setImageData(
      satpamperusahaanfoto?.split('/data-perusahaan/')[1]
        ? satpamperusahaanfoto
        : require('../../images/logo.png')
    );
    setShowImage(!showImage);
  };

  const handleOpenModalDetail = (kegiatanKode) => {
    setPerusahaanDetailKode(kegiatanKode);
    setOpenModalDetail(true);
  };

  const handleOpenModalQR = ({ kode, nama }) => {
    setQrDetail({ kode, nama });
    setOpenModalQR(true);
  };

  const handleDelete = (kodeperusahaan) => {
    Swal.fire({
      text: 'Do you want to delete this perusahaan?',
      showCancelButton: true,
      confirmButtonText: 'Yes'
    }).then((result) => {
      if (result.isConfirmed) {
        const deleteData = async () => {
          await api
            .deletePerusahaan(kodeperusahaan)
            .then((res) => {
              Swal.fire('Good job!', res.data.message, 'success');
              getAllPerusahaan();
            })
            .catch((err) => {
              Swal.fire({ icon: 'error', title: 'Oops...', text: err.response.data.message });
            });
        };
        deleteData();
      }
    });
  };

  // Column Data Table
  const columnData = [
    {
      title: 'Action',
      accessor: 'satpamperusahaankode',
      render: ({ satpamperusahaankode }) => (
        <div className="flex items-center gap-1">
          {/* Button Aksi */}
          <Popover width={110} position="right" withArrow shadow="xl">
            <Popover.Target>
              <div className="btnAksi my-1 py-1 px-2">Action</div>
            </Popover.Target>
            <Popover.Dropdown className="popOverDropdown">
              <div
                size="sm"
                className="popOverItem"
                onClick={() => handleOpenModalDetail(satpamperusahaankode)}>
                <i className="fa-solid fa-circle-info"></i> Detail
              </div>
              <Link to={`/perusahaan/update/${satpamperusahaankode}`}>
                <div className="popOverItem">
                  <i className="fa-solid fa-pen"></i> Edit
                </div>
              </Link>
              <div className="popOverItem" onClick={() => handleDelete(satpamperusahaankode)}>
                <i className="fa-solid fa-trash mr-[2px]"></i> Delete
              </div>
            </Popover.Dropdown>
          </Popover>
        </div>
      )
    },
    {
      title: 'Foto Perusahaan',
      accessor: 'satpamperusahaanfoto',
      width: 150,
      cellsStyle: {
        verticalAlign: 'top'
      },
      render: ({ satpamperusahaanfoto }) => (
        <div
          className="h-32 mt-1 mb-2 flex justify-center cursor-pointer hover:opacity-80"
          onClick={() => handleImageData({ satpamperusahaanfoto })}>
          {satpamperusahaanfoto?.split('/data-perusahaan/')[1] ? (
            <img src={satpamperusahaanfoto} alt="" className="h-full object-contain rounded" />
          ) : (
            <img
              src={require('../../images/logo.png')}
              alt=""
              className="h-full object-contain rounded"
            />
          )}
        </div>
      )
    },
    {
      title: 'Kode dan Status',
      accessor: 'satpamperusahaanstatus',
      cellsStyle: {
        verticalAlign: 'top'
      },
      width: 200,
      render: ({ satpamperusahaankode, satpamperusahaannama, satpamperusahaanstatus }) => (
        <div>
          <div
            className="flex justify-center text-center h-auto w-full py-0 my-0 mb-1 cursor-pointer"
            onClick={() =>
              handleOpenModalQR({
                kode: satpamperusahaankode,
                nama: satpamperusahaannama
              })
            }>
            <QRCode size={128} value={satpamperusahaankode} />
          </div>
          {satpamperusahaanstatus === 'Aktif' ? (
            <div className="border-primary border-2 text-sm text-primary btnStatus block w-full rounded-full text-center mb-2">
              Aktif
            </div>
          ) : (
            <div className="border-secondary text-sm border-2 text-secondary btnStatus block w-full rounded-full text-center mb-2">
              Tidak Aktif
            </div>
          )}
        </div>
      )
    },
    {
      title: 'Nama Perusahaan',
      accessor: 'satpamperusahaannama',
      cellsStyle: {
        verticalAlign: 'top'
      },
      width: 250
    },
    {
      title: 'Latitude',
      accessor: 'satpamperusahaanlatitude',
      cellsStyle: {
        verticalAlign: 'top'
      },
      width: 200
    },
    {
      title: 'Longitude',
      accessor: 'satpamperusahaanlongitude',
      cellsStyle: {
        verticalAlign: 'top'
      },
      width: 200
    },
    {
      title: 'Radius',
      accessor: 'satpamperusahaanradius',
      width: 150,
      cellsStyle: {
        verticalAlign: 'top'
      },
      render: ({ satpamperusahaanradius }) => (
        <div>
          <div className="py-0 my-0">
            <div className="py-0 my-0">
              {satpamperusahaanradius !== null &&
                satpamperusahaanradius !== null &&
                distanceFormat(satpamperusahaanradius)}
            </div>
          </div>
        </div>
      )
    },
    {
      title: 'Alamat',
      accessor: 'satpamperusahaanalamat',
      width: 300,
      cellsStyle: {
        verticalAlign: 'top'
      },
      render: ({ satpamperusahaanalamat }) => (
        <div>
          <div className="py-0 my-0">
            <div className="py-0 my-0">{satpamperusahaanalamat}</div>
          </div>
        </div>
      )
    }
  ];

  return (
    <div className="relative px-4 md:px-10 mx-auto w-full -mt-8 overflow-x-hidden scrollbar-thin scrollbar-track-gray-100 scrollbar-thumb-gray-300">
      <div className="w-full min-h-screen">
        <div className="w-full justify-center bg-gray-50 rounded-lg shadowAll pb-8">
          <div className="flex flex-wrap lg:flex-nowrap justify-beetween items-center">
            <div className="flex flex-col md:flex-row flex-wrap justify-start items-center w-full"></div>
            <div className="my-3 px-5 flex justify-end">
              <div className="flex gap-4 w-full">
                <Tooltip label="Tambah Perusahaan">
                  <Link to="/perusahaan/create">
                    <div className="bg-customGray p-2 rounded-md cursor-pointer hover:opacity-80">
                      <AiOutlineFileAdd size={24} />
                    </div>
                  </Link>
                </Tooltip>
              </div>
            </div>
          </div>
          {/* Table Component */}
          {data?.length < 0 ? (
            <div className="text-center">
              <div className="mt-10">Tidak ada data perusahaan.</div>
            </div>
          ) : (
            <div className="p-5">
              <Table data={filterData} columnData={columnData} setQuery={setQuery} />
            </div>
          )}
        </div>
        {/* Modal Detail Perusahaan */}
        <ModalPerusahaanDetail
          kode={perusahaanDetailKode}
          openModalDetail={openModalDetail}
          setOpenModalDetail={setOpenModalDetail}
        />

        {/* Modal Detail QR */}
        <ModalPerusahaanQR
          qrDetail={qrDetail}
          openModalQR={openModalQR}
          setOpenModalQR={setOpenModalQR}
        />

        {/* Modal Image */}
        <Modal
          centered
          size="md"
          opened={showImage}
          onClose={() => setShowImage(false)}
          className="overflow-auto bg-transparent -mt-10">
          {/* Content image */}
          {imageData && (
            <img
              src={imageData}
              alt=""
              className="w-full object-contain rounded-t-lg flex items-center justify-center"
            />
          )}
        </Modal>
      </div>
    </div>
  );
}
