import React, { useState, useEffect } from 'react';
import moment from 'moment';
import Swal from 'sweetalert2';
import { AiOutlineFileAdd } from 'react-icons/ai';
import { HiOutlinePrinter } from 'react-icons/hi';
import { Link } from 'react-router-dom';
import { Modal, Tooltip } from '@mantine/core';
import { Navigation, Pagination, Scrollbar } from 'swiper';
import { Popover } from '@mantine/core';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useDebouncedValue } from '@mantine/hooks';
import { useSelector } from 'react-redux';
import 'swiper/css';
import 'swiper/css/navigation';
// Import files
import * as api from '../../redux/api';
import Cards from '../../components/general/Cards';
import FilterDate from '../../components/general/FilterDate';
import FilterKategori from '../../components/general/FilterKategori';
import FilterStatus from '../../components/general/FilterStatus';
import ModalKegiatanHarianDetail from '../../components/kegiatan-harian/ModalKegiatanHarianDetail';
import ReadMore from '../../components/general/ReadMore';
import Table from '../../components/general/Table';

export default function KegiatanHarian({ role }) {
  const [datas, setDatas] = useState([]);
  const [kategori, setKategori] = useState('');
  const [showImage, setShowImage] = useState(false);
  const [imageData, setImageData] = useState(null);
  const [showDate, setShowDate] = useState(false);
  const [data, setData] = useState([]);
  const [openModalDetail, setOpenModalDetail] = useState(false);
  const [kegiatanDetailKode, setKegiatanDetailKode] = useState('');
  const [filterData, setFilterData] = useState([]);
  const [query, setQuery] = useState('');
  const [debouncedQuery] = useDebouncedValue(query, 200);

  // Date
  const { perusahaanKode, startDateAdmin, endDateAdmin } = useSelector((state) => state.perusahaan);
  const [date, setDate] = useState({
    startDate: startDateAdmin || moment().format('YYYY-MM-DD'),
    endDate: endDateAdmin || moment().format('YYYY-MM-DD')
  });

  // Get data
  useEffect(() => {
    setDate({
      startDate: startDateAdmin,
      endDate: endDateAdmin
    });
  }, [startDateAdmin, endDateAdmin]);

  useEffect(() => {
    const formDate = {
      startdate: date.startDate,
      enddate: date.endDate,
      satpamkegiatansatpamkegiatankategorikode: kategori
    };

    if (perusahaanKode) {
      formDate.satpamperusahaankode = perusahaanKode;
    }

    const getData = async (formData) => {
      await api.kegiatanHarian(formData).then((res) => {
        setData(res.data.data);
        setDatas(res.data);
      });
    };

    getData(formDate);
    setShowDate(false);
  }, [date, kategori, perusahaanKode]);

  useEffect(() => {
    setFilterData(
      data.filter(
        ({
          satpamkegiatantanggalmelaporkan,
          satpamkegiatankode,
          satpamkegiatankategorinama,
          satpamnamalengkap,
          satpamkegiatanuraian,
          satpamkegiatanketerangan,
          satpamkegiatanalamat
        }) => {
          if (
            debouncedQuery !== '' &&
            !`${satpamkegiatantanggalmelaporkan} ${satpamkegiatankode} ${satpamkegiatankategorinama} ${satpamnamalengkap} ${satpamkegiatanuraian} ${satpamkegiatanketerangan} ${satpamkegiatanalamat}`
              .toLowerCase()
              .includes(debouncedQuery.trim().toLowerCase())
          ) {
            return false;
          }
          return true;
        }
      )
    );
  }, [data, debouncedQuery]);

  const handleImageData = ({
    satpamkegiatanfotosatu,
    satpamkegiatanketeranganfotosatu,
    satpamkegiatanfotodua,
    satpamkegiatanketeranganfotodua,
    satpamkegiatanfototiga,
    satpamkegiatanketeranganfototiga,
    satpamkegiatanfotoempat,
    satpamkegiatanketeranganfotoempat,
    satpamkegiatanfotolima,
    satpamkegiatanketeranganfotolima
  }) => {
    setImageData([
      {
        image: satpamkegiatanfotosatu?.split('/data-kegiatan/')[1] && satpamkegiatanfotosatu,
        keterangan:
          satpamkegiatanketeranganfotosatu?.split('-')[1] &&
          satpamkegiatanketeranganfotosatu?.split('-')[1]
      },
      {
        image: satpamkegiatanfotodua?.split('/data-kegiatan/')[1] && satpamkegiatanfotodua,
        keterangan:
          satpamkegiatanketeranganfotodua?.split('-')[1] &&
          satpamkegiatanketeranganfotodua?.split('-')[1]
      },
      {
        image: satpamkegiatanfototiga?.split('/data-kegiatan/')[1] && satpamkegiatanfototiga,
        keterangan:
          satpamkegiatanketeranganfototiga?.split('-')[1] &&
          satpamkegiatanketeranganfototiga?.split('-')[1]
      },
      {
        image: satpamkegiatanfotoempat?.split('/data-kegiatan/')[1] && satpamkegiatanfotoempat,
        keterangan:
          satpamkegiatanketeranganfotoempat?.split('-')[1] &&
          satpamkegiatanketeranganfotoempat?.split('-')[1]
      },
      {
        image: satpamkegiatanfotolima?.split('/data-kegiatan/')[1] && satpamkegiatanfotolima,
        keterangan:
          satpamkegiatanketeranganfotolima?.split('-')[1] &&
          satpamkegiatanketeranganfotolima?.split('-')[1]
      }
    ]);
    setShowImage(!showImage);
  };

  const getData = async (formData) => {
    await api.kegiatanHarian(formData).then((res) => {
      setData(res.data.data);
      setDatas(res.data);
    });
  };

  const handleOpenModalDetail = (kegiatanKode) => {
    setKegiatanDetailKode(kegiatanKode);
    setOpenModalDetail(true);
  };

  const handleDelete = (formData) => {
    const formDate = {
      startdate: date.startDate,
      enddate: date.endDate
    };

    Swal.fire({
      text: 'Do you want to delete this kegiatan?',
      showCancelButton: true,
      confirmButtonText: 'Yes'
    }).then((result) => {
      if (result.isConfirmed) {
        const deleteData = async () => {
          await api
            .kegiatanHarianDelete(formData)
            .then((res) => {
              Swal.fire('Good job!', res.data.message, 'success');
              getData(formDate);
            })
            .catch((err) => {
              Swal.fire({ icon: 'error', title: 'Oops...', text: err.response.data.message });
            });
        };
        deleteData();
      }
    });
  };

  // Column Data Table
  const columnData = [
    {
      title: 'Action',
      accessor: 'satpamkegiatankode',
      width: 100,
      cellsStyle: {
        verticalAlign: 'top'
      },
      render: ({ satpamkegiatankode }) => (
        <div className="flex items-center gap-1">
          {/* Button Aksi */}
          <Popover width={110} position="right" withArrow shadow="xl">
            <Popover.Target>
              <div className="btnAksi my-1 py-1 px-2">Action</div>
            </Popover.Target>
            <Popover.Dropdown className="popOverDropdown">
              <div
                size="sm"
                className="popOverItem"
                onClick={() => handleOpenModalDetail(satpamkegiatankode)}>
                <i className="fa-solid fa-circle-info"></i> Detail
              </div>
              {role === 'Admin Project' && (
                <>
                  <Link to={`/kegiatan-harian/update/${satpamkegiatankode}`}>
                    <div className="popOverItem">
                      <i className="fa-solid fa-pen"></i> Edit
                    </div>
                  </Link>
                  <div
                    className="popOverItem"
                    onClick={() =>
                      handleDelete({
                        satpamkegiatankode: satpamkegiatankode
                      })
                    }>
                    <i className="fa-solid fa-trash mr-[2px]"></i> Delete
                  </div>
                </>
              )}
              <Link to={`/kegiatan-harian-printperkeg/${satpamkegiatankode}`}>
                <div className="popOverItem">
                  <i className="fa-solid fa-print"></i> Print
                </div>
              </Link>
            </Popover.Dropdown>
          </Popover>
        </div>
      )
    },
    {
      title: 'Foto Kegiatan',
      accessor: 'satpamkegiatanfotosatu',
      width: 150,
      cellsStyle: {
        verticalAlign: 'top'
      },
      render: ({
        satpamkegiatanfotosatu,
        satpamkegiatanketeranganfotosatu,
        satpamkegiatanfotodua,
        satpamkegiatanketeranganfotodua,
        satpamkegiatanfototiga,
        satpamkegiatanketeranganfototiga,
        satpamkegiatanfotoempat,
        satpamkegiatanketeranganfotoempat,
        satpamkegiatanfotolima,
        satpamkegiatanketeranganfotolima
      }) => (
        <div
          className="h-32 mt-1 mb-2 flex justify-center cursor-pointer hover:opacity-80"
          onClick={() =>
            handleImageData({
              satpamkegiatanfotosatu,
              satpamkegiatanketeranganfotosatu,
              satpamkegiatanfotodua,
              satpamkegiatanketeranganfotodua,
              satpamkegiatanfototiga,
              satpamkegiatanketeranganfototiga,
              satpamkegiatanfotoempat,
              satpamkegiatanketeranganfotoempat,
              satpamkegiatanfotolima,
              satpamkegiatanketeranganfotolima
            })
          }>
          <img
            src={
              satpamkegiatanfotosatu?.split('/data-kegiatan/')[1]
                ? satpamkegiatanfotosatu
                : satpamkegiatanfotodua?.split('/data-kegiatan/')[1]
                ? satpamkegiatanfotodua
                : satpamkegiatanfototiga?.split('/data-kegiatan/')[1]
                ? satpamkegiatanfototiga
                : satpamkegiatanfotoempat?.split('/data-kegiatan/')[1]
                ? satpamkegiatanfotoempat
                : satpamkegiatanfotolima?.split('/data-kegiatan/')[1]
                ? satpamkegiatanfotolima
                : ''
            }
            alt=""
            className="h-full object-contain rounded-lg"
          />
        </div>
      )
    },
    {
      title: 'Status, Kode, dan Tanggal',
      accessor: 'satpamkegiatantanggalmelaporkan,',
      width: 200,
      cellsStyle: {
        verticalAlign: 'top'
      },
      render: ({ satpamkegiatantanggalmelaporkan, satpamkegiatankode, satpamkegiatanstatus }) => (
        <div>
          <div className="py-0 my-0">
            <div className="py-0 my-0">{satpamkegiatankode} &nbsp;</div>
            <div className="py-0 my-0">
              {typeof satpamkegiatantanggalmelaporkan !== 'undefined' &&
                moment(satpamkegiatantanggalmelaporkan).format('DD MMM YYYY HH:mm')}
            </div>
            {Number(satpamkegiatanstatus) === 0 ? (
              <div className="border-primary border-2 text-sm text-primary btnStatus block w-full rounded-full text-center mb-2">
                Open
              </div>
            ) : (
              <div className="border-secondary text-sm border-2 text-secondary btnStatus block w-full rounded-full text-center mb-2">
                Closed
              </div>
            )}
          </div>
        </div>
      )
    },
    {
      title: 'Kategori',
      accessor: 'satpamkegiatankategorinama',
      cellsStyle: {
        verticalAlign: 'top'
      },
      width: 200
    },
    {
      title: 'Nama Personil',
      accessor: 'satpamnamalengkap',
      cellsStyle: {
        verticalAlign: 'top'
      },
      width: 250
    },
    {
      title: 'Uraian',
      accessor: 'satpamkegiatanuraian',
      cellsStyle: {
        verticalAlign: 'top'
      },
      width: 350,
      render: ({ satpamkegiatanuraian }) => (
        <>{satpamkegiatanuraian !== null && <ReadMore>{satpamkegiatanuraian}</ReadMore>}</>
      )
    },
    {
      title: 'Keterangan',
      accessor: 'satpamkegiatanketerangan',
      cellsStyle: {
        verticalAlign: 'top'
      },
      width: 350,
      render: ({ satpamkegiatanketerangan }) => (
        <>{satpamkegiatanketerangan !== null && <ReadMore>{satpamkegiatanketerangan}</ReadMore>}</>
      )
    },
    {
      title: 'Alamat',
      accessor: 'satpamkegiatanalamat',
      cellsStyle: {
        verticalAlign: 'top'
      },
      width: 300
    }
  ];

  return (
    <div className={`w-full min-h-screen p-5 ${role === 'Admin Project' ? 'mt-1' : '-mt-10'}`}>
      {/* Cards */}
      <Cards
        title="Statistik Kegiatan Harian"
        desc="Berikut ini adalah jumlah kegiatan harian yang telah dilakukan"
        card1={{ title: 'Verifikasi', value: datas?.sudah_verifikasi || 0 }}
        card2={{
          title: 'Belum Verifikasi',
          value: datas?.belum_verifikasi || 0
        }}
        card3={{ title: 'Total Data', value: datas?.jumlah_data || 0 }}
      />

      <div className="mt-10 w-full justify-center bg-gray-50 rounded-lg shadowAll pb-8">
        <div className="flex flex-wrap lg:flex-nowrap justify-beetween items-center">
          <div className="flex flex-col md:flex-row flex-wrap justify-start items-center w-full">
            <FilterDate
              date={date}
              setDate={setDate}
              showDate={showDate}
              setShowDate={setShowDate}
            />
            <FilterKategori kategori={kategori} setKategori={setKategori} page="kegiatan" />
            <FilterStatus laporan="kegiatan" data={data} setFilterData={setFilterData} />
          </div>

          <div className="my-3 px-5 flex justify-end">
            <div className="flex gap-4 w-full">
              {role === 'Admin Project' && (
                <Tooltip label="Tambah Kegiatan">
                  <Link to="/kegiatan-harian/create">
                    <div className="bg-customGray p-2 rounded-md cursor-pointer hover:opacity-80">
                      <AiOutlineFileAdd size={24} />
                    </div>
                  </Link>
                </Tooltip>
              )}

              <Link to={`/kegiatan-harian-print`} state={{ data: data, date: date }}>
                <div className="bg-customGray p-2 rounded-md cursor-pointer hover:opacity-80 flex items-center text-gray-700">
                  <HiOutlinePrinter size={24} />
                </div>
              </Link>
            </div>
          </div>
        </div>
        {/* Table Component */}
        {datas.message === 'Data empty' ? (
          <div className="text-center">
            <div className="mt-10">Tidak ada data kegiatan harian pada tanggal tersebut.</div>
            <div className="mb-72">Silahkan memilih rentang tanggal lainnya!</div>
          </div>
        ) : (
          <div className="p-5">
            <Table data={filterData} columnData={columnData} setQuery={setQuery} />
          </div>
        )}
      </div>
      {/* Modal Detail Kegiatan */}
      <ModalKegiatanHarianDetail
        kode={kegiatanDetailKode}
        openModalDetail={openModalDetail}
        setOpenModalDetail={setOpenModalDetail}
      />

      {/* Modal Image */}
      <Modal
        centered
        size="lg"
        opened={showImage}
        onClose={() => setShowImage(false)}
        className="overflow-auto bg-transparent -mt-10">
        <Swiper
          modules={[Navigation, Pagination, Scrollbar]}
          spaceBetween={0}
          slidesPerView={1}
          navigation
          pagination={{ clickable: true }}
          scrollbar={{ draggable: true }}>
          {imageData
            ?.filter((data) => data.image)
            .map((item, i) => (
              <SwiperSlide key={i}>
                {item.image && (
                  <img
                    src={item.image}
                    alt=""
                    className="w-full object-contain rounded-t-lg flex items-center justify-center"
                  />
                )}
                {item.keterangan && (
                  <div className="bg-primary text-gray-50 text-center text-[14px] rounded-b-lg py-2">
                    {item.keterangan}
                  </div>
                )}
              </SwiperSlide>
            ))}
        </Swiper>
      </Modal>

      {/* Modal Create */}
      {/* <CreateKegiatanHarian dataSatpam={satpamData} /> */}
    </div>
  );
}
