import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import ClipLoader from 'react-spinners/ClipLoader';
import UserDropdown from '../dropdowns/UserDropdown';
import { NavLink } from '@mantine/core';
import { useSelector } from 'react-redux';

export default function Sidebar({ handleLogout }) {
  const { menuData, loading } = useSelector((state) => ({ ...state.menu }));

  const [collapseShow, setCollapseShow] = useState('hidden');
  const [activeMainMenu, setActiveMainMenu] = useState('Dashboard');

  const location = useLocation();

  useEffect(() => {
    const filteredChildMenu = menuData?.filter(
      (childmenu) => childmenu.adminmenulink === location.pathname
    );
    filteredChildMenu?.map((childmenu) => setActiveMainMenu(childmenu.adminmenugrup));
  }, [menuData, location.pathname]);

  return (
    <>
      <nav className="bg-gray-100 md:left-0 md:pb-10 md:block md:fixed md:top-0 md:bottom-0 md:overflow-y-auto md:flex-row md:flex-nowrap md:overflow-hidden shadow-xl h-full flex flex-wrap items-center justify-between relative md:w-64 z-10 py-4 px-3 md:scrollbar-thin md:scrollbar-thumb-customGray md:scrollbar-track-gray-100">
        <div className="md:flex-col md:items-stretch md:min-h-screen md:flex-nowrap px-0 flex flex-wrap items-center justify-between w-full mx-auto">
          {/* Toggler */}
          <button
            className="cursor-pointer text-darkGray opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
            type="button"
            onClick={() => setCollapseShow('bg-gray-100 m-2 py-2 px-6')}>
            <i className="fas fa-bars"></i>
          </button>
          {/* Brand */}
          <Link to="/">
            <div className="flex flex-col items-center md:p-2 p-0 text-sm">
              <img
                src={require('../../images/logo.png')}
                alt=""
                className="hidden md:block h-36 mb-3"
              />
              <h4 className="text-center uppercase text-2xl text-darkGray font-bold md:-mt-2">
                GAPS
              </h4>
              <h6 className="text-center text-sm text-gray-500 font-bold">
                Gapara Asset Protection System
              </h6>
            </div>
          </Link>

          {/* User */}
          <ul className="md:hidden items-center flex flex-wrap list-none">
            <li className="inline-block relative">
              <UserDropdown />
            </li>
          </ul>
          {/* Collapse */}
          <div
            className={
              'md:flex md:flex-col md:items-stretch md:opacity-100 md:relative md:mt-4 md:shadow-none shadow absolute top-0 left-0 right-0 z-40 overflow-y-auto overflow-x-hidden h-auto items-center flex-1 rounded ' +
              collapseShow
            }>
            {/* Collapse header */}
            <div className="md:min-w-full md:hidden block pb-4 mb-4 border-b border-solid border-secondary">
              <div className="flex flex-wrap">
                <div className="w-6/12">
                  <Link
                    to="/"
                    className="md:block text-left mr-0 flex flex-col whitespace-nowrap font-bold p-4 px-0">
                    <span className="text-sm text-slate-600">GAPS</span>
                    <span className="text-xs text-slate-400">Gapara Asset Protection System</span>
                  </Link>
                </div>
                <div className="w-6/12 flex justify-end">
                  <button
                    type="button"
                    className="cursor-pointer text-darkGray opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
                    onClick={() => setCollapseShow('hidden')}>
                    <i className="fas fa-times"></i>
                  </button>
                </div>
              </div>
            </div>
            {/* Divider */}
            <hr className="my-4 md:min-w-full" />
            {/* Heading */}
            {loading ? (
              <div className="flex items-center justify-center">
                <ClipLoader color="red" loading={true} size={20} speedMultiplier={0.7} /> &nbsp;
                data loading...
              </div>
            ) : (
              menuData?.filter((mainmenu) => mainmenu?.adminmenuclass === 'Main')?.length > 0 &&
              menuData
                ?.filter((menufilter) => menufilter?.adminmenuclass === 'Main')
                ?.map((mainmenu) => (
                  <div key={mainmenu.adminhakaksesadminmenuid}>
                    {mainmenu?.adminmenulink !== '#' && mainmenu?.adminmenulink !== '' ? (
                      <div key={mainmenu?.adminhakaksesadminmenuid}>
                        <ul className="md:flex-col md:min-w-full flex flex-col list-none">
                          <li
                            className={`items-center my-1 pl-2.5 rounded ${
                              location.pathname === mainmenu.adminmenulink
                                ? 'font-bold text-white bgGradientGAP'
                                : 'font-semibold hoverGradientGAP hover:text-white hover:font-bold'
                            }`}>
                            <Link
                              to={mainmenu?.adminmenulink}
                              className={`text-xs py-1 block`}
                              state={mainmenu}
                              onClick={() => {
                                setCollapseShow('hidden');
                                setActiveMainMenu(mainmenu.adminmenugrup);
                              }}>
                              <i className={`${mainmenu.adminmenuicon} mr-2 text-sm `}></i>{' '}
                              {mainmenu.adminmenuutama}
                            </Link>
                          </li>
                        </ul>
                      </div>
                    ) : (
                      <div key={mainmenu?.adminhakaksesadminmenuid}>
                        <NavLink
                          defaultOpened={activeMainMenu === mainmenu.adminmenugrup ? true : false}
                          className={`font-semibold py-1 my-1 rounded ${
                            activeMainMenu === mainmenu.adminmenugrup
                              ? 'bg-gray-500 hover:bg-gray-600 text-white'
                              : 'hover:bg-gray-500 hover:text-white'
                          }`}
                          label={mainmenu?.adminmenuutama}
                          icon={<i className={mainmenu?.adminmenuicon} />}>
                          {menuData?.filter(
                            (childmenu) =>
                              childmenu?.adminmenugrup === mainmenu.adminmenugrup &&
                              childmenu?.adminmenuclass === 'Child'
                          )?.length > 0 && (
                            <>
                              <ul className="md:flex-col md:min-w-full flex flex-col list-none">
                                {menuData
                                  ?.filter(
                                    (childmenu) =>
                                      childmenu?.adminmenugrup === mainmenu.adminmenugrup &&
                                      childmenu?.adminmenuclass === 'Child'
                                  )
                                  ?.map((item) => (
                                    <li
                                      className={`items-center rounded pl-5 my-[2px] ${
                                        location.pathname === item.adminmenulink
                                          ? 'font-bold text-white bgGradientGAP'
                                          : 'font-semibold hoverGradientGAP hover:text-white hover:font-bold'
                                      }`}
                                      key={item?.adminhakaksesadminmenuid}>
                                      <Link
                                        to={item?.adminmenulink}
                                        className={`text-xs py-1 block`}
                                        state={item}
                                        onClick={() => {
                                          setCollapseShow('hidden');
                                          setActiveMainMenu(
                                            item.adminmenugrup ? item.adminmenugrup : ''
                                          );
                                        }}>
                                        <i className={`${item.adminmenuicon} mr-2 text-sm`}></i>
                                        {item.adminmenuutama}
                                      </Link>
                                    </li>
                                  ))}
                              </ul>
                            </>
                          )}
                        </NavLink>
                      </div>
                    )}
                  </div>
                ))
            )}
            <NavLink
              className="md:hidden flex font-semibold py-1 my-1 rounded hover:bg-gray-200"
              label="Logout"
              onClick={handleLogout}
              icon={<i className="fas fa-sign-out"></i>}
            />
          </div>
        </div>
      </nav>
      <div
        className="hidden md:block border-t border-customGray fixed cursor-pointer bottom-0 z-10 md:w-60 py-1 ml-2 mx-auto px-4 bg-gray-100 hover:bg-gray-200"
        onClick={handleLogout}>
        <span className="font-semibold text-sm">
          <i className="fas fa-sign-out mr-1"></i> Logout
        </span>
      </div>
    </>
  );
}
