import React, { useState } from 'react';
import { FaCalendarAlt } from 'react-icons/fa';
import ReactApexChart from 'react-apexcharts';

export default function Grafik({ title, grafikData, label, date, setDate }) {
  const [showDate, setShowDate] = useState(false);

  const grafikOptions = {
    series: [
      {
        name: label,
        data: [...grafikData.map((data) => data.total)]
      }
    ],
    options: {
      chart: {
        type: 'line',
        height: 350
      },
      dataLabels: {
        enabled: false
      },
      colors: ['#052D5A', '#0E69D3'],
      stroke: {
        curve: 'smooth'
      },
      labels: [...grafikData.map((data) => data.date)],
      xaxis: {
        type: 'datetime'
      },
      yaxis: {
        opposite: false,
        max: (max) => {
          grafikData.map((data) => {
            return (max = Math.max(max, data.total));
          });
          return max + 3;
        },
        min: 0,
        labels: {
          formatter: function (val) {
            return val.toFixed(0);
          }
        }
      }
    }
  };

  return (
    <>
      <div className="relative flex justify-between items-center rounded-t-lg p-2">
        <div className="text-darkGray font-bold ml-5">{title}</div>
        <div className="flex gap-2">
          {/* <DownloadImagePage pageId="chartPrint" filename={`${title}.pdf`} titleChart={title} dataChart={data} bgChart={true} /> */}
          {/* <DownloadPage pageId="chartPrint" filename={`${title}.pdf`} titleChart={title} dataChart={data} bgChart={true} /> */}
          {/* <div className="bg-white p-2 rounded-lg cursor-pointer hover:text-gray-500">
						<AiFillPrinter size={24} />
					</div> */}
          {/* <DownloadChart pageId="chartPrint" title={title} options={options} data={data} /> */}
          <div
            className="text-darkGray text-[14px] font-bold bg-white pl-2 pr-4 py-2 rounded-lg cursor-pointer hover:opacity-80"
            onClick={() => setShowDate(!showDate)}>
            <FaCalendarAlt size={18} />
            {/* <i className="fa-solid fa-calendar-days text-xl"></i>
            <i className="fa-regular fa-calendar fa-xl"></i>
            &nbsp;
            <span className="hidden xl:inline">Filter date</span> */}
          </div>
        </div>
      </div>
      <div className="px-5">
        <ReactApexChart
          options={grafikOptions.options}
          series={grafikOptions.series}
          type="area"
          height={350}
        />
      </div>
    </>
  );
}
