import { useState } from 'react';
import { parseToNewLine } from '../../utils/parseToNewLine';

const ReadMore = ({ children }) => {
  const text = children;
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };

  return (
    <>
      {isReadMore ? parseToNewLine(text.slice(0, 300)) : parseToNewLine(text)}
      {text.length > 300 && (
        <span onClick={toggleReadMore} className="text-blue-600 cursor-pointer">
          {isReadMore ? ' ...read more' : ' show less'}
        </span>
      )}
    </>
  );
};

export default ReadMore;
