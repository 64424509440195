import React, { useState, useEffect } from 'react';
import * as api from '../../redux/api';
import { Modal } from '@mantine/core';
import moment from 'moment';
import { parseToNewLine } from '../../utils/parseToNewLine';

const ModalKegiatanHarianDetail = ({ kode, openModalDetail, setOpenModalDetail }) => {
  const [dataKegiatan, setDataKegiatan] = useState({});

  useEffect(() => {
    if (kode !== '') {
      const getDataKegiatanByKode = async () => {
        await api.kegiatanHarianByKode({ satpamkegiatankode: kode }).then((res) => {
          setDataKegiatan(res.data.data[0]);
        });
      };

      getDataKegiatanByKode();
    }
  }, [kode]);

  return (
    <Modal
      opened={openModalDetail}
      size="xl"
      overflow="inside"
      centered
      closeOnClickOutside
      onClose={() => setOpenModalDetail(false)}>
      <div className="text-center text-lg font-bold mb-5">Detail Kegiatan Harian</div>
      <div>
        <div className="text-dark scrollbar-thin px-5">
          <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
            <div className="md:w-3/5 w-full font-bold">Kode Kegiatan</div>
            <div className="w-full">{dataKegiatan?.satpamkegiatankode}</div>
          </div>
          <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
            <div className="md:w-3/5 w-full font-bold">Nama Perusahaan</div>
            <div className="w-full">{dataKegiatan?.satpamperusahaannama}</div>
          </div>
          <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
            <div className="md:w-3/5 w-full font-bold">Kode Perusahaan</div>
            <div className="w-full">{dataKegiatan?.satpamkegiatansatpamperusahaankode}</div>
          </div>
          <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
            <div className="md:w-3/5 w-full font-bold">Kode Kegiatan</div>
            <div className="w-full">{dataKegiatan?.satpamkegiatansatpamkode}</div>
          </div>
          <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
            <div className="md:w-3/5 w-full font-bold">Nama Lengkap</div>
            <div className="w-full">{dataKegiatan?.satpamnamalengkap}</div>
          </div>
          <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
            <div className="md:w-3/5 w-full font-bold">Tanggal Melaporkan</div>
            <div className="w-full">
              {moment(dataKegiatan?.satpamkegiatantanggalmelaporkan).format('DD MMM YYYY HH:mm') ||
                '-'}
            </div>
          </div>
          <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
            <div className="md:w-3/5 w-full font-bold">Tanggal Aktifitas</div>
            <div className="w-full">
              {moment(dataKegiatan?.satpamkegiatantanggalaktifitas).format('DD MMM YYYY HH:mm') ||
                '-'}
            </div>
          </div>
          <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
            <div className="md:w-3/5 w-full font-bold">Uraian Kegiatan</div>
            <div className="w-full">
              {dataKegiatan?.satpamkegiatanuraian !== '' &&
                parseToNewLine(dataKegiatan?.satpamkegiatanuraian)}
            </div>
          </div>
          <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
            <div className="md:w-3/5 w-full font-bold">Keterangan Kegiatan</div>
            <div className="w-full">
              {dataKegiatan?.satpamkegiatanketerangan !== '' &&
                parseToNewLine(dataKegiatan?.satpamkegiatanketerangan)}
            </div>
          </div>
          {/* <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
            <div className="md:w-3/5 w-full font-bold">Latitude</div>
            <div className="w-full">{dataKegiatan?.satpamkegiatanlatitude}</div>
          </div>
          <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
            <div className="md:w-3/5 w-full font-bold">Longitude</div>
            <div className="w-full">{dataKegiatan?.satpamkegiatanlongitude}</div>
          </div> */}
          <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
            <div className="md:w-3/5 w-full font-bold">Alamat Kegiatan</div>
            <div className="w-full">{dataKegiatan?.satpamkegiatanalamat}</div>
          </div>
          <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
            <div className="md:w-3/5 w-full font-bold">Patokan</div>
            <div className="w-full">{dataKegiatan?.satpamkegiatanalamatpatokan}</div>
          </div>
          {dataKegiatan?.satpamkegiatanfotosatu?.split('/data-kegiatan/')[1].length > 0 && (
            <>
              <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
                <div className="md:w-3/5 w-full font-bold">Foto 1</div>
                <div className="w-full">
                  <img
                    className="w-full h-64 object-contain"
                    src={dataKegiatan?.satpamkegiatanfotosatu}
                    alt=""
                  />
                </div>
              </div>
              <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
                <div className="md:w-3/5 w-full font-bold">Ket. Foto 1</div>
                <div className="w-full">{dataKegiatan?.satpamkegiatanketeranganfotosatu}</div>
              </div>
            </>
          )}
          {dataKegiatan?.satpamkegiatanfotodua?.split('/data-kegiatan/')[1].length > 0 && (
            <>
              <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
                <div className="md:w-3/5 w-full font-bold">Foto 1</div>
                <div className="w-full">
                  <img
                    className="w-full h-64 object-contain"
                    src={dataKegiatan?.satpamkegiatanfotodua}
                    alt=""
                  />
                </div>
              </div>
              <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
                <div className="md:w-3/5 w-full font-bold">Ket. Foto 2</div>
                <div className="w-full">{dataKegiatan?.satpamkegiatanketeranganfotodua}</div>
              </div>
            </>
          )}
          {dataKegiatan?.satpamkegiatanfototiga?.split('/data-kegiatan/')[1].length > 0 && (
            <>
              <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
                <div className="md:w-3/5 w-full font-bold">Foto 3</div>
                <div className="w-full">
                  <img
                    className="w-full h-64 object-contain"
                    src={dataKegiatan?.satpamkegiatanfototiga}
                    alt=""
                  />
                </div>
              </div>
              <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
                <div className="md:w-3/5 w-full font-bold">Ket. Foto 3</div>
                <div className="w-full">{dataKegiatan?.satpamkegiatanketeranganfototiga}</div>
              </div>
            </>
          )}
          {dataKegiatan?.satpamkegiatanfotoempat?.split('/data-kegiatan/')[1].length > 0 && (
            <>
              <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
                <div className="md:w-3/5 w-full font-bold">Foto 4</div>
                <div className="w-full">
                  <img
                    className="w-full h-64 object-contain"
                    src={dataKegiatan?.satpamkegiatanfotoempat}
                    alt=""
                  />
                </div>
              </div>
              <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
                <div className="md:w-3/5 w-full font-bold">Ket. Foto 4</div>
                <div className="w-full">{dataKegiatan?.satpamkegiatanketeranganfotoempat}</div>
              </div>
            </>
          )}
          {dataKegiatan?.satpamkegiatanfotolima?.split('/data-kegiatan/')[1].length > 0 && (
            <>
              <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
                <div className="md:w-3/5 w-full font-bold">Foto 5</div>
                <div className="w-full">
                  <img
                    className="w-full h-64 object-contain"
                    src={dataKegiatan?.satpamkegiatanfotolima}
                    alt=""
                  />
                </div>
              </div>
              <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
                <div className="md:w-3/5 w-full font-bold">Ket. Foto 5</div>
                <div className="w-full">{dataKegiatan?.satpamkegiatanketeranganfotolima}</div>
              </div>
            </>
          )}
          <div className="flex justify-end mt-5">
            <div
              className="w-fit text-gray-50 py-2 px-3 rounded-md text-xs bg-dark hover:opacity-80 cursor-pointer"
              onClick={() => setOpenModalDetail(false)}>
              Kembali
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ModalKegiatanHarianDetail;
