// Import packages
import React, { useState, useEffect } from 'react';
import * as api from './redux/api';
import { Routes, Route, Navigate } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import PrivateRoute from './components/PrivateRoute';
import Login from './pages/auth/Login';
import Unauthorized from './pages/Unauthorized';
import NotFoundPage from './pages/NotFoundPage';
import Dashboard from './pages/dashboard/Dashboard';
import SatpamCreate from './pages/satpam/SatpamCreate';
import SatpamData from './pages/satpam/SatpamData';
import SatpamUpdate from './pages/satpam/SatpamUpdate';
import SatpamCetak from './components/satpam/SatpamCetak';
import KegiatanHarian from './pages/kegiatan-harian/KegiatanHarian';
import KegiatanHarianCreate from './pages/kegiatan-harian/KegiatanHarianCreate';
import KegiatanHarianPrint from './pages/kegiatan-harian/KegiatanHarianPrint';
import KegiatanHarianPrintPerKeg from './pages/kegiatan-harian/KegiatanHarianPrintPerKeg';
import KegiatanHarianUpdate from './pages/kegiatan-harian/KegiatanHarianUpdate';
import Kejadian from './pages/kejadian/Kejadian';
import KejadianCreate from './pages/kejadian/KejadianCreate';
import KejadianPrint from './pages/kejadian/KejadianPrint';
import KejadianPrintPerKej from './pages/kejadian/KejadianPrintPerKej';
import KejadianUpdate from './pages/kejadian/KejadianUpdate';
import Temuan from './pages/temuan/Temuan';
import TemuanCreate from './pages/temuan/TemuanCreate';
import TemuanPrint from './pages/temuan/TemuanPrint';
import TemuanPrintPerTem from './pages/temuan/TemuanPrintPerTem';
import TemuanUpdate from './pages/temuan/TemuanUpdate';
import LaporanSemua from './pages/laporan-semua/LaporanSemua';
import Patroli from './pages/patroli/Patroli';
import PatroliPrintPerPat from './pages/patroli/PatroliPrintPerPat';
import PatroliCreate from './pages/patroli/PatroliCreate';
import PatroliPrint from './pages/patroli/PatroliPrint';
import POSCreate from './pages/pos-checkpoint/POSCreate';
import CheckpointCreate from './pages/pos-checkpoint/CheckpointCreate';
import POSData from './pages/pos-checkpoint/POSData';
import POSUpdate from './pages/pos-checkpoint/POSUpdate';
import CheckpointData from './pages/pos-checkpoint/CheckpointData';
import CheckpointUpdate from './pages/pos-checkpoint/CheckpointUpdate';
import JadwalCreate from './pages/jadwal/JadwalCreate';
import Analisis from './pages/analisis/Analisis';
import TimeSheet from './pages/timesheet/TimeSheet';
import PerusahaanData from './pages/perusahaan/PerusahaanData';
import PerusahaanCreate from './pages/perusahaan/PerusahaanCreate';
import PetugasData from './pages/petugas/PetugasData';
import PetugasCreate from './pages/petugas/PetugasCreate';
import PerusahaanUpdate from './pages/perusahaan/PerusahaanUpdate';
import PetugasUpdate from './pages/petugas/PetugasUpdate';
import InformasiData from './pages/informasi/InformasiData';
import InformasiCreate from './pages/informasi/InformasiCreate';
import InformasiUpdate from './pages/informasi/InformasiUpdate';
import NotifikasiData from './pages/notifikasi/NotifikasiData';
import InboxData from './pages/inbox/InboxData';
import InboxCreate from './pages/inbox/InboxCreate';
import InboxUpdate from './pages/inbox/InboxUpdate';
import IzinData from './pages/izin/IzinData';
import KeadaanData from './pages/keadaan/KeadaanData';
import KeluhanData from './pages/keluhan/KeluhanData';
import TimeSheetPrint from './pages/timesheet/TimeSheetPrint';

const Jadwal = React.lazy(() => import('./pages/jadwal/Jadwal'));

const ROLES = {
  ADMIN_PROJECT: 'Admin Project',
  ADMIN_CLIENT: 'Admin Client'
};

export default function Router() {
  const menuData = JSON.parse(localStorage.getItem('menuData'));

  const profile = JSON.parse(localStorage.getItem('profile'));
  const token = profile?.access_token;
  const role = profile?.user?.adminpetugastipe;

  const [satpamData, setSatpamData] = useState([]);
  const [satpamUtama, setSatpamUtama] = useState([]);
  const [satpamCadangan, setSatpamCadangan] = useState([]);
  const [satpamJumlah, setSatpamJumlah] = useState([]);

  useEffect(() => {
    const getDataSatpam = async () => {
      const res = await api.satpamData();
      setSatpamData(res.data.data);
      setSatpamJumlah(res.data);
    };
    const getSatpamFilter = async () => {
      const resUtama = await api.satpamByFilter({ satpamtipe: 'Utama' });
      const resCadangan = await api.satpamByFilter({ satpamtipe: 'Cadangan' });
      setSatpamUtama(resUtama.data.data);
      setSatpamCadangan(resCadangan.data.data);
    };
    if (token) {
      getDataSatpam();
      getSatpamFilter();
    }
  }, [token]);

  return (
    <>
      <Routes>
        <Route path="/auth/login" element={token ? <Navigate to="/" /> : <Login />} />
        {/* <Route path="/auth/register" element={<Register />} /> */}

        <Route path="/unauthorized" element={<Unauthorized />} />
        <Route path="/*" element={<NotFoundPage />} />

        {/* Private Route */}
        {/* Role: Admin Project and Admin Client */}

        {/* Dashboard */}
        <Route
          element={
            <PrivateRoute
              allowedRoles={[ROLES.ADMIN_PROJECT, ROLES.ADMIN_CLIENT]}
              authorized={true}
            />
          }>
          <Route path="/" element={<Dashboard satpamData={satpamData} />} />
        </Route>

        {/* Satpam Pages */}
        <Route
          element={
            <PrivateRoute
              allowedRoles={[ROLES.ADMIN_PROJECT, ROLES.ADMIN_CLIENT]}
              authorized={menuData?.find((menu) =>
                menu.adminmenulink.includes('/personil') ? true : false
              )}
            />
          }>
          <Route
            path="/personil/data"
            element={<SatpamData satpamData={satpamData} satpamJumlah={satpamJumlah} role={role} />}
          />
          <Route path="/personil/cetak-all" element={<SatpamCetak satpamData={satpamData} />} />
          <Route path="/personil/cetak-utama" element={<SatpamCetak satpamData={satpamUtama} />} />
          <Route
            path="/personil/cetak-cadangan"
            element={<SatpamCetak satpamData={satpamCadangan} />}
          />
        </Route>

        <Route
          element={
            <PrivateRoute
              allowedRoles={[ROLES.ADMIN_PROJECT]}
              authorized={menuData?.find((menu) =>
                menu.adminmenulink.includes('/personil') ? true : false
              )}
            />
          }>
          <Route path="/personil/registrasi" element={<SatpamCreate />} />
          <Route path="/personil/update/:id" element={<SatpamUpdate />} />
        </Route>

        {/* Kegiatan Harian */}
        <Route
          element={
            <PrivateRoute
              allowedRoles={[ROLES.ADMIN_PROJECT, ROLES.ADMIN_CLIENT]}
              authorized={menuData?.find((menu) =>
                menu.adminmenulink.includes('/kegiatan-harian') ? true : false
              )}
            />
          }>
          <Route path="/kegiatan-harian" element={<KegiatanHarian role={role} />} />
          <Route
            path="/kegiatan-harian-print"
            element={
              <React.Suspense
                fallback={
                  <div className="flex min-h-screen-75 justify-center items-center">
                    <ClipLoader color="black" loading={true} size={40} speedMultiplier={0.7} />
                  </div>
                }>
                <KegiatanHarianPrint />
              </React.Suspense>
            }
          />
          <Route
            path="/kegiatan-harian-printperkeg/:kode"
            element={<KegiatanHarianPrintPerKeg />}
          />
        </Route>

        <Route
          element={
            <PrivateRoute
              allowedRoles={[ROLES.ADMIN_PROJECT]}
              authorized={menuData?.find((menu) =>
                menu.adminmenulink.includes('/kegiatan-harian') ? true : false
              )}
            />
          }>
          <Route
            path="/kegiatan-harian/create"
            element={<KegiatanHarianCreate satpamData={satpamData} />}
          />
          <Route path="/kegiatan-harian/update/:kode" element={<KegiatanHarianUpdate />} />
        </Route>

        {/* Kejadian */}
        <Route
          element={
            <PrivateRoute
              allowedRoles={[ROLES.ADMIN_PROJECT, ROLES.ADMIN_CLIENT]}
              authorized={menuData?.find((menu) =>
                menu.adminmenulink.includes('/kejadian') ? true : false
              )}
            />
          }>
          <Route path="/kejadian" element={<Kejadian role={role} />} />
          <Route
            path="/kejadian-print"
            element={
              <React.Suspense
                fallback={
                  <div className="flex min-h-screen-75 justify-center items-center">
                    <ClipLoader color="black" loading={true} size={40} speedMultiplier={0.7} />
                  </div>
                }>
                <KejadianPrint />
              </React.Suspense>
            }
          />
          <Route path="/kejadian-printperkej/:kode" element={<KejadianPrintPerKej />} />
        </Route>

        <Route
          element={
            <PrivateRoute
              allowedRoles={[ROLES.ADMIN_PROJECT]}
              authorized={menuData?.find((menu) =>
                menu.adminmenulink.includes('/kejadian') ? true : false
              )}
            />
          }>
          <Route path="/kejadian/create" element={<KejadianCreate />} />
          <Route path="/kejadian/update/:kode" element={<KejadianUpdate />} />
        </Route>

        {/* Temuan */}
        <Route
          element={
            <PrivateRoute
              allowedRoles={[ROLES.ADMIN_PROJECT, ROLES.ADMIN_CLIENT]}
              authorized={menuData?.find((menu) =>
                menu.adminmenulink.includes('/temuan') ? true : false
              )}
            />
          }>
          <Route path="/temuan" element={<Temuan role={role} />} />
          <Route path="/temuan-print" element={<TemuanPrint />} />
          <Route path="/temuan-printpertem/:kode" element={<TemuanPrintPerTem />} />
        </Route>

        <Route
          element={
            <PrivateRoute
              allowedRoles={[ROLES.ADMIN_PROJECT]}
              authorized={menuData?.find((menu) =>
                menu.adminmenulink.includes('/temuan') ? true : false
              )}
            />
          }>
          <Route path="/temuan/create" element={<TemuanCreate />} />
          <Route path="/temuan/update/:kode" element={<TemuanUpdate />} />
        </Route>

        {/* Patroli */}
        <Route
          element={
            <PrivateRoute
              allowedRoles={[ROLES.ADMIN_PROJECT, ROLES.ADMIN_CLIENT]}
              authorized={menuData?.find((menu) =>
                menu.adminmenulink.includes('/patroli') ? true : false
              )}
            />
          }>
          <Route path="/patroli" element={<Patroli role={role} />} />
          <Route path="/patroli-print" element={<PatroliPrint />} />
          <Route path="/patroli-printperpat/:kode" element={<PatroliPrintPerPat />} />
        </Route>

        <Route
          element={
            <PrivateRoute
              allowedRoles={[ROLES.ADMIN_PROJECT]}
              authorized={menuData?.find((menu) =>
                menu.adminmenulink.includes('/patroli') ? true : false
              )}
            />
          }>
          <Route path="/patroli/create" element={<PatroliCreate />} />
        </Route>

        {/* Filter Laporan */}
        <Route
          element={
            <PrivateRoute
              allowedRoles={[ROLES.ADMIN_PROJECT, ROLES.ADMIN_CLIENT]}
              authorized={menuData?.find((menu) =>
                menu.adminmenulink.includes('/laporan-semua') ? true : false
              )}
            />
          }>
          <Route path="/laporan-semua" element={<LaporanSemua />} />
        </Route>

        {/* Analisis */}
        <Route
          element={
            <PrivateRoute
              allowedRoles={[ROLES.ADMIN_PROJECT, ROLES.ADMIN_CLIENT]}
              authorized={menuData?.find((menu) =>
                menu.adminmenulink.includes('/laporan-analisis') ? true : false
              )}
            />
          }>
          <Route path="/laporan-analisis" element={<Analisis />} />
        </Route>

        {/* POS */}
        <Route
          element={
            <PrivateRoute
              allowedRoles={[ROLES.ADMIN_PROJECT, ROLES.ADMIN_CLIENT]}
              authorized={menuData?.find((menu) =>
                menu.adminmenulink.includes('/pos') ? true : false
              )}
            />
          }>
          <Route path="/pos" element={<POSData role={role} />} />
        </Route>

        <Route
          element={
            <PrivateRoute
              allowedRoles={[ROLES.ADMIN_PROJECT]}
              authorized={menuData?.find((menu) =>
                menu.adminmenulink.includes('/pos') ? true : false
              )}
            />
          }>
          <Route path="/pos/create" element={<POSCreate />} />
          <Route path="/pos/update/:kode" element={<POSUpdate />} />
        </Route>

        {/* Checkpoint */}
        <Route
          element={
            <PrivateRoute
              allowedRoles={[ROLES.ADMIN_PROJECT, ROLES.ADMIN_CLIENT]}
              authorized={menuData?.find((menu) =>
                menu.adminmenulink.includes('/checkpoint') ? true : false
              )}
            />
          }>
          <Route path="/checkpoint" element={<CheckpointData role={role} />} />
        </Route>

        <Route
          element={
            <PrivateRoute
              allowedRoles={[ROLES.ADMIN_PROJECT]}
              authorized={menuData?.find((menu) =>
                menu.adminmenulink.includes('/checkpoint') ? true : false
              )}
            />
          }>
          <Route path="/checkpoint/create" element={<CheckpointCreate />} />
          <Route path="/checkpoint/update/:kode" element={<CheckpointUpdate />} />
        </Route>

        {/* Roster / Jadwal */}
        <Route
          element={
            <PrivateRoute
              allowedRoles={[ROLES.ADMIN_PROJECT, ROLES.ADMIN_CLIENT]}
              authorized={menuData?.find((menu) =>
                menu.adminmenulink.includes('/personil/jadwal') ? true : false
              )}
            />
          }>
          <Route
            path="/personil/jadwal"
            element={
              <React.Suspense
                fallback={
                  <div className="flex min-h-screen-75 justify-center items-center">
                    <ClipLoader color="black" loading={true} size={40} speedMultiplier={0.7} />
                  </div>
                }>
                <Jadwal role={role} />
              </React.Suspense>
            }
          />
        </Route>

        <Route
          element={
            <PrivateRoute
              allowedRoles={[ROLES.ADMIN_PROJECT]}
              authorized={menuData?.find((menu) =>
                menu.adminmenulink.includes('/personil/jadwal') ? true : false
              )}
            />
          }>
          <Route path="/personil/jadwal/create" element={<JadwalCreate />} />
        </Route>

        {/* Izin */}
        <Route
          element={
            <PrivateRoute
              allowedRoles={[ROLES.ADMIN_PROJECT, ROLES.ADMIN_CLIENT]}
              authorized={menuData?.find((menu) =>
                menu.adminmenulink.includes('/personil/izin') ? true : false
              )}
            />
          }>
          <Route path="/personil/izin" element={<IzinData role={role} />} />
        </Route>

        {/* Timesheet */}
        <Route
          element={
            <PrivateRoute
              allowedRoles={[ROLES.ADMIN_PROJECT, ROLES.ADMIN_CLIENT]}
              authorized={menuData?.find((menu) =>
                menu.adminmenulink.includes('/personil/timesheet') ? true : false
              )}
            />
          }>
          <Route path="/personil/timesheet" element={<TimeSheet role={role} />} />
          <Route path="/personil/timesheet/print" element={<TimeSheetPrint />} />
        </Route>

        {/* Perusahaan */}
        <Route
          element={
            <PrivateRoute
              allowedRoles={[ROLES.ADMIN_PROJECT, ROLES.ADMIN_CLIENT]}
              authorized={menuData?.find((menu) =>
                menu.adminmenulink.includes('/perusahaan') ? true : false
              )}
            />
          }>
          <Route path="/perusahaan" element={<PerusahaanData />} />
          <Route path="/perusahaan/create" element={<PerusahaanCreate />} />
          <Route path="/perusahaan/update/:kode" element={<PerusahaanUpdate />} />
        </Route>

        {/* Petugas */}
        <Route
          element={
            <PrivateRoute
              allowedRoles={[ROLES.ADMIN_PROJECT, ROLES.ADMIN_CLIENT]}
              authorized={menuData?.find((menu) =>
                menu.adminmenulink.includes('/petugas') ? true : false
              )}
            />
          }>
          <Route path="/petugas" element={<PetugasData />} />
          <Route path="/petugas/create" element={<PetugasCreate />} />
          <Route path="/petugas/update/:kode" element={<PetugasUpdate />} />
        </Route>

        {/* Informasi */}
        <Route
          element={
            <PrivateRoute
              allowedRoles={[ROLES.ADMIN_PROJECT, ROLES.ADMIN_CLIENT]}
              authorized={menuData?.find((menu) =>
                menu.adminmenulink.includes('/informasi') ? true : false
              )}
            />
          }>
          <Route path="/informasi" element={<InformasiData role={role} />} />
        </Route>

        <Route
          element={
            <PrivateRoute
              allowedRoles={[ROLES.ADMIN_PROJECT]}
              authorized={menuData?.find((menu) =>
                menu.adminmenulink.includes('/informasi') ? true : false
              )}
            />
          }>
          <Route path="/informasi/create" element={<InformasiCreate />} />
          <Route path="/informasi/update/:kode" element={<InformasiUpdate />} />
        </Route>

        {/* Notifikasi */}
        <Route
          element={
            <PrivateRoute
              allowedRoles={[ROLES.ADMIN_PROJECT, ROLES.ADMIN_CLIENT]}
              authorized={menuData?.find((menu) =>
                menu.adminmenulink.includes('/notifikasi') ? true : false
              )}
            />
          }>
          <Route path="/notifikasi" element={<NotifikasiData role={role} />} />
        </Route>

        {/* Inbox */}
        <Route
          element={
            <PrivateRoute
              allowedRoles={[ROLES.ADMIN_PROJECT, ROLES.ADMIN_CLIENT]}
              authorized={menuData?.find((menu) =>
                menu.adminmenulink.includes('/inbox') ? true : false
              )}
            />
          }>
          <Route path="/inbox" element={<InboxData />} />
        </Route>

        <Route
          element={
            <PrivateRoute
              allowedRoles={[ROLES.ADMIN_PROJECT]}
              authorized={menuData?.find((menu) =>
                menu.adminmenulink.includes('/inbox') ? true : false
              )}
            />
          }>
          <Route path="/inbox/create" element={<InboxCreate />} />
          <Route path="/inbox/update/:kode" element={<InboxUpdate />} />
        </Route>

        {/* Keadaan */}
        <Route
          element={
            <PrivateRoute
              allowedRoles={[ROLES.ADMIN_PROJECT, ROLES.ADMIN_CLIENT]}
              authorized={menuData?.find((menu) =>
                menu.adminmenulink.includes('/keadaan-darurat') ? true : false
              )}
            />
          }>
          <Route path="/keadaan-darurat" element={<KeadaanData role={role} />} />
        </Route>

        {/* Keluhan */}
        <Route
          element={
            <PrivateRoute
              allowedRoles={[ROLES.ADMIN_PROJECT, ROLES.ADMIN_CLIENT]}
              authorized={menuData?.find((menu) =>
                menu.adminmenulink.includes('/keluhan') ? true : false
              )}
            />
          }>
          <Route path="/keluhan" element={<KeluhanData role={role} />} />
        </Route>
      </Routes>
    </>
  );
}
