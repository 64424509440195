import moment from 'moment';
import React from 'react';
import Headerlaporan from '../general/HeaderLaporan';
import TimelineSatpamProses from '../general/TimelineSatpamProses';

const TableCetakTemuan = ({ data, date }) => {
  return (
    <div className="w-full">
      {data.map((item) => (
        <div key={item.satpamtemuankode}>
          {typeof date !== 'undefined' && (
            <h4 className="text-md p-2">
              <span className="font-bold">Periode : </span>
              {moment(date.startDate).format('DD MMM YYYY')} s.d{' '}
              {moment(date.endDate).format('DD MMM YYYY')}
            </h4>
          )}
          <div className="flex w-full">
            <Headerlaporan />
            <div className="w-full px-5 bg-black flex flex-col items-end justify-center text-white">
              <div className="text-[18px] font-bold">LAPORAN TEMUAN</div>
              <div className="text-[14px]">{data.satpamperusahaannama}</div>
            </div>
          </div>
          <div className="m-5">
            <table className="border-gray-50" id="kegiatanHarianPrint">
              <thead className="bg-primary font-bold text-white text-[12px]">
                <tr>
                  <th colSpan="7" className="border border-gray-300 py-2 px-2 text-left">
                    <p className="font-semibold text-[18px]">#{item?.satpamtemuankode}</p>
                  </th>
                </tr>
              </thead>
              <tbody className="text-black">
                <tr>
                  <td
                    colSpan="2"
                    className="w-[30%] border border-gray-300 py-2 px-2 text-left text-primary font-semibold"
                    height="5px">
                    {moment(item?.satpamtemuantanggalmelaporkan).format('DD MMM YYYY HH:mm')}
                  </td>
                  <td
                    colSpan="4"
                    rowSpan="4"
                    className="border border-gray-300 py-2 px-2 text-left align-top">
                    <h4 className="font-semibold">DETAIL TEMUAN</h4>
                    <p className="mb-2">{item?.satpamtemuanuraian}</p>
                    <h4 className="font-semibold">TINDAKAN</h4>
                    <p className="mb-2">{item?.satpamtemuantindakan}</p>
                    <h4 className="font-semibold">HASIL TINDAKAN</h4>
                    <p>{item?.satpamtemuanhasil}</p>
                  </td>
                  <td
                    rowSpan="5"
                    className="w-[20%] border border-gray-300 py-2 px-2 text-left align-top">
                    <h4 className="font-semibold mb-2">DETAIL PROSES</h4>
                    <TimelineSatpamProses data={item?.satpam_proses ? item?.satpam_proses : ''} />
                  </td>
                </tr>
                <tr>
                  <td colSpan="2" className="border border-gray-300 py-2 px-2 text-left">
                    <span className="font-semibold">PATOKAN </span>:{' '}
                    {item?.satpamtemuanalamatpatokan}
                    <br />
                    <span className="font-semibold">ALAMAT LENGKAP</span> :{' '}
                    {item?.satpamtemuanalamat}
                  </td>
                </tr>
                <tr>
                  <td className="border border-gray-300 py-2 px-2 text-left w-[25%]">
                    <p className="font-semibold">KATEGORI :</p>
                    <p>{item?.satpamtemuankategorinama}</p>
                  </td>
                </tr>
                <tr>
                  <td className="border border-gray-300 py-2 px-2 text-left">
                    <h4 className="text-md font-bold">PELAPOR : {item?.satpamnamalengkap}</h4>
                  </td>
                </tr>
                <tr>
                  <td colSpan="5" className="border border-gray-300 p-5">
                    <div className="mx-auto">
                      <div className="flex flex-wrap -m-1 md:-m-2">
                        {item?.satpamtemuanfotosatu?.split('/data-temuan/')[1] && (
                          <div className="flex flex-wrap w-1/5">
                            <div className="w-full p-1 md:p-2">
                              <div className="min-h-52 max-w-sm bg-white rounded-md border border-gray-200 shadow-md break-all">
                                <img
                                  className="rounded-t-md h-36 object-cover w-full"
                                  src={item?.satpamtemuanfotosatu}
                                  alt=""
                                />
                                <div className="p-3">
                                  <p className="mb-2">{item?.satpamtemuanketeranganfotosatu}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        {item?.satpamtemuanfotodua?.split('/data-temuan/')[1] && (
                          <div className="flex flex-wrap w-1/5">
                            <div className="w-full p-1 md:p-2">
                              <div className="min-h-52 max-w-sm bg-white rounded-md border border-gray-200 shadow-md break-all">
                                <img
                                  className="rounded-t-md h-36 object-cover w-full"
                                  src={item?.satpamtemuanfotodua}
                                  alt=""
                                />
                                <div className="p-3">
                                  <p className="mb-2">{item?.satpamtemuanketeranganfotodua}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        {item?.satpamtemuanfototiga?.split('/data-temuan/')[1] && (
                          <div className="flex flex-wrap w-1/5">
                            <div className="w-full p-1 md:p-2">
                              <div className="min-h-52 max-w-sm bg-white rounded-md border border-gray-200 shadow-md break-all">
                                <img
                                  className="rounded-t-md h-36 object-cover w-full"
                                  src={item?.satpamtemuanfototiga}
                                  alt=""
                                />
                                <div className="p-3">
                                  <p className="mb-2">{item?.satpamtemuanketeranganfototiga}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        {item?.satpamtemuanfotoempat?.split('/data-temuan/')[1] && (
                          <div className="flex flex-wrap w-1/5">
                            <div className="w-full p-1 md:p-2">
                              <div className="min-h-52 max-w-sm bg-white rounded-md border border-gray-200 shadow-md break-all">
                                <img
                                  className="rounded-t-md h-36 object-cover w-full"
                                  src={item?.satpamtemuanfotoempat}
                                  alt=""
                                />
                                <div className="p-3">
                                  <p className="mb-2">{item?.satpamtemuanketeranganfotoempat}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        {item?.satpamtemuanfotolima?.split('/data-temuan/')[1] && (
                          <div className="flex flex-wrap w-1/5">
                            <div className="w-full p-1 md:p-2">
                              <div className="min-h-52 max-w-sm bg-white rounded-md border border-gray-200 shadow-md break-all">
                                <img
                                  className="rounded-t-md h-36 object-cover w-full"
                                  src={item?.satpamtemuanfotolima}
                                  alt=""
                                />
                                <div className="p-3">
                                  <p className="mb-2">{item?.satpamtemuanketeranganfotolima}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </td>
                </tr>
                <tr className="w-full">
                  <td colSpan="3" className="border border-gray-300 p-2 text-left align-top">
                    <p className="font-semibold">POTENSI TEMUAN :</p>
                    <p>
                      {item?.satpamtemuanpotensi !== null
                        ? item?.satpamtemuanpotensi
                        : 'Belum ada potensi'}
                    </p>
                    <p className="font-semibold mt-2">ANALISIS TEMUAN :</p>
                    <p>
                      {item?.satpamtemuananalisis !== null
                        ? item?.satpamtemuananalisis
                        : 'Belum ada analisis'}
                    </p>
                  </td>
                  <td colSpan="3" className="border border-gray-300 p-2 text-left align-top">
                    <p className="font-semibold">KESIMPULAN TEMUAN :</p>
                    <p>
                      {item?.satpamtemuankesimpulan !== null
                        ? item?.satpamtemuankesimpulan
                        : 'Belum ada kesimpulan'}
                    </p>
                    <p className="font-semibold mt-2">SARAN DAN REKOMENDASI :</p>
                    <p>
                      {item?.satpamtemuanrekomendasi !== null
                        ? item?.satpamtemuanrekomendasi
                        : 'Belum ada rekomendasi'}
                    </p>
                  </td>
                  <td className="border border-gray-300 px-2 py-2 text-center  align-middle">
                    <div>Tanda Tangan</div>
                    <div className="border mt-16 mb-5 mx-5" />
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="text-center w-full my-2 text-black">
              <b>Copyright@2022</b>
              <br />
              <b>PT. Kalpika Loka Persada</b>
            </div>
          </div>
          <div className="page-break" />
        </div>
      ))}
    </div>
  );
};

export default TableCetakTemuan;
