// Import packages
import React, { useState, useEffect } from 'react';
import * as api from '../../redux/api';
import { Modal, Tooltip } from '@mantine/core';
import { Popover } from '@mantine/core';
import Table from '../../components/general/Table';
import { AiOutlineFileAdd } from 'react-icons/ai';
import Swal from 'sweetalert2';
import moment from 'moment/moment';
import ModalCreateIzin from '../../components/izin/ModalCreateIzin';
import ModalDetailIzin from '../../components/izin/ModalDetailIzin';
import ModalUpdateIzin from '../../components/izin/ModalUpdateIzin';
import { useDebouncedValue } from '@mantine/hooks';

export default function IzinData({ role }) {
  const [showImage, setShowImage] = useState(false);
  const [imageData, setImageData] = useState(null);
  const [data, setData] = useState([]);
  const [openModalCreate, setOpenModalCreate] = useState(false);
  const [openModalUpdate, setOpenModalUpdate] = useState(false);
  const [openModalDetail, setOpenModalDetail] = useState(false);
  const [detailKode, setDetailKode] = useState('');

  const [filterData, setFilterData] = useState([]);

  const [query, setQuery] = useState('');
  const [debouncedQuery] = useDebouncedValue(query, 200);

  // Get data
  const getData = async () => {
    await api.izinData().then((res) => {
      setData(res.data.data);
    });
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    setFilterData(
      data.filter(
        ({ satpamizintanggal, satpamnamalengkap, satpamizinjenis, satpamjadwalmasuktanggal }) => {
          if (
            debouncedQuery !== '' &&
            !`${satpamizintanggal} ${satpamnamalengkap} ${satpamizinjenis} ${satpamjadwalmasuktanggal}`
              .toLowerCase()
              .includes(debouncedQuery.trim().toLowerCase())
          ) {
            return false;
          }
          return true;
        }
      )
    );
  }, [data, debouncedQuery]);

  useEffect(() => {
    if (!openModalUpdate) {
      setDetailKode('');
    }
  }, [openModalUpdate]);

  const handleOpenModalDetail = (kode) => {
    setDetailKode(kode);
    setOpenModalDetail(true);
  };

  const handleOpenModalUpdate = (kode) => {
    setDetailKode(kode);
    setOpenModalUpdate(true);
  };

  const handleImageData = (satpamizinfoto) => {
    setImageData(
      satpamizinfoto?.split('/data-izin/')[1] ? satpamizinfoto : require('../../images/logo.png')
    );
    setShowImage(!showImage);
  };

  const handleDelete = (kode) => {
    Swal.fire({
      text: 'Do you want to delete this inbox?',
      showCancelButton: true,
      confirmButtonText: 'Yes'
    }).then((result) => {
      if (result.isConfirmed) {
        const deleteData = async () => {
          await api
            .izinDelete(kode)
            .then((res) => {
              Swal.fire('Good job!', res.data.message, 'success');
              getData();
            })
            .catch((err) => {
              Swal.fire({ icon: 'error', title: 'Oops...', text: err.response.data.message });
            });
        };
        deleteData();
      }
    });
  };

  // Column Data Table
  const columnData = [
    {
      title: 'Action',
      accessor: 'satpamizinkode',
      width: 100,
      render: ({ satpamizinkode }) => (
        <div className="flex items-center gap-1">
          {/* Button Aksi */}
          <Popover width={110} position="right" withArrow shadow="xl">
            <Popover.Target>
              <div className="btnAksi my-1">Action</div>
            </Popover.Target>
            <Popover.Dropdown className="popOverDropdown">
              <div
                size="sm"
                className="popOverItem"
                onClick={() => handleOpenModalDetail(satpamizinkode)}>
                <i className="fa-solid fa-circle-info mr-[2px]"></i> Detail
              </div>
              {role === 'Admin Project' && (
                <>
                  <div
                    className="popOverItem"
                    onClick={() => handleOpenModalUpdate(satpamizinkode)}>
                    <i className="fa-solid fa-pen mr-[2px]"></i> Update
                  </div>
                  <div className="popOverItem" onClick={() => handleDelete(satpamizinkode)}>
                    <i className="fa-solid fa-trash mr-[2px]"></i> Delete
                  </div>
                </>
              )}
            </Popover.Dropdown>
          </Popover>
        </div>
      )
    },
    {
      title: 'Gambar',
      accessor: 'satpamizinfoto',
      width: 150,

      render: ({ satpamizinfoto }) => (
        <div className="h-32 mt-1 mb-2 flex justify-center cursor-pointer hover:opacity-80">
          {satpamizinfoto.split('/data-izin/')[1] ? (
            <img
              src={satpamizinfoto}
              alt=""
              className="h-full object-contain rounded"
              onClick={() => handleImageData(satpamizinfoto)}
            />
          ) : (
            <img
              src={require('../../images/logo.png')}
              alt=""
              className="h-full object-contain rounded"
            />
          )}
        </div>
      )
    },
    {
      title: 'Tanggal Pengajuan, Status',
      accessor: 'satpamizinstatus',
      width: 180,
      render: ({ satpamizintanggal, satpamizinstatus }) => (
        <div>
          <div className="flex justify-center text-center h-auto w-full py-0 my-0 mb-1 cursor-pointer">
            {moment(satpamizintanggal).format('DD MMM YYYY HH:mm')}
          </div>
          {satpamizinstatus === '0' ? (
            <div className="px-4 border-secondary text-sm border-2 text-secondary btnStatus rounded-full block w-full text-center">
              Belum disetujui
            </div>
          ) : satpamizinstatus === '1' ? (
            <div className="px-4 border-primary text-sm border-2 text-primary btnStatus rounded-full block w-full text-center">
              Disetujui
            </div>
          ) : (
            <div className="px-4 border-errorText border-2 text-sm text-errorText btnStatus block w-full rounded-full text-center">
              Ditolak
            </div>
          )}
        </div>
      )
    },
    {
      title: 'Personil',
      accessor: 'satpamnamalengkap',
      width: 200
    },
    {
      title: 'Jenis',
      accessor: 'satpamizinjenis',
      tooltipaccessor: 'satpamizinjenis',
      width: 100
    },
    {
      title: 'Tanggal Jadwal',
      accessor: 'satpamjadwalmasuktanggal',
      width: 130,
      render: ({ satpamjadwalmasuktanggal }) => (
        <>{moment(satpamjadwalmasuktanggal).format('DD MMM YYYY')}</>
      )
    },
    {
      title: 'Keterangan',
      accessor: 'satpamizinketerangan',
      width: 300
    }
  ];

  return (
    <div className="relative px-4 md:px-10 mx-auto w-full -mt-8 overflow-x-hidden scrollbar-thin scrollbar-track-gray-100 scrollbar-thumb-gray-300">
      <div className="w-full min-h-screen">
        <div className="w-full justify-center bg-gray-50 rounded-lg shadowAll pb-8">
          <div className="flex flex-wrap lg:flex-nowrap justify-beetween items-center">
            <div className="flex flex-col md:flex-row flex-wrap justify-start items-center w-full"></div>
            {role === 'Admin Project' && (
              <div className="my-3 px-5 flex justify-end">
                <div className="flex gap-4 w-full">
                  <Tooltip label="Ajukan Izin">
                    <div
                      className="bg-customGray p-2 rounded-md cursor-pointer hover:opacity-80"
                      onClick={() => setOpenModalCreate(true)}>
                      <AiOutlineFileAdd size={24} />
                    </div>
                  </Tooltip>
                </div>
              </div>
            )}
          </div>
          {/* Table Component */}
          {data?.length > 0 ? (
            <div className="p-5">
              <Table
                data={filterData}
                columnData={columnData}
                setQuery={setQuery}
                id="satpamizinkode"
              />
            </div>
          ) : (
            <div className="text-center">
              <div className="mt-10 mb-72">Tidak ada data izin.</div>
            </div>
          )}
        </div>
        {/* Modal Create Izin*/}
        <ModalCreateIzin
          getDataIzin={getData}
          openModalCreate={openModalCreate}
          setOpenModalCreate={setOpenModalCreate}
          setDetailKode={setDetailKode}
          setOpenModalDetail={setOpenModalDetail}
        />
        {/* Modal Create Izin*/}
        <ModalUpdateIzin
          kode={detailKode}
          getDataIzin={getData}
          openModalUpdate={openModalUpdate}
          setOpenModalUpdate={setOpenModalUpdate}
          setDetailKode={setDetailKode}
        />

        {/* Modal Detail Izin*/}
        <ModalDetailIzin
          kode={detailKode}
          getDataIzin={getData}
          openModalDetail={openModalDetail}
          setOpenModalDetail={setOpenModalDetail}
          role={role}
        />

        {/* Modal Image */}
        <Modal
          centered
          size="md"
          opened={showImage}
          onClose={() => setShowImage(false)}
          className="overflow-auto bg-transparent -mt-10">
          {/* Content image */}
          {imageData && (
            <img
              src={imageData}
              alt=""
              className="w-full object-contain rounded-t-lg flex items-center justify-center"
            />
          )}
        </Modal>
      </div>
    </div>
  );
}
