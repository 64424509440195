import React, { useState, useEffect } from 'react';
import * as api from '../../redux/api';
import { Modal } from '@mantine/core';
import DatePicker from 'react-datepicker';
import id from 'date-fns/locale/id';
import moment from 'moment';
import { ClipLoader } from 'react-spinners';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { notifikasiSchema } from '../../utils/schemaValidation';
import Swal from 'sweetalert2';

export default function ModalSendNotifikasiByPersonil({
  personil,
  openModalSendNotifByPersonil,
  setOpenModalSendNotifByPersonil
}) {
  const pengirim = JSON.parse(localStorage.getItem('profile'));
  const pengirimNama = pengirim?.user?.adminpetugasnamalengkap;
  const pengirimJabatan = pengirim?.user?.adminpetugasjabatan;

  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [tanggalNotif, setTanggalNotif] = useState(new Date());

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm({
    resolver: yupResolver(notifikasiSchema)
  });

  useEffect(() => {
    setTanggalNotif(new Date());
    reset({
      satpamnotifikasipengirimnama: pengirimNama,
      satpamnotifikasipengirimjabatan: pengirimJabatan
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pengirimJabatan, pengirimNama, personil]);

  const onSubmit = async (data) => {
    setLoadingSubmit(true);
    data.satpamkodeselected = [
      {
        label: personil.satpamnamalengkap,
        value: {
          satpamkode: personil.satpamkode,
          satpamtoken: personil.satpamtoken,
          satpamnohp: personil.satpamnohp
        }
      }
    ];
    data.satpamnotifikasitanggal = moment(tanggalNotif).format('YYYY-MM-DDTHH:mm');

    await api
      .createNotifikasi(data)
      .then((res) => {
        reset();
        setTanggalNotif(new Date());
        Swal.fire('Good job!', 'You successfully submit the form!', 'success');
        setLoadingSubmit(false);
      })
      .catch((error) => {
        setLoadingSubmit(false);
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: error.response.data.message
        });
      });
  };

  return (
    <Modal
      opened={openModalSendNotifByPersonil}
      size="lg"
      centered
      closeOnClickOutside
      onClose={() => setOpenModalSendNotifByPersonil(false)}>
      <div className="overflow-auto scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100">
        <div className="text-center text-lg font-bold mb-1">Kirim Notifikasi</div>
        <div className="text-center text-md font-semibold mb-2">
          Personil : {personil?.satpamnamalengkap} - {personil?.satpamjabatan}
        </div>
        <form onSubmit={handleSubmit(onSubmit)} className="w-full flex flex-col p-5">
          <div className="md:flex mb-2 items-center">
            <div className="md:w-1/3">
              <label htmlFor="satpamnotifikasitanggal" className="block font-semibold mb-1 md:mb-0">
                Tanggal
              </label>
            </div>
            <div className="md:w-2/3">
              <DatePicker
                selected={tanggalNotif}
                className={`w-full text-sm cursor-pointer inputForm`}
                onChange={(date) => setTanggalNotif(date)}
                locale={id}
                showTimeSelect
                timeIntervals={15}
                dateFormat="dd/MM/yyyy HH:mm"
                timeFormat="HH:mm"
                placeholderText="dd/MM/yyyy HH:mm"
                required
              />
            </div>
          </div>
          <div className="md:flex mb-2 items-center">
            <div className="md:w-1/3">
              <label htmlFor="satpamnotifikasitanggal" className="block font-semibold mb-1 md:mb-0">
                Jenis Notifikasi
              </label>
            </div>
            <div className="md:w-2/3">
              <select className="w-full inputForm" {...register('satpamnotifikasijenis')}>
                <option value="">Pilih Jenis</option>
                <option value="Umum">Umum</option>
                <option value="Keadaan Darurat">Keadaan Darurat</option>
              </select>
            </div>
            <p className="text-errorText text-xs">{errors.satpamnotifikasijenis?.message}</p>
          </div>
          <div className="md:flex mb-2 items-center">
            <div className="md:w-1/3">
              <label htmlFor="pengirim" className="block font-semibold mb-1 md:mb-0">
                Nama Pengirim
              </label>
            </div>
            <div className="md:w-2/3">
              <input
                type="text"
                className="w-full inputForm"
                {...register('satpamnotifikasipengirimnama')}
              />
            </div>
            <p className="text-errorText text-xs">{errors.satpamnotifikasipengirimnama?.message}</p>
          </div>
          <div className="md:flex mb-2 items-center">
            <div className="md:w-1/3">
              <label htmlFor="pengirim" className="block font-semibold mb-1 md:mb-0">
                Jabatan Pengirim
              </label>
            </div>
            <div className="md:w-2/3">
              <input
                type="text"
                className="w-full inputForm"
                {...register('satpamnotifikasipengirimjabatan')}
              />
              <p className="text-errorText text-xs">
                {errors.satpamnotifikasipengirimjabatan?.message}
              </p>
            </div>
          </div>
          <div className="md:flex mb-2 items-center">
            <div className="md:w-1/3">
              <label htmlFor="pengirim" className="block font-semibold mb-1 md:mb-0">
                Header
              </label>
            </div>
            <div className="md:w-2/3">
              <input
                type="text"
                className="w-full inputForm"
                {...register('satpamnotifikasiheader')}
              />
              {errors.satpamnotifikasiheader?.message}
            </div>
          </div>
          <div className="md:flex mb-2 items-center">
            <div className="md:w-1/3">
              <label htmlFor="pengirim" className="block font-semibold mb-1 md:mb-0">
                Judul
              </label>
            </div>
            <div className="md:w-2/3">
              <input
                type="text"
                className="w-full inputForm"
                {...register('satpamnotifikasijudul')}
              />
              <p className="text-errorText text-xs">{errors.satpamnotifikasijudul?.message}</p>
            </div>
          </div>
          <div className="md:flex mb-5">
            <div className="md:w-1/3">
              <label htmlFor="tanggal" className="block font-semibold mb-1 md:mb-0">
                Isi
              </label>
            </div>
            <div className="md:w-2/3">
              <textarea
                rows={3}
                className="w-full inputForm"
                {...register('satpamnotifikasiisi')}
              />
              <p className="text-errorText text-xs">{errors.satpamnotifikasiisi?.message}</p>
            </div>
          </div>

          <div className="flex justify-center gap-5 items-center w-full">
            <button
              type="submit"
              className="text-gray-50 py-2 px-5 rounded-md text-sm bg-dark hover:opacity-80"
              disabled={loadingSubmit === true ? true : false}>
              {loadingSubmit ? (
                <ClipLoader color="white" loading={true} size={20} speedMultiplier={0.7} />
              ) : (
                'Simpan'
              )}
            </button>

            <button
              type="button"
              className="btn text-gray-50 py-2 px-5 rounded-md text-sm bg-dark hover:opacity-80"
              onClick={() => setOpenModalSendNotifByPersonil(false)}>
              Kembali
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
}
