import * as yup from 'yup';

export const satpamSchema = yup
  .object({
    satpamnip: yup.string().required('NIP harus diisi'),
    satpamnamalengkap: yup.string().required('Nama harus diisi'),
    satpamnohp: yup.string().required('No HP harus diisi'),
    satpamemail: yup.string().required('Email harus diisi'),
    satpamjabatan: yup.string().nullable(),
    satpamtipe: yup.string().nullable(),
    satpamjeniskelamin: yup.string().nullable(),
    satpampendidikanjenjang: yup.string().nullable(),
    satpambanknama: yup.string().nullable(),
    satpambanknomorrekening: yup.string().nullable()
  })
  .shape({
    satpamfoto: yup.mixed().nullable(),
    satpambankfoto: yup.mixed().nullable()
  });

export const satpamUpdateSchema = yup
  .object({
    satpamnip: yup.string().required('NIP harus diisi'),
    satpamstatus: yup.string().required('Status harus diisi'),
    satpamnamalengkap: yup.string().required('Nama harus diisi'),
    satpamnohp: yup.string().required('No HP harus diisi'),
    satpamemail: yup.string().required('Email harus diisi'),
    satpamjabatan: yup.string().nullable(),
    satpamtipe: yup.string().nullable(),
    satpamjeniskelamin: yup.string().nullable(),
    satpampendidikanjenjang: yup.string().nullable(),
    satpambanknama: yup.string().nullable(),
    satpambanknomorrekening: yup.string().nullable()
  })
  .shape({
    satpamfoto: yup.mixed().nullable(),
    satpambankfoto: yup.mixed().nullable()
  });

export const kegiatanHarianSchema = yup
  .object({
    satpamkegiatansatpamkegiatankategorikode: yup.string().required('Kategori harus diisi'),
    uraian: yup.string().required('Uraian harus diisi'),
    keterangan: yup.string().required('Keterangan harus diisi'),
    alamat: yup.string().required('Alamat harus diisi'),
    status: yup.string().required('Status harus diisi'),
    latitude: yup.string().nullable(),
    longitude: yup.string().nullable(),
    patokan: yup.string().nullable(),
    keterangan_foto1: yup.string().nullable(),
    keterangan_foto2: yup.string().nullable(),
    keterangan_foto3: yup.string().nullable(),
    keterangan_foto4: yup.string().nullable(),
    keterangan_foto5: yup.string().nullable()
  })
  .shape({
    // foto_kegiatan1: yup.mixed().test('required', 'Silahkan pilih gambar', (value) => {
    //   return value && value.length;
    // }),
    foto_kegiatan1: yup.mixed(),
    foto_kegiatan2: yup.mixed(),
    foto_kegiatan3: yup.mixed(),
    foto_kegiatan4: yup.mixed(),
    foto_kegiatan5: yup.mixed()
  });

export const kegiatanHarianUpdateSchema = yup
  .object({
    satpamkegiatansatpamkegiatankategorikode: yup.string().required('Kategori harus diisi'),
    satpamprosesketerangan: yup.string().required('Perubahan harus diisi'),
    uraian: yup.string().required('Uraian harus diisi'),
    keterangan: yup.string().required('Keterangan harus diisi'),
    alamat: yup.string().required('Alamat harus diisi'),
    status: yup.string().required('Status harus diisi'),
    latitude: yup.string().nullable(),
    longitude: yup.string().nullable(),
    patokan: yup.string().nullable(),
    keterangan_foto1: yup.string().nullable(),
    keterangan_foto2: yup.string().nullable(),
    keterangan_foto3: yup.string().nullable(),
    keterangan_foto4: yup.string().nullable(),
    keterangan_foto5: yup.string().nullable()
  })
  .shape({
    // foto_kegiatan1: yup.mixed().test('required', 'Silahkan pilih gambar', (value) => {
    //   return value && value.length;
    // }),
    foto_kegiatan1: yup.mixed(),
    foto_kegiatan2: yup.mixed(),
    foto_kegiatan3: yup.mixed(),
    foto_kegiatan4: yup.mixed(),
    foto_kegiatan5: yup.mixed()
  });

export const kejadianSchema = yup
  .object({
    satpamkejadianuraian: yup.string().required('Uraian harus diisi'),
    satpamkejadiantindakan: yup.string().required('Tindakan harus diisi'),
    satpamkejadianhasil: yup.string().required('Hasil harus diisi'),
    satpamkejadianpelaku: yup.string().required('Pelaku harus diisi'),
    satpamkejadiankorban: yup.string().required('Korban harus diisi'),
    satpamkejadiansaksi: yup.string().required('Saksi harus diisi'),
    satpamkejadianstatus: yup.string().required('Status harus diisi'),
    satpamkejadianlatitude: yup.string().nullable(),
    satpamkejadianlongitude: yup.string().nullable(),
    satpamkejadianalamat: yup.string().required('Alamat harus diisi'),
    satpamkejadianalamatpatokan: yup.string().nullable(),
    satpamkejadianketeranganfotosatu: yup.string().nullable(),
    satpamkejadianketeranganfotodua: yup.string().nullable(),
    satpamkejadianketeranganfototiga: yup.string().nullable(),
    satpamkejadianketeranganfotoempat: yup.string().nullable(),
    satpamkejadianketeranganfotolima: yup.string().nullable()
  })
  .shape({
    // satpamkejadianfotosatu: yup.mixed().test('required', 'Silahkan pilih gambar', (value) => {
    //   return value && value.length;
    // }),
    satpamkejadianfotosatu: yup.mixed(),
    satpamkejadianfotodua: yup.mixed(),
    satpamkejadianfototiga: yup.mixed(),
    satpamkejadianfotoempat: yup.mixed(),
    satpamkejadianfotolima: yup.mixed()
  });

export const kejadianUpdateSchema = yup
  .object({
    satpamprosesketerangan: yup.string().required('Perubahan harus diisi'),
    satpamkejadianuraian: yup.string().required('Uraian harus diisi'),
    satpamkejadiantindakan: yup.string().required('Tindakan harus diisi'),
    satpamkejadianhasil: yup.string().required('Hasil harus diisi'),
    satpamkejadianpelaku: yup.string().required('Pelaku harus diisi'),
    satpamkejadiankorban: yup.string().required('Korban harus diisi'),
    satpamkejadiansaksi: yup.string().required('Saksi harus diisi'),
    satpamkejadianstatus: yup.string().required('Status harus diisi'),
    satpamkejadianlatitude: yup.string().nullable(),
    satpamkejadianlongitude: yup.string().nullable(),
    satpamkejadianalamat: yup.string().required('Alamat harus diisi'),
    satpamkejadianpotensi: yup.string().nullable(),
    satpamkejadiananalisis: yup.string().nullable(),
    satpamkejadiankesimpulan: yup.string().nullable(),
    satpamkejadianrekomendasi: yup.string().nullable(),
    satpamkejadianalamatpatokan: yup.string().nullable(),
    satpamkejadianketeranganfotosatu: yup.string().nullable(),
    satpamkejadianketeranganfotodua: yup.string().nullable(),
    satpamkejadianketeranganfototiga: yup.string().nullable(),
    satpamkejadianketeranganfotoempat: yup.string().nullable(),
    satpamkejadianketeranganfotolima: yup.string().nullable()
  })
  .shape({
    // satpamkejadianfotosatu: yup.mixed().test('required', 'Silahkan pilih gambar', (value) => {
    //   return value && value.length;
    // }),
    satpamkejadianfotosatu: yup.mixed(),
    satpamkejadianfotodua: yup.mixed(),
    satpamkejadianfototiga: yup.mixed(),
    satpamkejadianfotoempat: yup.mixed(),
    satpamkejadianfotolima: yup.mixed()
  });

export const temuanSchema = yup
  .object({
    satpamtemuanuraian: yup.string().required('Uraian harus diisi'),
    satpamtemuantindakan: yup.string().required('Tindakan harus diisi'),
    satpamtemuanhasil: yup.string().required('Hasil harus diisi'),
    satpamtemuanstatus: yup.string().required('Status harus diisi'),
    satpamtemuanlatitude: yup.string().nullable(),
    satpamtemuanlongitude: yup.string().nullable(),
    satpamtemuanalamat: yup.string().required('Alamat harus diisi'),
    satpamtemuanalamatpatokan: yup.string().nullable(),
    satpamtemuanketeranganfotosatu: yup.string().nullable(),
    satpamtemuanketeranganfotodua: yup.string().nullable(),
    satpamtemuanketeranganfototiga: yup.string().nullable(),
    satpamtemuanketeranganfotoempat: yup.string().nullable(),
    satpamtemuanketeranganfotolima: yup.string().nullable()
  })
  .shape({
    // satpamtemuanfotosatu: yup.mixed().test('required', 'Silahkan pilih gambar', (value) => {
    //   return value && value.length;
    // }),
    satpamtemuanfotosatu: yup.mixed(),
    satpamtemuanfotodua: yup.mixed(),
    satpamtemuanfototiga: yup.mixed(),
    satpamtemuanfotoempat: yup.mixed(),
    satpamtemuanfotolima: yup.mixed()
  });

export const temuanUpdateSchema = yup
  .object({
    satpamprosesketerangan: yup.string().required('Perubahan harus diisi'),
    satpamtemuanuraian: yup.string().required('Uraian harus diisi'),
    satpamtemuantindakan: yup.string().required('Tindakan harus diisi'),
    satpamtemuanpotensi: yup.string().nullable(),
    satpamtemuananalisis: yup.string().nullable(),
    satpamtemuankesimpulan: yup.string().nullable(),
    satpamtemuanrekomendasi: yup.string().nullable(),
    satpamtemuanstatus: yup.string().required('Status harus diisi'),
    satpamtemuanlatitude: yup.string().nullable(),
    satpamtemuanlongitude: yup.string().nullable(),
    satpamtemuanalamat: yup.string().required('Alamat harus diisi'),
    satpamtemuanalamatpatokan: yup.string().nullable(),
    satpamtemuanketeranganfotosatu: yup.string().nullable(),
    satpamtemuanketeranganfotodua: yup.string().nullable(),
    satpamtemuanketeranganfototiga: yup.string().nullable(),
    satpamtemuanketeranganfotoempat: yup.string().nullable(),
    satpamtemuanketeranganfotolima: yup.string().nullable()
  })
  .shape({
    // satpamtemuanfotosatu: yup.mixed().test('required', 'Silahkan pilih gambar', (value) => {
    //   return value && value.length;
    // }),
    satpamtemuanfotosatu: yup.mixed(),
    satpamtemuanfotodua: yup.mixed(),
    satpamtemuanfototiga: yup.mixed(),
    satpamtemuanfotoempat: yup.mixed(),
    satpamtemuanfotolima: yup.mixed()
  });

export const posSchema = yup
  .object({
    satpamposnama: yup.string().required('Nama pos harus diisi'),
    satpamposketerangan: yup.string().required('Keterangan harus diisi'),
    satpamposradius: yup.string().required('Radius harus diisi'),
    satpamposlatitude: yup.string().required('Latitude harus diisi'),
    satpamposlongitude: yup.string().required('Longitude harus diisi')
  })
  .shape({
    satpamposfoto: yup.mixed().test('required', 'Silahkan pilih gambar', (value) => {
      return value && value.length;
    })
  });

export const posUpdateSchema = yup
  .object({
    satpamposnama: yup.string().required('Nama pos harus diisi'),
    satpamposketerangan: yup.string().required('Keterangan harus diisi'),
    satpamposradius: yup.string().required('Radius harus diisi'),
    satpamposlatitude: yup.string().required('Latitude harus diisi'),
    satpamposlongitude: yup.string().required('Longitude harus diisi'),
    satpamposstatus: yup.string().required('Status harus diisi')
  })
  .shape({
    satpamposfoto: yup.mixed().test('required', 'Silahkan pilih gambar', (value) => {
      return value && value.length;
    })
  });

export const checkpointSchema = yup
  .object({
    satpamtitiksatpamposkode: yup.string().required('POS harus diisi'),
    satpamtitiknama: yup.string().required('Nama pos harus diisi'),
    satpamtitikketerangan: yup.string().required('Keterangan harus diisi'),
    satpamtitikradius: yup.string().required('Radius harus diisi'),
    satpamtitiklatitude: yup.string().required('Latitude harus diisi'),
    satpamtitiklongitude: yup.string().required('Longitude harus diisi')
  })
  .shape({
    satpamtitikfoto: yup.mixed().test('required', 'Silahkan pilih gambar', (value) => {
      return value && value.length;
    })
  });

export const checkpointUpdateSchema = yup
  .object({
    satpamtitiksatpamposkode: yup.string().required('POS harus diisi'),
    satpamtitiknama: yup.string().required('Nama pos harus diisi'),
    satpamtitikketerangan: yup.string().required('Keterangan harus diisi'),
    satpamtitikradius: yup.string().required('Radius harus diisi'),
    satpamtitiklatitude: yup.string().required('Latitude harus diisi'),
    satpamtitiklongitude: yup.string().required('Longitude harus diisi'),
    satpamtitikstatus: yup.string().required('Status harus diisi')
  })
  .shape({
    satpamtitikfoto: yup.mixed().test('required', 'Silahkan pilih gambar', (value) => {
      return value && value.length;
    })
  });

export const jadwalSatpamSchema = yup.object({
  satpamjadwalstatus: yup.string().required('Status harus diisi'),
  satpamjadwalketerangan: yup.string().required('Keterangan harus diisi')
  // satpamjadwalsatpamkode: yup.string().required('POS harus diisi')
});

export const izinSatpamSchema = yup
  .object({
    satpamizinketerangan: yup.string().required('Keterangan harus diisi'),
    satpamizinjenis: yup.string().required('Jenis harus diisi')
    // satpamjadwalsatpamkode: yup.string().required('POS harus diisi')
  })
  .shape({
    satpamizinfoto: yup.mixed().test('required', 'Silahkan pilih gambar', (value) => {
      return value && value.length;
    })
  });

export const izinSatpamUpdateSchema = yup
  .object({
    satpamizinstatus: yup.string().required('Status harus diisi'),
    satpamizinketerangan: yup.string().required('Keterangan harus diisi'),
    satpamizinjenis: yup.string().required('Jenis harus diisi')
    // satpamjadwalsatpamkode: yup.string().required('POS harus diisi')
  })
  .shape({
    satpamizinfoto: yup.mixed().test('required', 'Silahkan pilih gambar', (value) => {
      return value && value.length;
    })
  });

export const perusahaanSchema = yup
  .object({
    satpamperusahaannama: yup.string().required('Nama harus diisi'),
    satpamperusahaanalamat: yup.string().required('Alamat harus diisi'),
    satpamperusahaanradius: yup.string().nullable(),
    satpamperusahaanlatitude: yup.string().nullable(),
    satpamperusahaanlongitude: yup.string().nullable()
  })
  .shape({
    satpamperusahaanfoto: yup.mixed().test('required', 'Silahkan pilih gambar', (value) => {
      return value && value.length;
    })
  });

export const perusahaanUpdateSchema = yup
  .object({
    satpamperusahaannama: yup.string().required('Nama harus diisi'),
    satpamperusahaanalamat: yup.string().required('Alamat harus diisi'),
    satpamperusahaanstatus: yup.string().required('Status harus diisi'),
    satpamperusahaanradius: yup.string().nullable(),
    satpamperusahaanlatitude: yup.string().nullable(),
    satpamperusahaanlongitude: yup.string().nullable()
  })
  .shape({
    satpamperusahaanfoto: yup.mixed().test('required', 'Silahkan pilih gambar', (value) => {
      return value && value.length;
    })
  });

export const petugasSchema = yup
  .object({
    adminpetugasnamalengkap: yup.string().required('Nama harus diisi'),
    adminpetugastipe: yup.string().required('Tipe harus diisi'),
    adminpetugasusername: yup.string().required('Email harus diisi'),
    jabatan: yup.string().required('Jabatan harus diisi'),
    password: yup.string().required('Password harus diisi')
  })
  .shape({
    // foto: yup.mixed().test('required', 'Silahkan pilih gambar', (value) => {
    //   return value && value.length;
    // })
  });

export const petugasUpdateSchema = yup
  .object({
    adminpetugasnamalengkap: yup.string().required('Nama harus diisi'),
    adminpetugastipe: yup.string().required('Tipe harus diisi'),
    adminpetugasusername: yup.string().required('Email harus diisi'),
    adminpetugasstatus: yup.string().required('Status harus diisi'),
    jabatan: yup.string().required('Jabatan harus diisi')
  })
  .shape({
    // foto: yup.mixed().test('required', 'Silahkan pilih gambar', (value) => {
    //   return value && value.length;
    // })
  });

export const informasiSchema = yup
  .object({
    satpaminformasigrup: yup.string().required('Grup harus diisi'),
    satpaminformasiheader: yup.string().required('Header harus diisi'),
    satpaminformasiheaderdetail: yup.string().required('Detail header harus diisi'),
    satpaminformasijudul: yup.string().nullable(),
    satpaminformasilink: yup.string().nullable()
  })
  .shape({
    satpaminformasigambar: yup.mixed().test('required', 'Silahkan pilih gambar', (value) => {
      return value && value.length;
    })
  });

export const informasiUpdateSchema = yup
  .object({
    satpaminformasigrup: yup.string().required('Grup harus diisi'),
    satpaminformasiheader: yup.string().required('Header harus diisi'),
    satpaminformasiheaderdetail: yup.string().required('Detail header harus diisi'),
    satpaminformasijudul: yup.string().nullable(),
    satpaminformasilink: yup.string().nullable(),
    satpaminformasistatus: yup.string().required('Status harus diisi')
  })
  .shape({
    satpaminformasigambar: yup.mixed().test('required', 'Silahkan pilih gambar', (value) => {
      return value && value.length;
    })
  });

export const notifikasiSchema = yup.object({
  satpamnotifikasipengirimnama: yup.string().required('Nama pengirim harus diisi'),
  satpamnotifikasipengirimjabatan: yup.string().required('Jataban pengirim harus diisi'),
  satpamnotifikasijenis: yup.string().required('Jenis notifikasi harus dipilih'),
  satpamnotifikasijudul: yup.string().nullable(),
  satpamnotifikasiisi: yup.string().nullable()
});

export const inboxSchema = yup
  .object({
    satpaminboxberlaku: yup.number().required('Berlaku harus diisi'),
    satpaminboxjudul: yup.string().required('Judul harus diisi'),
    satpaminboxjuduldetail: yup.string().required('Detail judul harus diisi'),
    satpaminboxaksi: yup.string().nullable(),
    satpaminboxaksitext: yup.string().nullable()
  })
  .shape({
    satpaminboxgambar: yup.mixed().test('required', 'Silahkan pilih gambar', (value) => {
      return value && value.length;
    })
  });

export const inboxUpdateSchema = yup
  .object({
    satpaminboxstatus: yup.number().required('Status harus diisi'),
    satpaminboxberlaku: yup.number().required('Berlaku harus diisi'),
    satpaminboxjudul: yup.string().required('Judul harus diisi'),
    satpaminboxjuduldetail: yup.string().required('Detail judul harus diisi'),
    satpaminboxaksi: yup.string().nullable(),
    satpaminboxaksitext: yup.string().nullable()
  })
  .shape({
    satpaminboxgambar: yup.mixed().test('required', 'Silahkan pilih gambar', (value) => {
      return value && value.length;
    })
  });

export const keadaanSchema = yup.object({
  satpamkeadaanpesan: yup.string().required('Pesan harus diisi'),
  satpamkeadaanalamat: yup.string().required('Alamat harus diisi'),
  satpamkeadaanlatitude: yup.string().nullable(),
  satpamkeadaanlongitude: yup.string().nullable()
});

export const keluhanSchema = yup.object({
  satpamkeluhanadminnama: yup.string().required('Nama admin harus diisi'),
  satpamkeluhansatpamkeluhankategorikode: yup.string().required('Kategori harus diisi'),
  satpamkeluhanisi: yup.string().required('Isi harus diisi'),
  satpamkeluhanstatus: yup.string().required('Status harus diisi')
});
