// Import packages
import React, { useState, useEffect } from 'react';
import * as api from '../../redux/api';
import { Modal, Tooltip } from '@mantine/core';
import { Popover } from '@mantine/core';
import Table from '../../components/general/Table';
import { AiOutlineFileAdd } from 'react-icons/ai';
import moment from 'moment/moment';
import ModalListNotifikasiByPersonil from '../../components/notifikasi/ModalListNotifikasiByPersonil';
import ModalSendNotifikasiByPersonil from '../../components/notifikasi/ModalSendNotifikasiByPersonil';
import ModalSendNotifikasi from '../../components/notifikasi/ModalSendNotifikasi';
import { useDebouncedValue } from '@mantine/hooks';

export default function NotifikasiData({ role }) {
  const [showImage, setShowImage] = useState(false);
  const [imageData, setImageData] = useState(null);
  const [data, setData] = useState([]);
  const [openModalDetail, setOpenModalDetail] = useState(false);
  const [openModalSendNotif, setOpenModalSendNotif] = useState(false);
  const [openModalSendNotifByPersonil, setOpenModalSendNotifByPersonil] = useState(false);
  const [personil, setPersonil] = useState({});

  const [filterData, setFilterData] = useState([]);

  const [query, setQuery] = useState('');
  const [debouncedQuery] = useDebouncedValue(query, 200);

  useEffect(() => {
    const getData = async () => {
      await api.satpamData().then((res) => {
        setData(res.data.data);
      });
    };
    getData();
  }, []);

  useEffect(() => {
    if (data?.length > 0) {
      setFilterData(
        data.filter(({ satpamnamalengkap, satpamjabatan, satpamnohp, satpamterakhiraktif }) => {
          if (
            debouncedQuery !== '' &&
            !`${satpamnamalengkap} ${satpamjabatan} ${satpamnohp} ${satpamterakhiraktif}`
              .toLowerCase()
              .includes(debouncedQuery.trim().toLowerCase())
          ) {
            return false;
          }
          return true;
        })
      );
    }
  }, [data, debouncedQuery]);

  const handleImageData = ({ satpamfoto }) => {
    setImageData(
      satpamfoto !== '' && satpamfoto !== null
        ? `${process.env.REACT_APP_API_URL}/assets/upload/data-foto/${satpamfoto}`
        : require('../../images/satpam.jpg')
    );
    setShowImage(!showImage);
  };

  const handleOpenModalDetail = (data) => {
    setPersonil(data);
    setOpenModalDetail(true);
  };

  const handleOpenModalSendNotifByPersonil = (data) => {
    setPersonil(data);
    setOpenModalSendNotifByPersonil(true);
  };

  // Column Data Table
  const columnData = [
    {
      title: 'Action',
      accessor: 'satpamkode',
      width: 100,
      render: ({ satpamkode }) => (
        <div className="flex items-center gap-1">
          {/* Button Aksi */}
          <Popover width={110} position="right" withArrow shadow="xl">
            <Popover.Target>
              <div className="btnAksi my-1">Action</div>
            </Popover.Target>
            <Popover.Dropdown className="popOverDropdown">
              <div
                size="sm"
                className="popOverItem"
                onClick={() =>
                  handleOpenModalDetail(data?.filter((data) => data.satpamkode === satpamkode)[0])
                }>
                <i className="fa-solid fa-circle-info"></i> Detail
              </div>
              <div
                size="sm"
                className="popOverItem"
                onClick={() =>
                  handleOpenModalSendNotifByPersonil(
                    data?.filter((data) => data.satpamkode === satpamkode)[0]
                  )
                }>
                <i className="fa-solid fa-bell"></i> Kirim
              </div>
            </Popover.Dropdown>
          </Popover>
        </div>
      )
    },
    {
      title: 'Foto Personil',
      accessor: 'satpamfoto',
      width: 150,
      render: ({ satpamfoto }) => (
        <div
          className="h-28 mt-1 mb-2 flex justify-center cursor-pointer hover:opacity-80"
          onClick={() => handleImageData({ satpamfoto })}>
          {satpamfoto !== null && satpamfoto !== '' ? (
            <img
              src={`${process.env.REACT_APP_API_URL}/assets/upload/data-foto/${satpamfoto}`}
              alt=""
              className="h-full object-contain rounded"
            />
          ) : (
            <img
              src={require('../../images/satpam.jpg')}
              alt=""
              className="h-full object-contain rounded"
            />
          )}
        </div>
      )
    },
    {
      title: 'Nama Personil',
      accessor: 'satpamnamalengkap',
      width: 250
    },
    {
      title: 'Jabatan',
      accessor: 'satpamjabatan',
      wrapText: true
    },
    {
      title: 'No. HP',
      accessor: 'satpamnohp',
      wrapText: true
    },
    {
      title: 'Terakhir Aktif',
      accessor: 'satpamterakhiraktif',
      width: 200,
      render: ({ satpamterakhiraktif }) => (
        <>
          {satpamterakhiraktif !== '' && satpamterakhiraktif !== null
            ? moment(satpamterakhiraktif).format('DD MMM YYYY HH:mm')
            : '-'}
        </>
      )
    }
  ];

  return (
    <div className="relative px-4 md:px-10 mx-auto w-full -mt-8 overflow-x-hidden scrollbar-thin scrollbar-track-gray-100 scrollbar-thumb-gray-300">
      <div className="w-full min-h-screen">
        <div className="w-full justify-center bg-gray-50 rounded-lg shadowAll pb-8">
          <div className="flex flex-wrap lg:flex-nowrap justify-beetween items-center">
            <div className="flex flex-col md:flex-row flex-wrap justify-start items-center w-full"></div>
            {role === 'Admin Project' && (
              <div className="my-3 px-5 flex justify-end">
                <div className="flex gap-4 w-full">
                  <Tooltip label="Tambah Notifikasi">
                    <div
                      className="bg-customGray p-2 rounded-md cursor-pointer hover:opacity-80"
                      onClick={() => setOpenModalSendNotif(true)}>
                      <AiOutlineFileAdd size={24} />
                    </div>
                  </Tooltip>
                </div>
              </div>
            )}
          </div>
          {/* Table Component */}
          {data?.length > 0 ? (
            <div className="p-5">
              <Table data={filterData} columnData={columnData} setQuery={setQuery} />
            </div>
          ) : (
            <div className="text-center">
              <div className="mt-10 mb-72">Tidak ada data personil.</div>
            </div>
          )}
        </div>
        {/* Modal Detail Send Notifikasi */}
        <ModalSendNotifikasi
          dataSatpam={data}
          openModalSendNotif={openModalSendNotif}
          setOpenModalSendNotif={setOpenModalSendNotif}
        />

        {/* Modal Detail List Notifikasi */}
        <ModalListNotifikasiByPersonil
          personil={personil}
          openModalDetail={openModalDetail}
          setOpenModalDetail={setOpenModalDetail}
        />

        {/* Modal Detail Send Notifikasi By Personil */}
        <ModalSendNotifikasiByPersonil
          personil={personil}
          openModalSendNotifByPersonil={openModalSendNotifByPersonil}
          setOpenModalSendNotifByPersonil={setOpenModalSendNotifByPersonil}
        />

        {/* Modal Image */}
        <Modal
          centered
          size="md"
          opened={showImage}
          onClose={() => setShowImage(false)}
          className="overflow-auto bg-transparent -mt-10">
          {/* Content image */}
          {imageData && (
            <img
              src={imageData}
              alt=""
              className="h-56 w-full object-contain rounded-t-lg flex items-center justify-center"
            />
          )}
        </Modal>
      </div>
    </div>
  );
}
