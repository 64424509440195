import React, { useState, useEffect } from 'react';
import * as api from '../../redux/api';
import Swal from 'sweetalert2';
import { Link } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import useImagePreview from '../../hooks/useImagePreview';
import { inboxSchema } from '../../utils/schemaValidation';
import imageCompression from 'browser-image-compression';
import id from 'date-fns/locale/id';
import DatePicker from 'react-datepicker';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import moment from 'moment/moment';
import { formats, modules } from '../../utils/reactQuillOptions';
import Select from 'react-select';

export default function InboxCreate() {
  const [loading, setLoading] = useState(false);
  const [tanggal, setTanggal] = useState(new Date());
  const [tipe, setTipe] = useState('');
  const [deskripsi, setDeskripsi] = useState('');
  const [jenis, setJenis] = useState('Semua');
  const [satpamOptions, setSatpamOptions] = useState([]);
  const [satpamSelected, setSatpamSelected] = useState([]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch
  } = useForm({
    resolver: yupResolver(inboxSchema)
  });

  const [fileDataURL] = useImagePreview(watch('satpaminboxgambar'));

  const compressOptions = {
    maxSizeMB: 1,
    maxWidthOrHeight: 1920,
    useWebWorker: true
  };

  useEffect(() => {
    const getDataSatpam = async () => {
      await api.satpamData().then((res) => {
        res.data.data.map((personil) => {
          return setSatpamOptions((prev) => [
            ...prev,
            {
              label: personil.satpamnamalengkap,
              value: { satpamkode: personil.satpamkode, satpamtoken: personil.satpamtoken }
            }
          ]);
        });
      });
    };

    getDataSatpam();
  }, []);

  useEffect(() => {
    if (jenis === 'Semua') {
      setSatpamSelected(satpamOptions);
    } else {
      setSatpamSelected([]);
    }
  }, [jenis, satpamOptions]);

  const onSubmit = async (data) => {
    setLoading(true);

    // data.satpaminboxsatpamkode = satpamSelected;
    data.satpaminboxtanggal = moment(tanggal).format('YYYY-MM-DDTHH:mm');
    data.satpaminboxtipe = tipe;

    // Change tag <p></p> to tag <br>
    let newDeskripsi = deskripsi;
    const removeTagP = newDeskripsi.replace(/<p>/g, '');
    const changeTagPtoBr = removeTagP.replace(/<\/p>/g, '<br>');

    data.satpaminboxdeskripsi = changeTagPtoBr;

    const formData = new FormData();

    formData.append('satpamkodeselected', JSON.stringify(satpamSelected));

    if (data.satpaminboxgambar.length > 0) {
      const compressedFile = await imageCompression(data.satpaminboxgambar[0], compressOptions);
      formData.append('satpaminboxgambar', compressedFile, compressedFile.name);
    }

    for (const key in data) {
      if (key === 'field') {
        formData.append(key, data[key][1]);
      } else {
        formData.append(key, data[key]);
      }
    }

    if (deskripsi !== '') {
      await api
        .createInbox(formData)
        .then((res) => {
          Swal.fire('Good job!', 'You successfully submit the form!', 'success');
          setLoading(false);
          setDeskripsi('');
          setTanggal('');
          setJenis('Semua');
          setSatpamSelected([]);
          reset();
          reset({ satpaminboxgambar: '' });
        })
        .catch((error) => {
          setLoading(false);
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: error.response.data.message
          });
        });
    } else {
      setLoading(false);
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'deskripsi tidak boleh kosong'
      });
    }
  };

  return (
    <div className="relative px-4 md:px-10 mx-auto w-full -mt-8 scrollbar-thin scrollbar-track-gray-100 scrollbar-thumb-gray-300">
      <div className="w-full min-h-screen">
        <div className="py-3 bg-gray-50 rounded-lg shadow-black/40 shadow-lg flex justify-center items-center mb-10 text-center text-lg font-semibold">
          Tambah Inbox
        </div>
        <form
          onSubmit={handleSubmit(onSubmit)}
          encType="multipart/form-data"
          className="w-full flex flex-col gap-5">
          <div className="w-full flex flex-col gap-5">
            <div className=" bg-gray-50 p-5 rounded-lg shadowAll flex flex-col justify-around">
              <div className="md:flex mb-3 items-center">
                <div className="md:w-1/4">
                  <label
                    htmlFor="satpamnotifikasitanggal"
                    className="block font-semibold mb-1 md:mb-0">
                    Kirim Ke
                  </label>
                </div>
                <div className="md:w-3/4">
                  <select
                    className="w-full inputForm"
                    value={jenis}
                    onChange={(e) => setJenis(e.target.value)}>
                    <option value="">Pilih Jenis</option>
                    <option value="Semua">Semua Personil</option>
                    <option value="Beberapa">Beberapa Personil</option>
                  </select>
                </div>
              </div>
              {jenis === 'Beberapa' && (
                <div className="md:flex mb-3 items-center">
                  <div className="md:w-1/4">
                    <label
                      htmlFor="satpamnotifikasitanggal"
                      className="block font-semibold mb-1 md:mb-0">
                      Pilih Personil
                    </label>
                  </div>
                  <div className="md:w-3/4">
                    <Select
                      options={satpamOptions}
                      defaultValue={satpamOptions.filter(
                        (option) =>
                          option.value === satpamSelected.map((selected) => selected.value)
                      )}
                      className="w-full"
                      placeholder="Pilih personil"
                      isSearchable
                      isMulti
                      onChange={(e) => setSatpamSelected(e)}
                    />
                  </div>
                </div>
              )}
              <div className="md:flex items-center mb-3">
                <div className="md:w-1/4">
                  <label className="block font-semibold mb-1 md:mb-0">Tanggal Inbox</label>
                </div>
                <div className="md:w-3/4">
                  <div className="relative block">
                    <DatePicker
                      selected={tanggal}
                      className={`w-full text-sm cursor-pointer inputForm`}
                      onChange={(date) => setTanggal(date)}
                      locale={id}
                      showTimeInput
                      dateFormat="dd/MM/yyyy HH:mm"
                      timeFormat="HH:mm"
                      placeholderText="dd/MM/yyyy HH:mm"
                      required
                    />
                    <div className="absolute top-1/2 transform -translate-y-1/2 right-3 pointer-events-none">
                      <i className="far fa-calendar"></i>
                    </div>
                  </div>
                </div>
              </div>
              <div className="md:flex items-center mb-3">
                <div className="md:w-1/4">
                  <label className="block font-semibold mb-1 md:mb-0">Tipe</label>
                </div>
                <div className="md:w-3/4">
                  <select
                    className={`w-full inputForm`}
                    value={tipe}
                    onChange={(e) => setTipe(e.target.value)}>
                    <option value="">Pilih tipe</option>
                    <option value="Gambar">Gambar</option>
                    <option value="Video">Video</option>
                  </select>
                </div>
              </div>
              <div className="md:flex items-center">
                <div className="md:w-1/4">
                  <label className="block font-semibold mb-1 md:mb-0">Berlaku sampai</label>
                </div>
                <div className="md:w-3/4">
                  <input
                    type="number"
                    className={`w-full ${errors.satpaminboxberlaku ? 'invalidForm' : 'inputForm'}`}
                    {...register('satpaminboxberlaku')}
                  />
                  <p className="text-errorText text-xs">{errors.satpaminboxberlaku?.message}</p>
                </div>
              </div>
            </div>
            <div className="bg-gray-50 p-5 rounded-lg shadowAll">
              <div className="md:flex mb-3">
                <div className="md:w-1/4">
                  <label htmlFor="satpamfoto" className="block font-semibold mb-1 md:mb-0">
                    Gambar / Thumbnail
                  </label>
                </div>
                <div className="md:w-3/4">
                  <div className="flex w-full">
                    <div className="w-full">
                      <div className="h-auto p-3 bg-white rounded-md border border-gray-200 shadow-md">
                        <div className="flex flex-col w-full">
                          <p className="text-dark text-xs mb-2">
                            *) Gunakan gambar berdimensi 1080x708
                          </p>
                          <label className="p-2 flex flex-col w-full h-36 items-center justify-center border-4 border-dashed hover:bg-gray-100 hover:border-gray-300">
                            {fileDataURL ? (
                              <div className="flex flex-col items-center justify-center">
                                <img
                                  src={fileDataURL}
                                  className="w-full h-32 object-contain"
                                  alt="preview"
                                />
                              </div>
                            ) : (
                              <div className="flex flex-col items-center justify-center pt-2">
                                {/* <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="w-12 h-12 text-gray-400 group-hover:text-gray-600"
                                  viewBox="0 0 20 20"
                                  fill="currentColor">
                                  <path
                                    fillRule="evenodd"
                                    d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z"
                                    clipRule="evenodd"
                                  />
                                </svg> */}
                                <p className="pt-1 text-sm tracking-wider text-gray-400 group-hover:text-gray-600">
                                  Select a photo
                                </p>
                              </div>
                            )}
                            <input
                              type="file"
                              className="opacity-0"
                              {...register('satpaminboxgambar')}
                              accept="image/*"
                            />
                          </label>
                        </div>
                        <p className="text-errorText text-xs">
                          {errors.satpaminboxgambar?.message}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {tipe === 'Video' && (
                <div className="md:flex items-center">
                  <div className="md:w-1/4">
                    <label className="block font-semibold mb-1 md:mb-0">ID Youtube</label>
                  </div>
                  <div className="md:w-3/4">
                    <input
                      type="text"
                      className={`w-full ${errors.satpaminboxvideo ? 'invalidForm' : 'inputForm'}`}
                      {...register('satpaminboxvideo')}
                    />
                    <p className="text-errorText text-xs">{errors.satpaminboxvideo?.message}</p>
                  </div>
                </div>
              )}
            </div>
            <div className=" bg-gray-50 p-5 rounded-lg shadowAll flex flex-col justify-around">
              <div className="md:flex items-center mb-3">
                <div className="md:w-1/4">
                  <label className="block font-semibold mb-1 md:mb-0">Judul Inbox</label>
                </div>
                <div className="md:w-3/4">
                  <input
                    type="text"
                    className={`w-full ${errors.satpaminboxjudul ? 'invalidForm' : 'inputForm'}`}
                    {...register('satpaminboxjudul')}
                  />
                  <p className="text-errorText text-xs">{errors.satpaminboxjudul?.message}</p>
                </div>
              </div>
              <div className="md:flex mb-3">
                <div className="md:w-1/4">
                  <label className="block font-semibold mb-1 md:mb-0">Judul Detail Inbox</label>
                </div>
                <div className="md:w-3/4">
                  <textarea
                    rows={3}
                    className={`w-full ${
                      errors.satpaminboxjuduldetail ? 'invalidForm' : 'inputForm'
                    }`}
                    {...register('satpaminboxjuduldetail')}
                  />
                  <p className="text-errorText text-xs">{errors.satpaminboxjuduldetail?.message}</p>
                </div>
              </div>
              <div className="md:flex">
                <div className="md:w-1/4">
                  <label className="block font-semibold mb-1 md:mb-0">Deskripsi</label>
                </div>
                <div className="md:w-3/4">
                  <ReactQuill
                    theme="snow"
                    modules={modules}
                    formats={formats}
                    value={deskripsi}
                    onChange={setDeskripsi}
                  />
                </div>
              </div>
            </div>
            <div className="bg-gray-50 p-5 rounded-lg shadowAll">
              <div className="md:flex mb-3 items-center">
                <div className="md:w-1/4">
                  <label htmlFor="tanggal_aktifitas" className="block font-semibold mb-1 md:mb-0">
                    Teks Aksi
                  </label>
                </div>
                <div className="md:w-3/4">
                  <input
                    type="text"
                    className={`w-full ${errors.satpaminboxaksitext ? 'invalidForm' : 'inputForm'}`}
                    {...register('satpaminboxaksitext')}
                  />
                  <p className="text-errorText text-xs">{errors.satpaminboxaksitext?.message}</p>
                </div>
              </div>
              <div className="md:flex items-center">
                <div className="md:w-1/4">
                  <label htmlFor="tanggal" className="block font-semibold mb-1 md:mb-0">
                    Link Aksi
                  </label>
                </div>
                <div className="md:w-3/4">
                  <input
                    type="text"
                    className={`w-full ${errors.satpaminboxaksi ? 'invalidForm' : 'inputForm'}`}
                    {...register('satpaminboxaksi')}
                  />
                  <p className="text-errorText text-xs">{errors.satpaminboxaksi?.message}</p>
                </div>
              </div>
            </div>
          </div>

          <div className="flex justify-between items-center w-full py-5 px-10 shadowAll rounded-lg">
            <button
              type="submit"
              className="text-gray-50 py-2 px-5 rounded-md text-sm bg-dark hover:opacity-80"
              disabled={loading === true ? true : false}>
              {loading ? (
                <ClipLoader color="white" loading={true} size={20} speedMultiplier={0.7} />
              ) : (
                'Simpan'
              )}
            </button>

            <Link to="/inbox">
              <button className="btn text-gray-50 py-2 px-5 rounded-md text-sm bg-dark hover:opacity-80">
                Kembali
              </button>
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
}
