import React, { useState } from 'react';
import * as api from '../../redux/api';
import Swal from 'sweetalert2';
import { Link } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import useImagePreview from '../../hooks/useImagePreview';
import { perusahaanSchema } from '../../utils/schemaValidation';
import imageCompression from 'browser-image-compression';

export default function PerusahaanCreate() {
  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch
  } = useForm({
    resolver: yupResolver(perusahaanSchema)
  });

  const [fileDataURL] = useImagePreview(watch('satpamperusahaanfoto'));

  const compressOptions = {
    maxSizeMB: 1,
    maxWidthOrHeight: 1920,
    useWebWorker: true
  };

  const onSubmit = async (data) => {
    const formData = new FormData();

    if (data.satpamperusahaanfoto.length > 0) {
      const compressedFile = await imageCompression(data.satpamperusahaanfoto[0], compressOptions);
      formData.append('satpamperusahaanfoto', compressedFile, compressedFile.name);
    }

    for (const key in data) {
      if (key === 'field') {
        formData.append(key, data[key][1]);
      } else {
        formData.append(key, data[key]);
      }
    }

    setLoading(true);

    await api
      .createPerusahaan(formData)
      .then((res) => {
        Swal.fire('Good job!', 'You successfully submit the form!', 'success');
        setLoading(false);
        reset();
        reset({ satpamperusahaanfoto: '' });
      })
      .catch((error) => {
        setLoading(false);
        console.log(error.message);
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: error.response.data.message
        });
      });
  };

  return (
    <div className="relative px-4 md:px-10 mx-auto w-full -mt-8 scrollbar-thin scrollbar-track-gray-100 scrollbar-thumb-gray-300">
      <div className="w-full min-h-screen">
        <div className="py-3 bg-gray-50 rounded-lg shadow-black/40 shadow-lg flex justify-center items-center mb-10 text-center text-lg font-semibold">
          Tambah Perusahaan
        </div>
        <form
          onSubmit={handleSubmit(onSubmit)}
          encType="multipart/form-data"
          className="w-full flex flex-col gap-5">
          <div className="flex flex-wrap lg:flex-nowrap text-sm gap-5">
            <div className="w-full flex flex-col gap-5">
              <div className=" bg-gray-50 p-5 rounded-lg shadowAll flex flex-col justify-around">
                <div className="md:flex items-center mb-3">
                  <div className="md:w-1/3">
                    <label className="block font-semibold mb-1 md:mb-0">Nama Perusahaan</label>
                  </div>
                  <div className="md:w-2/3">
                    <input
                      type="text"
                      className={`w-full ${
                        errors.satpamperusahaannama ? 'invalidForm' : 'inputForm'
                      }`}
                      rows={3}
                      {...register('satpamperusahaannama')}
                    />
                    <p className="text-errorText text-xs">{errors.satpamperusahaannama?.message}</p>
                  </div>
                </div>
                <div className="md:flex mb-3">
                  <div className="md:w-1/3">
                    <label className="block font-semibold mb-1 md:mb-0">Alamat</label>
                  </div>
                  <div className="md:w-2/3">
                    <textarea
                      className={`w-full ${
                        errors.satpamperusahaanalamat ? 'invalidForm' : 'inputForm'
                      }`}
                      rows={3}
                      {...register('satpamperusahaanalamat')}
                    />
                    <p className="text-errorText text-xs">
                      {errors.satpamperusahaanalamat?.message}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full flex flex-col gap-5 ">
              <div className="bg-gray-50 p-5 rounded-lg shadowAll mb-3">
                <div className="md:flex mb-3">
                  <div className="md:w-1/3">
                    <label htmlFor="satpamfoto" className="block font-semibold mb-1 md:mb-0">
                      Foto Profil
                    </label>
                  </div>
                  <div className="md:w-2/3">
                    <div className="flex w-full">
                      <div className="w-full">
                        <div className="h-auto p-3 bg-white rounded-md border border-gray-200 shadow-md">
                          <div className="flex flex-col w-full">
                            <label className="p-2 flex flex-col w-full h-28 items-center justify-center border-4 border-dashed hover:bg-gray-100 hover:border-gray-300">
                              {fileDataURL ? (
                                <div className="flex flex-col items-center justify-center">
                                  <img
                                    src={fileDataURL}
                                    className="w-full h-24 object-contain"
                                    alt="preview"
                                  />
                                </div>
                              ) : (
                                <div className="flex flex-col items-center justify-center pt-2">
                                  {/* <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="w-12 h-12 text-gray-400 group-hover:text-gray-600"
                                    viewBox="0 0 20 20"
                                    fill="currentColor">
                                    <path
                                      fillRule="evenodd"
                                      d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z"
                                      clipRule="evenodd"
                                    />
                                  </svg> */}
                                  <p className="pt-1 text-sm tracking-wider text-gray-400 group-hover:text-gray-600">
                                    Select a photo
                                  </p>
                                </div>
                              )}
                              <input
                                type="file"
                                className="opacity-0"
                                {...register('satpamperusahaanfoto')}
                                accept="image/*"
                              />
                            </label>
                          </div>
                          <p className="text-errorText text-xs">
                            {errors.satpamperusahaanfoto?.message}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="md:flex mb-3 items-center">
                  <div className="md:w-1/3">
                    <label htmlFor="tanggal_aktifitas" className="block font-semibold mb-1 md:mb-0">
                      Radius
                    </label>
                  </div>
                  <div className="md:w-2/3">
                    <input
                      type="text"
                      className={`w-full ${
                        errors.satpamperusahaanradius ? 'invalidForm' : 'inputForm'
                      }`}
                      {...register('satpamperusahaanradius')}
                    />
                    <p className="text-errorText text-xs">
                      {errors.satpamperusahaanradius?.message}
                    </p>
                  </div>
                </div>
                <div className="md:flex mb-3 items-center">
                  <div className="md:w-1/3">
                    <label htmlFor="tanggal" className="block font-semibold mb-1 md:mb-0">
                      Latitude
                    </label>
                  </div>
                  <div className="md:w-2/3">
                    <input
                      type="text"
                      className={`w-full ${
                        errors.satpamperusahaanlatitude ? 'invalidForm' : 'inputForm'
                      }`}
                      {...register('satpamperusahaanlatitude')}
                    />
                    <p className="text-errorText text-xs">
                      {errors.satpamperusahaanlatitude?.message}
                    </p>
                  </div>
                </div>
                <div className="md:flex items-center">
                  <div className="md:w-1/3">
                    <label htmlFor="tanggal" className="block font-semibold mb-1 md:mb-0">
                      longitude
                    </label>
                  </div>
                  <div className="md:w-2/3">
                    <input
                      type="text"
                      className={`w-full ${
                        errors.satpamperusahaanlongitude ? 'invalidForm' : 'inputForm'
                      }`}
                      {...register('satpamperusahaanlongitude')}
                    />
                    <p className="text-errorText text-xs">
                      {errors.satpamperusahaanlongitude?.message}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="flex justify-between items-center w-full py-5 px-10 shadowAll rounded-lg">
            <button
              type="submit"
              className="text-gray-50 py-2 px-5 rounded-md text-sm bg-dark hover:opacity-80"
              disabled={loading === true ? true : false}>
              {loading ? (
                <ClipLoader color="white" loading={true} size={20} speedMultiplier={0.7} />
              ) : (
                'Simpan'
              )}
            </button>

            <Link to="/perusahaan">
              <button className="btn text-gray-50 py-2 px-5 rounded-md text-sm bg-dark hover:opacity-80">
                Kembali
              </button>
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
}
