import React, { useState, useEffect } from 'react';
import * as api from '../../redux/api';
import { Modal } from '@mantine/core';
import moment from 'moment';

export default function ModalKeluhanDetail({ kode, openModalDetail, setOpenModalDetail }) {
  const [dataKeluhan, setDataKeluhan] = useState({});

  useEffect(() => {
    if (kode !== '') {
      const getKeluhanByKode = async () => {
        await api.keluhanDetail(kode).then((res) => {
          setDataKeluhan(res.data.data[0]);
        });
      };

      getKeluhanByKode();
    }
  }, [kode]);

  return (
    <Modal
      opened={openModalDetail}
      size="xl"
      overflow="inside"
      centered
      closeOnClickOutside
      onClose={() => setOpenModalDetail(false)}>
      <div className="text-center text-lg font-bold mb-5">Detail Keluhan</div>
      <div>
        <div className="text-dark scrollbar-thin px-5">
          <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
            <div className="md:w-3/5 w-full font-bold">Tanggal</div>
            <div className="w-full">
              {dataKeluhan?.satpamkeluhantanggal !== '' &&
                dataKeluhan?.satpamkeluhantanggal !== null &&
                moment(dataKeluhan?.satpamkeluhantanggal).format('DD MMM YYYY HH:mm')}
            </div>
          </div>
          <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
            <div className="md:w-3/5 w-full font-bold">Kategori</div>
            <div className="w-full">{dataKeluhan?.satpamkeluhankategorinama || '-'}</div>
          </div>
          <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
            <div className="md:w-3/5 w-full font-bold">Nama Admin</div>
            <div className="w-full">{dataKeluhan?.satpamkeluhanadminnama || '-'}</div>
          </div>
          <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
            <div className="md:w-3/5 w-full font-bold">Isi</div>
            <div className="w-full">{dataKeluhan?.satpamkeluhanisi || '-'}</div>
          </div>
          <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
            <div className="md:w-3/5 w-full font-bold">Status</div>
            <div className="w-full">
              {dataKeluhan?.satpamkeluhanstatus === '1' ? (
                <div className="px-4 border-primary border-2 text-sm text-primary btnStatus rounded text-center">
                  Selesai
                </div>
              ) : (
                <div className="px-4 border-secondary text-sm border-2 text-secondary btnStatus rounded text-center">
                  Belum selesai
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}
