import React, { useState, useEffect } from 'react';
import { Modal, Progress } from '@mantine/core';
import * as api from '../../redux/api';
import moment from 'moment/moment';

const ModalPatroliDetail = ({ kode, openModalDetail, setOpenModalDetail }) => {
  const [dataPatroli, setDataPatroli] = useState([]);

  useEffect(() => {
    if (kode !== '') {
      const getDataPatroliDetail = async () => {
        await api.patroliDetail({ patroli_kode: kode }).then((res) => {
          setDataPatroli(res.data);
        });
      };

      getDataPatroliDetail();
    }
  }, [kode]);

  return (
    <Modal
      opened={openModalDetail}
      size="xl"
      overflow="inside"
      centered
      closeOnClickOutside
      onClose={() => setOpenModalDetail(false)}>
      <div className="text-center text-lg font-bold mb-5">Detail Patroli</div>
      <div>
        <div className="text-dark scrollbar-thin px-5">
          <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
            <div className="md:w-3/5 w-full font-bold">Jenis Patroli</div>
            <div className="w-full">{dataPatroli?.satpampatrolijenis}</div>
          </div>
          <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
            <div className="md:w-3/5 w-full font-bold">Nama Patroli</div>
            <div className="w-full">{dataPatroli?.satpampatrolinama}</div>
          </div>
          <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
            <div className="md:w-3/5 w-full font-bold">Nama POS</div>
            <div className="w-full">{dataPatroli?.satpamposnama}</div>
          </div>
          <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
            <div className="md:w-3/5 w-full font-bold">Nama Perusahaan</div>
            <div className="w-full">{dataPatroli?.satpamperusahaannama}</div>
          </div>
          <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
            <div className="md:w-3/5 w-full font-bold">Nama Personil</div>
            <div className="w-full">{dataPatroli?.satpamnamalengkap}</div>
          </div>
          <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
            <div className="md:w-3/5 w-full font-bold">Tanggal Mulai</div>
            <div className="w-full">
              {dataPatroli?.satpampatrolimulaitanggal !== null
                ? moment(dataPatroli?.satpampatrolimulaitanggal).format('DD MMM YYYY HH:mm')
                : '-'}
            </div>
          </div>
          <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
            <div className="md:w-3/5 w-full font-bold">Tanggal Akhir</div>
            <div className="w-full">
              {dataPatroli.satpampatroliselesaitanggal !== null
                ? moment(dataPatroli?.satpampatroliselesaitanggal).format('DD MMM YYYY HH:mm')
                : '-'}
            </div>
          </div>
          <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
            <div className="md:w-3/5 w-full font-bold">Waktu Patroli</div>
            <div className="w-full">{dataPatroli?.durasi_kumulatif}</div>
          </div>
          {dataPatroli?.satpampatrolikendaraanjenis !== null && (
            <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
              <div className="md:w-3/5 w-full font-bold">Jenis Kendaraan</div>
              <div className="w-full">{dataPatroli?.satpampatrolikendaraanjenis}</div>
            </div>
          )}
          {dataPatroli?.satpampatrolikendaraannama !== null && (
            <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
              <div className="md:w-3/5 w-full font-bold">Nama Kendaraan</div>
              <div className="w-full">{dataPatroli?.satpampatrolikendaraannama}</div>
            </div>
          )}
          {dataPatroli?.satpampatrolikendaraannomor !== null && (
            <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
              <div className="md:w-3/5 w-full font-bold">Nomor Kendaraan</div>
              <div className="w-full">{dataPatroli?.satpampatrolikendaraannomor}</div>
            </div>
          )}
          {dataPatroli?.satpampatrolikilometerawalangka !== null && (
            <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
              <div className="md:w-3/5 w-full font-bold">Km Awal</div>
              <div className="w-full">{dataPatroli?.satpampatrolikilometerawalangka}</div>
            </div>
          )}
          {dataPatroli?.satpampatrolikilometerakhirangka !== null && (
            <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
              <div className="md:w-3/5 w-full font-bold">Km Akhir</div>
              <div className="w-full">{dataPatroli?.satpampatrolikilometerakhirangka}</div>
            </div>
          )}
          {dataPatroli?.satpampatrolikilometerawalangka !== null &&
            dataPatroli?.satpampatrolikilometerakhirangka !== null && (
              <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
                <div className="md:w-3/5 w-full font-bold">Jarak (Km Akhir - Km Awal)</div>
                <div className="w-full">
                  {dataPatroli?.satpampatrolikilometerakhirangka -
                    dataPatroli?.satpampatrolikilometerawalangka}{' '}
                  km
                </div>
              </div>
            )}
          <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
            <div className="md:w-3/5 w-full font-bold">Target Patroli</div>
            <div className="w-full">{dataPatroli?.target_kumulatif} X</div>
          </div>
          <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
            <div className="md:w-3/5 w-full font-bold">Kemajuan</div>
            <div className="w-full">
              {!isNaN(dataPatroli?.persentase_kumulatif) ? (
                <Progress
                  label={
                    Number.isInteger(parseFloat(dataPatroli?.persentase_kumulatif))
                      ? parseFloat(dataPatroli?.persentase_kumulatif) + ' %'
                      : parseFloat(dataPatroli?.persentase_kumulatif).toFixed(3) + ' %'
                  }
                  color={
                    dataPatroli?.persentase_kumulatif.toFixed() <= 30
                      ? 'yellow'
                      : dataPatroli?.persentase_kumulatif.toFixed() <= 70
                      ? 'teal'
                      : 'blue'
                  }
                  value={dataPatroli?.persentase_kumulatif.toFixed()}
                  size="xl"
                  radius="xl"
                />
              ) : (
                <Progress label="0" value={0} size="xl" radius="xl" />
              )}
            </div>
          </div>
          <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 overflow-x-auto">
            <div className="inline-block min-w-full shadow-md rounded-lg overflow-hidden">
              <table className="min-w-full leading-normal">
                <thead>
                  <tr>
                    <th
                      colSpan={3}
                      className="px-2 py-1 border-b-2 align-middle border-gray-300 bg-gray-200 text-xs font-bold text-center tracking-wider">
                      Rute Patroli
                    </th>
                  </tr>
                  <tr>
                    <th className="px-2 py-1 border-b-2 align-middle border-gray-300 bg-gray-200 text-xs font-semibold text-center tracking-wider">
                      Checkpoint
                    </th>
                    <th className="px-2 py-1 border-b-2 align-middle border-gray-300 bg-gray-200 text-xs font-semibold text-center tracking-wider">
                      Target
                    </th>
                    <th className="px-2 py-1 border-b-2 align-middle border-gray-300 bg-gray-200 text-xs font-semibold text-center tracking-wider">
                      Dilakukan
                    </th>
                    <th className="px-2 py-1 border-b-2 align-middle border-gray-300 bg-gray-200 text-xs font-semibold text-center tracking-wider">
                      Kemajuan
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {dataPatroli?.Detail?.map((data) => (
                    <tr key={data.satpamtitiknama}>
                      <td className="px-2 py-1 border-b border-gray-200 bg-white text-sm">
                        <p className="text-center">{data.satpamtitiknama}</p>
                      </td>
                      <td className="px-2 py-1 border-b border-gray-200 bg-white text-sm">
                        <p className="text-center">{data.satpampatrolidetailtarget}</p>
                      </td>
                      <td className="px-2 py-1 border-b border-gray-200 bg-white text-sm">
                        <p className="text-center">{data.satpampatrolidetailkemajuan}</p>
                      </td>
                      <td className="px-2 py-1 border-b border-gray-200 bg-white text-sm">
                        <p className="text-center">
                          {Number.isInteger(parseFloat(data.presentase))
                            ? parseFloat(data.presentase) + ' %'
                            : parseFloat(data.presentase).toFixed(3) + ' %'}
                        </p>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ModalPatroliDetail;
