// Import packages
import React, { useState, useEffect } from 'react';
import * as api from '../../redux/api';
import { Tooltip } from '@mantine/core';
import { Popover } from '@mantine/core';
import Table from '../../components/general/Table';
import { AiOutlineFileAdd } from 'react-icons/ai';
import Swal from 'sweetalert2';
import moment from 'moment/moment';
import ModalKeadaanDetail from '../../components/keadaan/ModalKeadaanDetail';
import ModalKeadaanCreate from '../../components/keadaan/ModalKeadaanCreate';
import { useDebouncedValue } from '@mantine/hooks';
import ReadMore from '../../components/general/ReadMore';

export default function KeadaanData({ role }) {
  const [data, setData] = useState([]);
  const [dataSatpam, setDataSatpam] = useState([]);
  const [openModalDetail, setOpenModalDetail] = useState(false);
  const [openModalKeadaanCreate, setOpenModalKeadaanCreate] = useState(false);
  const [keadaanDetailKode, setKeadaanDetailKode] = useState('');

  const [filterData, setFilterData] = useState([]);

  const [query, setQuery] = useState('');
  const [debouncedQuery] = useDebouncedValue(query, 200);

  // Get data
  const getData = async () => {
    await api.keadaanData().then((res) => {
      setData(res.data.data);
    });
  };

  useEffect(() => {
    const getDataSatpam = async () => {
      await api.satpamData().then((res) => {
        setDataSatpam(res.data.data);
      });
    };
    getDataSatpam();
    getData();
  }, []);

  useEffect(() => {
    setFilterData(
      data.filter(
        ({
          satpamkeadaantanggal,
          satpamkeadaanpesan,
          satpamkeadaanalamat,
          satpamkeadaanlatitude,
          satpamkeadaanlongitude
        }) => {
          if (
            debouncedQuery !== '' &&
            !`${satpamkeadaantanggal} ${satpamkeadaanpesan} ${satpamkeadaanalamat} ${satpamkeadaanlatitude} ${satpamkeadaanlongitude}`
              .toLowerCase()
              .includes(debouncedQuery.trim().toLowerCase())
          ) {
            return false;
          }
          return true;
        }
      )
    );
  }, [data, debouncedQuery]);

  const handleOpenModalDetail = (kode) => {
    setKeadaanDetailKode(kode);
    setOpenModalDetail(true);
  };

  const handleDelete = (kode) => {
    Swal.fire({
      text: 'Do you want to delete this Keadaan?',
      showCancelButton: true,
      confirmButtonText: 'Yes'
    }).then((result) => {
      if (result.isConfirmed) {
        const deleteData = async () => {
          await api
            .keadaanDelete(kode)
            .then((res) => {
              Swal.fire('Good job!', res.data.message, 'success');
              getData();
            })
            .catch((err) => {
              Swal.fire({ icon: 'error', title: 'Oops...', text: err.response.data.message });
            });
        };
        deleteData();
      }
    });
  };

  // Column Data Table
  const columnData = [
    {
      title: 'Action',
      accessor: 'satpamkeadaankode',
      width: 100,
      cellsStyle: {
        verticalAlign: 'top'
      },
      render: ({ satpamkeadaankode }) => (
        <div className="flex items-center gap-1">
          {/* Button Aksi */}
          <Popover width={110} position="right" withArrow shadow="xl">
            <Popover.Target>
              <div className="btnAksi my-1 px-2 py-1">Action</div>
            </Popover.Target>
            <Popover.Dropdown className="popOverDropdown">
              <div
                size="sm"
                className="popOverItem"
                onClick={() => handleOpenModalDetail(satpamkeadaankode)}>
                <i className="fa-solid fa-circle-info"></i> Detail
              </div>
              {role === 'Admin Project' && (
                <div className="popOverItem" onClick={() => handleDelete(satpamkeadaankode)}>
                  <i className="fa-solid fa-trash mr-[2px]"></i> Delete
                </div>
              )}
            </Popover.Dropdown>
          </Popover>
        </div>
      )
    },
    {
      title: 'Tanggal',
      accessor: 'satpamkeadaantanggal',
      width: 150,
      cellsStyle: {
        verticalAlign: 'top'
      },
      render: ({ satpamkeadaantanggal }) => (
        <div>
          <div className="flex justify-center text-center h-auto w-full py-0 my-0 mb-1 cursor-pointer">
            {moment(satpamkeadaantanggal).format('DD MMM YYYY HH:mm')}
          </div>
        </div>
      )
    },
    {
      title: 'Pesan',
      accessor: 'satpamkeadaanpesan',
      width: 400,
      cellsStyle: {
        verticalAlign: 'top'
      },
      render: ({ satpamkeadaanpesan }) => (
        <>
          {satpamkeadaanpesan !== null && satpamkeadaanpesan !== '' && (
            <ReadMore>{satpamkeadaanpesan}</ReadMore>
          )}
        </>
      )
    },
    {
      title: 'Alamat',
      accessor: 'satpamkeadaanalamat',
      cellsStyle: {
        verticalAlign: 'top'
      },
      width: 300
    },
    {
      title: 'Latitude',
      accessor: 'satpamkeadaanlatitude',

      cellsStyle: {
        verticalAlign: 'top'
      },
      width: 150
    },
    {
      title: 'Longitude',
      accessor: 'satpamkeadaanlongitude',
      cellsStyle: {
        verticalAlign: 'top'
      },
      width: 150
    }
  ];

  return (
    <div className="relative px-4 md:px-10 mx-auto w-full -mt-8 pb-10 overflow-x-hidden scrollbar-thin scrollbar-track-gray-100 scrollbar-thumb-gray-300">
      <div className="w-full min-h-screen">
        <div className="w-full justify-center bg-gray-50 rounded-lg shadowAll pb-8">
          <div className="flex flex-wrap lg:flex-nowrap justify-beetween items-center">
            <div className="flex flex-col md:flex-row flex-wrap justify-start items-center w-full"></div>
            {role === 'Admin Project' && (
              <div className="my-3 px-5 flex justify-end">
                <div className="flex gap-4 w-full">
                  <Tooltip label="Tambah Keadaan">
                    <div
                      className="bg-customGray p-2 rounded-md cursor-pointer hover:opacity-80"
                      onClick={() => setOpenModalKeadaanCreate(true)}>
                      <AiOutlineFileAdd size={24} />
                    </div>
                  </Tooltip>
                </div>
              </div>
            )}
          </div>
          {/* Table Component */}
          {data?.length > 0 ? (
            <div className="p-5">
              <Table data={filterData} columnData={columnData} setQuery={setQuery} />
            </div>
          ) : (
            <div className="text-center">
              <div className="mt-10 mb-72">Tidak ada data Keadaan.</div>
            </div>
          )}
        </div>
        {/* Modal Detail Keadaan */}
        <ModalKeadaanDetail
          kode={keadaanDetailKode}
          openModalDetail={openModalDetail}
          setOpenModalDetail={setOpenModalDetail}
        />
        {/* Modal Detail Keadaan */}
        <ModalKeadaanCreate
          getData={getData}
          dataSatpam={dataSatpam}
          openModalKeadaanCreate={openModalKeadaanCreate}
          setOpenModalKeadaanCreate={setOpenModalKeadaanCreate}
        />
      </div>
    </div>
  );
}
