export default function Headerlaporan() {
  return (
    <div className="min-w-fit px-8 py-2 flex items-center bgGradientGAP gap-3 whitespace-nowrap">
      <img src={require('../../images/logo.png')} alt="" className="h-[50px]" />
      <div className="flex flex-col items-start uppercase">
        <div className="text-gray-100 text-[18px] font-bold">GAPS</div>
        <div className="text-gray-100 text-[15px] font-bold">Gapara Asset Protection System</div>
      </div>
    </div>
  );
}
