import moment from 'moment';
import Swal from 'sweetalert2';
import { AiOutlineFileAdd } from 'react-icons/ai';
import { Carousel } from '@mantine/carousel';
import { HiOutlinePrinter } from 'react-icons/hi';
import { Link } from 'react-router-dom';
import { Modal, Tooltip, Popover } from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import { useSelector } from 'react-redux';
import { useState, useEffect } from 'react';

// Import Files
import * as api from '../../redux/api';
import Cards from '../../components/general/Cards';
import FilterDate from '../../components/general/FilterDate';
import FilterKategori from '../../components/general/FilterKategori';
import FilterStatus from '../../components/general/FilterStatus';
import ModalTemuanDetail from '../../components/temuan/ModalTemuanDetail';
import ReadMore from '../../components/general/ReadMore';
import Table from '../../components/general/Table';

export default function Temuan({ role }) {
  const profile = localStorage.profile && JSON.parse(localStorage.profile);
  const adminType = profile.user.adminpetugastipe;

  const [data, setData] = useState([]);
  const [datas, setDatas] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [imageData, setImageData] = useState(null);
  const [kategori, setKategori] = useState('');
  const [openModalDetail, setOpenModalDetail] = useState(false);
  const [showDate, setShowDate] = useState(false);
  const [showImage, setShowImage] = useState(false);
  const [temuanDetailKode, setTemuanDetailKode] = useState('');

  const [query, setQuery] = useState('');
  const [debouncedQuery] = useDebouncedValue(query, 200);
  const { perusahaanKode, startDateAdmin, endDateAdmin } = useSelector((state) => state.perusahaan);
  const [date, setDate] = useState({
    startDate: startDateAdmin || moment().format('YYYY-MM-DD'),
    endDate: endDateAdmin || moment().format('YYYY-MM-DD')
  });

  useEffect(() => {
    setDate({
      startDate: startDateAdmin,
      endDate: endDateAdmin
    });
  }, [startDateAdmin, endDateAdmin]);

  // Get data
  useEffect(() => {
    const formDate = {
      startdate: date.startDate,
      enddate: date.endDate,
      kategori: kategori
    };

    if (perusahaanKode) {
      formDate.satpamperusahaankode = perusahaanKode;
    }

    const getData = async (formDate) => {
      await api.temuan(formDate).then((res) => {
        setData(res.data.Data);
        setDatas(res.data);
      });
    };

    getData(formDate);
    setShowDate(false);
  }, [date, kategori, perusahaanKode]);

  useEffect(() => {
    if (data?.length > 0) {
      setFilterData(
        data.filter(
          ({
            satpamtemuankode,
            satpamtemuantanggalmelaporkan,
            satpamtemuananalisis,
            satpamnamalengkap,
            satpamtemuanuraian,
            satpamtemuantindakan,
            satpamtemuanpatokan,
            satpamtemuanalamat
          }) => {
            if (
              debouncedQuery !== '' &&
              !`${satpamtemuankode} ${satpamtemuantanggalmelaporkan} ${satpamtemuananalisis} ${satpamnamalengkap} ${satpamtemuanuraian} ${satpamtemuantindakan} ${satpamtemuanpatokan} ${satpamtemuanalamat}`
                .toLowerCase()
                .includes(debouncedQuery.trim().toLowerCase())
            ) {
              return false;
            }
            return true;
          }
        )
      );
    }
  }, [data, debouncedQuery]);

  const handleImageData = ({
    satpamtemuanfotosatu,
    satpamtemuanketeranganfotosatu,
    satpamtemuanfotodua,
    satpamtemuanketeranganfotodua,
    satpamtemuanfototiga,
    satpamtemuanketeranganfototiga,
    satpamtemuanfotoempat,
    satpamtemuanketeranganfotoempat,
    satpamtemuanfotolima,
    satpamtemuanketeranganfotolima
  }) => {
    setImageData([
      {
        image: satpamtemuanfotosatu?.split('/data-temuan/')[1] && satpamtemuanfotosatu,
        keterangan: satpamtemuanketeranganfotosatu
      },
      {
        image: satpamtemuanfotodua?.split('/data-temuan/')[1] && satpamtemuanfotodua,
        keterangan: satpamtemuanketeranganfotodua
      },
      {
        image: satpamtemuanfototiga?.split('/data-temuan/')[1] && satpamtemuanfototiga,
        keterangan: satpamtemuanketeranganfototiga
      },
      {
        image: satpamtemuanfotoempat?.split('/data-temuan/')[1] && satpamtemuanfotoempat,
        keterangan: satpamtemuanketeranganfotoempat
      },
      {
        image: satpamtemuanfotolima?.split('/data-temuan/')[1] && satpamtemuanfotolima,
        keterangan: satpamtemuanketeranganfotolima
      }
    ]);
    setShowImage(!showImage);
  };

  const handleOpenModalDetail = (temuanKode) => {
    setTemuanDetailKode(temuanKode);
    setOpenModalDetail(true);
  };

  const handleDelete = (formData) => {
    const formDate = {
      startdate: date.startDate,
      enddate: date.endDate,
      kategori: kategori
    };

    if (perusahaanKode) {
      formDate.satpamperusahaankode = perusahaanKode;
    }

    const getData = async () => {
      await api
        .temuan(formDate)
        .then((res) => {
          setData(res.data.Data);
          setDatas(res.data);
        })
        .catch((err) => console.log(err.message));
    };

    Swal.fire({
      text: 'Do you want to delete this temuan?',
      showCancelButton: true,
      confirmButtonText: 'Yes'
    }).then((result) => {
      if (result.isConfirmed) {
        const deleteData = async () => {
          await api
            .temuanDelete(formData)
            .then((res) => {
              Swal.fire('Good job!', res.data.message, 'success');
              getData();
            })
            .catch((err) => {
              Swal.fire({ icon: 'error', title: 'Oops...', text: err.response.data.message });
            });
        };
        deleteData();
      }
    });
  };

  // Column Data Table
  const columnData = [
    {
      title: 'Action',
      accessor: 'satpamtemuankode',
      width: 100,
      render: ({ satpamtemuankode }) => (
        <div className="flex items-center gap-1">
          {/* Button Aksi */}
          <Popover width={110} position="right" withArrow shadow="xl">
            <Popover.Target>
              <div className="btnAksi my-1">Action</div>
            </Popover.Target>
            <Popover.Dropdown className="popOverDropdown">
              <div
                size="sm"
                className="popOverItem"
                onClick={() => handleOpenModalDetail(satpamtemuankode)}>
                <i className="fa-solid fa-circle-info"></i> Detail
              </div>
              <Link to={`/temuan-printpertem/${satpamtemuankode}`}>
                <div className="popOverItem">
                  <i className="fa-solid fa-print"></i> Print
                </div>
              </Link>
              {adminType === 'Admin Project' && (
                <>
                  <Link to={`/temuan/update/${satpamtemuankode}`}>
                    <div className="popOverItem">
                      <i className="fa-solid fa-pen"></i> Edit
                    </div>
                  </Link>
                  <div
                    className="popOverItem"
                    onClick={() =>
                      handleDelete({
                        satpamtemuankode: satpamtemuankode
                      })
                    }>
                    <i className="fa-solid fa-trash mr-[2px]"></i> Delete
                  </div>
                </>
              )}
            </Popover.Dropdown>
          </Popover>
        </div>
      )
    },
    {
      title: 'Foto Temuan',
      accessor: 'satpamtemuanfotosatu',
      width: 150,
      render: ({
        satpamtemuanfotosatu,
        satpamtemuanketeranganfotosatu,
        satpamtemuanfotodua,
        satpamtemuanketeranganfotodua,
        satpamtemuanfototiga,
        satpamtemuanketeranganfototiga,
        satpamtemuanfotoempat,
        satpamtemuanketeranganfotoempat,
        satpamtemuanfotolima,
        satpamtemuanketeranganfotolima
      }) => (
        <div
          className="h-32 mt-1 mb-2 flex justify-center cursor-pointer hover:opacity-80"
          onClick={() =>
            handleImageData({
              satpamtemuanfotosatu,
              satpamtemuanketeranganfotosatu,
              satpamtemuanfotodua,
              satpamtemuanketeranganfotodua,
              satpamtemuanfototiga,
              satpamtemuanketeranganfototiga,
              satpamtemuanfotoempat,
              satpamtemuanketeranganfotoempat,
              satpamtemuanfotolima,
              satpamtemuanketeranganfotolima
            })
          }>
          <img
            src={
              satpamtemuanfotosatu?.split('/data-temuan/')[1]
                ? satpamtemuanfotosatu
                : satpamtemuanfotodua?.split('/data-temuan/')[1]
                ? satpamtemuanfotodua
                : satpamtemuanfototiga?.split('/data-temuan/')[1]
                ? satpamtemuanfototiga
                : satpamtemuanfotoempat?.split('/data-temuan/')[1]
                ? satpamtemuanfotoempat
                : satpamtemuanfotolima?.split('/data-temuan/')[1]
                ? satpamtemuanfotolima
                : ''
            }
            alt=""
            className="h-full object-contain rounded-lg"
          />
        </div>
      )
    },
    {
      title: 'Status, Kode, dan Tanggal',
      accessor: 'satpamtemuantanggal',
      width: 200,
      cellsStyle: {
        verticalAlign: 'top'
      },
      render: ({ satpamtemuankode, satpamtemuantanggalmelaporkan, satpamtemuanstatus }) => (
        <div className="py-0 my-0">
          <div className="py-0 my-0">{satpamtemuankode} &nbsp;</div>
          <div className="py-0 my-0">
            {satpamtemuantanggalmelaporkan !== undefined &&
              moment(satpamtemuantanggalmelaporkan).format('DD MMM YYYY HH:mm')}
          </div>
          {Number(satpamtemuanstatus) === 0 ? (
            <div className="border-primary border-2 text-sm text-primary btnStatus block w-full rounded-full text-center mb-2">
              Open
            </div>
          ) : (
            <div className="border-secondary text-sm border-2 text-secondary btnStatus block w-full rounded-full text-center mb-2">
              Closed
            </div>
          )}
        </div>
      )
    },
    {
      title: 'Kategori',
      accessor: 'satpamtemuankategorinama',
      width: 200,
      cellsStyle: {
        verticalAlign: 'top'
      },
      render: ({ satpamtemuankategorinama }) => (
        <div className="py-0 my-0">
          {satpamtemuankategorinama !== null ? satpamtemuankategorinama : 'Belum ada kategori'}
        </div>
      )
    },
    // {
    //   title: 'Analisis',
    //   accessor: 'satpamtemuananalisis',
    //   width: 200,
    //   cellsStyle: {
    //     verticalAlign: 'top'
    //   },
    //   render: ({ satpamtemuananalisis }) => (
    //     <div className="py-0 my-0">
    //       {satpamtemuananalisis !== null ? satpamtemuananalisis : 'Belum ada analisis'}
    //     </div>
    //   )
    // },
    {
      title: 'Nama Personil',
      accessor: 'satpamnamalengkap',
      cellsStyle: {
        verticalAlign: 'top'
      },
      width: 150
    },
    {
      title: 'Uraian Temuan',
      accessor: 'satpamtemuanuraian',
      cellsStyle: {
        verticalAlign: 'top'
      },
      width: 350,
      render: ({ satpamtemuanuraian }) => (
        <>{satpamtemuanuraian !== '' && <ReadMore>{satpamtemuanuraian}</ReadMore>}</>
      )
    },
    {
      title: 'Uraian Tindakan',
      accessor: 'satpamtemuantindakan',
      cellsStyle: {
        verticalAlign: 'top'
      },
      width: 350,
      render: ({ satpamtemuantindakan }) => (
        <>{satpamtemuantindakan !== '' && <ReadMore>{satpamtemuantindakan}</ReadMore>}</>
      )
    },
    {
      title: 'Hasil Tindakan',
      accessor: 'satpamtemuanhasil',
      cellsStyle: {
        verticalAlign: 'top'
      },
      width: 350,
      render: ({ satpamtemuanhasil }) => (
        <>{satpamtemuanhasil !== '' && <ReadMore>{satpamtemuanhasil}</ReadMore>}</>
      )
    },
    {
      title: 'Patokan lokasi',
      accessor: 'satpamtemuanalamatpatokan',
      cellsStyle: {
        verticalAlign: 'top'
      },
      width: 150
    },
    {
      title: 'Alamat',
      accessor: 'satpamtemuanalamat',
      cellsStyle: {
        verticalAlign: 'top'
      },
      width: 300
    }
  ];

  return (
    <div className={`w-full min-h-screen p-5 ${role === 'Admin Project' ? 'mt-1' : '-mt-10'}`}>
      {/* Cards */}
      <Cards
        title="Statistik Temuan"
        desc="Berikut ini adalah jumlah temuan yang ditemukan"
        card1={{ title: 'Open', value: datas?.Total_Open || 0 }}
        card2={{ title: 'Close', value: datas?.Total_Close || 0 }}
        card3={{ title: 'Total', value: datas?.Total_Data || 0 }}
      />

      <div className="mt-10 w-full justify-center bg-gray-50 rounded-lg shadowAll">
        <div className="flex flex-wrap lg:flex-nowrap justify-beetween items-center">
          <div className="flex flex-col md:flex-row flex-wrap justify-start items-center w-full">
            <FilterDate
              date={date}
              setDate={setDate}
              showDate={showDate}
              setShowDate={setShowDate}
            />
            <FilterKategori kategori={kategori} setKategori={setKategori} page="temuan" />
            <FilterStatus laporan="temuan" data={data} setFilterData={setFilterData} />
          </div>
          <div className="my-3 px-5 flex justify-end">
            <div className="flex gap-4 w-full">
              {role === 'Admin Project' && (
                <Tooltip label="Tambah temuan">
                  <Link to="/temuan/create">
                    <div className="bg-customGray p-2 rounded-md cursor-pointer hover:opacity-80">
                      <AiOutlineFileAdd size={24} />
                    </div>
                  </Link>
                </Tooltip>
              )}
              <Link to={`/temuan-print`} state={{ data: data, date: date }}>
                <div className="bg-customGray p-2 rounded-md cursor-pointer hover:opacity-80 flex items-center text-gray-700">
                  <HiOutlinePrinter size={24} />
                </div>
              </Link>
            </div>
          </div>
        </div>

        {/* Table Component */}
        {datas.message === 'Data empty' ? (
          <div className="text-center p-5">
            <div className="mt-10">Tidak ada data temuan pada tanggal tersebut.</div>
            <div className="mb-72">Silahkan memilih rentang tanggal lainnya!</div>
          </div>
        ) : (
          <div className="p-5">
            <Table
              data={filterData}
              columnData={columnData}
              setQuery={setQuery}
              id="satpamtemuanid"
            />
          </div>
        )}
      </div>

      {/* Modal Detail temuan */}
      <ModalTemuanDetail
        kode={temuanDetailKode}
        openModalDetail={openModalDetail}
        setOpenModalDetail={setOpenModalDetail}
      />

      {/* Modal Image */}
      <Modal
        centered
        size="xl"
        opened={showImage}
        onClose={() => setShowImage(false)}
        className="overflow-auto bg-transparent -mt-10 scrollbar-thin">
        {/* Content image */}
        <Carousel mx="auto" withIndicators height="auto" dragFree slideGap="md" align="start">
          {imageData &&
            imageData
              .filter((data) => data.image.split('/data-temuan/')[1])
              .map((item, i) => (
                <Carousel.Slide key={i} className="w-full">
                  {item.image && (
                    <img
                      src={item.image}
                      alt=""
                      className="w-full object-contain rounded-t-lg flex items-center justify-center"
                    />
                  )}
                  {item.keterangan && (
                    <div className="bg-primary text-gray-50 text-center text-[14px] rounded-b-lg py-2">
                      {item.keterangan}
                    </div>
                  )}
                </Carousel.Slide>
              ))}
          {/* ...other slides */}
        </Carousel>
      </Modal>
    </div>
  );
}
