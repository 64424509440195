export default function HeaderlaporanMail() {
  return (
    <div
      style={{
        display: 'flex',
        minWidth: 'fit-content',
        alignItems: 'center',
        gap: '0.75rem',
        whiteSpace: 'nowrap',
        backgroundColor: 'rgb(14, 105, 211)',
        padding: '0.5rem 2rem'
      }}>
      <img
        src="https://api.gapara.co.id/assets/upload/logo.png"
        alt=""
        style={{ height: '50px' }}
      />
      <div
        style={{
          padding: '0 0.25rem',
          textAlign: 'left',
          textTransform: 'uppercase'
        }}>
        <div
          style={{
            display: 'block',
            fontSize: '18px',
            fontWeight: '700',
            color: 'rgb(243, 244, 246)'
          }}>
          GAPS
        </div>
        <div
          style={{
            display: 'block',
            fontSize: '15px',
            fontWeight: '700',
            color: 'rgb(243, 244, 246)'
          }}>
          Gapara Asset Protection System
        </div>
      </div>
    </div>
  );
}
