/* eslint-disable array-callback-return */
// Import packages
import React, { useState, useEffect, useRef } from 'react';
import * as api from '../../redux/api';
import { Tooltip } from '@mantine/core';
import moment from 'moment';
import { ClipLoader } from 'react-spinners';
import ReactToPrint from 'react-to-print';
import { HiOutlinePrinter } from 'react-icons/hi';
import Headerlaporan from '../../components/general/HeaderLaporan';

export default function Analisis() {
  // Get Profile
  const profile = localStorage.profile && JSON.parse(localStorage.profile);
  const adminType = profile.user.adminpetugastipe;

  const [month, setMonth] = useState('');
  const [loading, setLoading] = useState(false);
  const [monthOptions, setMonthOptions] = useState([]);
  const [dataKejadian, setDataKejadian] = useState([]);
  const [dataKegiatan, setDataKegiatan] = useState([]);
  const [dataTemuan, setDataTemuan] = useState([]);
  const [dataPersonil, setDataPersonil] = useState([]);
  // const [dataKeluhan, setDataKeluhan] = useState([]);

  const [totalMinggu1, setTotalMinggu1] = useState({
    kejadian: 0,
    kegiatan: 0,
    temuan: 0,
    keluhan: 0,
    personil: 0
  });
  const [totalMinggu2, setTotalMinggu2] = useState({
    kejadian: 0,
    kegiatan: 0,
    temuan: 0,
    keluhan: 0,
    personil: 0
  });
  const [totalMinggu3, setTotalMinggu3] = useState({
    kejadian: 0,
    kegiatan: 0,
    temuan: 0,
    keluhan: 0,
    personil: 0
  });
  const [totalMinggu4, setTotalMinggu4] = useState({
    kejadian: 0,
    kegiatan: 0,
    temuan: 0,
    keluhan: 0,
    personil: 0
  });

  const componentRef = useRef();

  useEffect(() => {
    const getDataMonth = async () => {
      await api
        .getMonth()
        .then((res) => {
          const dataArray = Object.values(res.data);
          dataArray.sort((a, b) => new Date(b.month) - new Date(a.month));
          dataArray.map((data) => {
            setMonthOptions((prev) => [...prev, { value: data.month, label: data.month_name }]);
          });
        })
        .catch();
    };

    getDataMonth();
  }, []);

  useEffect(() => {
    const today = new Date();
    setMonth(moment(today).format('YYYY-M'));
  }, [monthOptions]);

  useEffect(() => {
    setLoading(true);

    let dataMinggu1Kejadian = [];
    let dataMinggu2Kejadian = [];
    let dataMinggu3Kejadian = [];
    let dataMinggu4Kejadian = [];

    let dataMinggu1Kegiatan = [];
    let dataMinggu2Kegiatan = [];
    let dataMinggu3Kegiatan = [];
    let dataMinggu4Kegiatan = [];

    let dataMinggu1Temuan = [];
    let dataMinggu2Temuan = [];
    let dataMinggu3Temuan = [];
    let dataMinggu4Temuan = [];

    // let dataMinggu1Keluhan = [];
    // let dataMinggu2Keluhan = [];
    // let dataMinggu3Keluhan = [];
    // let dataMinggu4Keluhan = [];

    let dataMinggu1Personil = [];
    let dataMinggu2Personil = [];
    let dataMinggu3Personil = [];
    let dataMinggu4Personil = [];

    if (month !== '') {
      const getDataKejadian = async (month) => {
        await api
          .analisisKejadian({ month: moment(month).format('YYYY-MM') })
          .then((res) => {
            if (res.data.data.length > 0) {
              setDataKejadian(res.data.data);
              res.data.data.map((jadwal) => {
                dataMinggu1Kejadian.push(parseInt(jadwal.minggu1));
                dataMinggu2Kejadian.push(parseInt(jadwal.minggu2));
                dataMinggu3Kejadian.push(parseInt(jadwal.minggu3));
                dataMinggu4Kejadian.push(parseInt(jadwal.minggu4));
              });
              setTotalMinggu1((prev) => ({
                ...prev,
                kejadian: dataMinggu1Kejadian.reduce((a, b) => a + b, 0)
              }));
              setTotalMinggu2((prev) => ({
                ...prev,
                kejadian: dataMinggu2Kejadian.reduce((a, b) => a + b, 0)
              }));
              setTotalMinggu3((prev) => ({
                ...prev,
                kejadian: dataMinggu3Kejadian.reduce((a, b) => a + b, 0)
              }));
              setTotalMinggu4((prev) => ({
                ...prev,
                kejadian: dataMinggu4Kejadian.reduce((a, b) => a + b, 0)
              }));
            }
            setLoading(false);
          })
          .catch((err) => {
            setLoading(false);
          });
      };
      const getDataKegiatan = async (month) => {
        await api
          .analisisKegiatan({ month: moment(month).format('YYYY-MM') })
          .then((res) => {
            if (res.data.data.length > 0) {
              setDataKegiatan(res.data.data);
              res.data.data.map((jadwal) => {
                dataMinggu1Kegiatan.push(parseInt(jadwal.minggu1));
                dataMinggu2Kegiatan.push(parseInt(jadwal.minggu2));
                dataMinggu3Kegiatan.push(parseInt(jadwal.minggu3));
                dataMinggu4Kegiatan.push(parseInt(jadwal.minggu4));
              });
              setTotalMinggu1((prev) => ({
                ...prev,
                kegiatan: dataMinggu1Kegiatan.reduce((a, b) => a + b, 0)
              }));
              setTotalMinggu2((prev) => ({
                ...prev,
                kegiatan: dataMinggu2Kegiatan.reduce((a, b) => a + b, 0)
              }));
              setTotalMinggu3((prev) => ({
                ...prev,
                kegiatan: dataMinggu3Kegiatan.reduce((a, b) => a + b, 0)
              }));
              setTotalMinggu4((prev) => ({
                ...prev,
                kegiatan: dataMinggu4Kegiatan.reduce((a, b) => a + b, 0)
              }));
            }
            setLoading(false);
          })
          .catch((err) => {
            setLoading(false);
          });
      };
      const getDataTemuan = async (month) => {
        await api
          .analisisTemuan({ month: moment(month).format('YYYY-MM') })
          .then((res) => {
            if (res.data.data.length > 0) {
              setDataTemuan(res.data.data);
              res.data.data.map((jadwal) => {
                dataMinggu1Temuan.push(parseInt(jadwal.minggu1));
                dataMinggu2Temuan.push(parseInt(jadwal.minggu2));
                dataMinggu3Temuan.push(parseInt(jadwal.minggu3));
                dataMinggu4Temuan.push(parseInt(jadwal.minggu4));
              });
              setTotalMinggu1((prev) => ({
                ...prev,
                temuan: dataMinggu1Temuan.reduce((a, b) => a + b, 0)
              }));
              setTotalMinggu2((prev) => ({
                ...prev,
                temuan: dataMinggu2Temuan.reduce((a, b) => a + b, 0)
              }));
              setTotalMinggu3((prev) => ({
                ...prev,
                temuan: dataMinggu3Temuan.reduce((a, b) => a + b, 0)
              }));
              setTotalMinggu4((prev) => ({
                ...prev,
                temuan: dataMinggu4Temuan.reduce((a, b) => a + b, 0)
              }));
            }
            setLoading(false);
          })
          .catch((err) => {
            setLoading(false);
          });
      };
      // const getDataKeluhan = async (month) => {
      //   await api
      //     .analisisKeluhan({ month: moment(month).format('YYYY-MM') })
      //     .then((res) => {
      //       if (res.data.data.data.length > 0) {
      //         setDataKeluhan(res.data.data.data);
      //         res.data.data.data.map((jadwal) => {
      //           dataMinggu1Keluhan.push(parseInt(jadwal.minggu1));
      //           dataMinggu2Keluhan.push(parseInt(jadwal.minggu2));
      //           dataMinggu3Keluhan.push(parseInt(jadwal.minggu3));
      //           dataMinggu4Keluhan.push(parseInt(jadwal.minggu4));
      //         });
      //         setTotalMinggu1((prev) => ({
      //           ...prev,
      //           keluhan: dataMinggu1Keluhan.reduce((a, b) => a + b, 0)
      //         }));
      //         setTotalMinggu2((prev) => ({
      //           ...prev,
      //           keluhan: dataMinggu2Keluhan.reduce((a, b) => a + b, 0)
      //         }));
      //         setTotalMinggu3((prev) => ({
      //           ...prev,
      //           keluhan: dataMinggu3Keluhan.reduce((a, b) => a + b, 0)
      //         }));
      //         setTotalMinggu4((prev) => ({
      //           ...prev,
      //           keluhan: dataMinggu4Keluhan.reduce((a, b) => a + b, 0)
      //         }));
      //       }
      //       setLoading(false);
      //     })
      //     .catch((err) => {
      //       setLoading(false);
      //     });
      // };
      const getDataPersonil = async (month) => {
        await api
          .analisisSatpam({ month: moment(month).format('YYYY-MM') })
          .then((res) => {
            if (res.data.data.data.length > 0) {
              setDataPersonil(res.data.data.data);
              res.data.data.data.map((jadwal) => {
                dataMinggu1Personil.push(parseInt(jadwal.sudah_absen));
                dataMinggu2Personil.push(parseInt(jadwal.izin));
                dataMinggu3Personil.push(parseInt(jadwal.sakit));
                dataMinggu4Personil.push(parseInt(jadwal.alfa));
              });
              setTotalMinggu1((prev) => ({
                ...prev,
                personil: dataMinggu1Personil.reduce((a, b) => a + b, 0)
              }));
              setTotalMinggu2((prev) => ({
                ...prev,
                personil: dataMinggu2Personil.reduce((a, b) => a + b, 0)
              }));
              setTotalMinggu3((prev) => ({
                ...prev,
                personil: dataMinggu3Personil.reduce((a, b) => a + b, 0)
              }));
              setTotalMinggu4((prev) => ({
                ...prev,
                personil: dataMinggu4Personil.reduce((a, b) => a + b, 0)
              }));
            }
            setLoading(false);
          })
          .catch((err) => {
            setLoading(false);
          });
      };
      getDataPersonil(month);
      getDataKejadian(month);
      getDataKegiatan(month);
      getDataTemuan(month);
      // getDataKeluhan(month);
    }
    setLoading(false);
  }, [month]);

  return (
    <div className="relative px-4 md:px-10 mx-auto w-full -mt-8 overflow-x-hidden scrollbar-thin scrollbar-track-gray-100 scrollbar-thumb-gray-300">
      <div className="w-full min-h-screen">
        <div className="mb-5 w-full min-h-screen justify-center bg-white rounded-lg shadowAll">
          <div className="flex flex-wrap lg:flex-nowrap justify-beetween items-center">
            <div className="flex flex-col md:flex-row flex-wrap justify-start items-center w-full">
              <div className="flex flex-col md:items-center md:flex-row w-full gap-1 md:my-3 my-2 pr-5 md:pr-2 pl-5">
                <div className="font-semibold text-md mr-2 mb-1 md:mb-0">Filter month</div>
                <div className="md:flex md:flex-row gap-2">
                  <div className="flex md:flex-row flex-col justify-center items-center md:mt-0 mt-2">
                    <div className="w-full">
                      <select
                        className="cursor-pointer inputForm text-md w-full"
                        value={month}
                        onChange={(e) => setMonth(e.target.value)}>
                        <option value="">Pilih bulan</option>
                        {monthOptions.length > 0 ? (
                          monthOptions.map((month, i) => (
                            <option key={i} value={month.value}>
                              {month.label + ' ' + month.value?.split('-')[0]}
                            </option>
                          ))
                        ) : (
                          <option value="" disabled>
                            Belum ada data
                          </option>
                        )}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="my-3 px-5 flex justify-end">
              <div className="flex gap-4 w-full">
                <Tooltip label="Cetak Analisis">
                  <ReactToPrint
                    trigger={() => (
                      <div className="bg-customGray p-2 rounded-md cursor-pointer hover:opacity-80">
                        <HiOutlinePrinter size={24} />
                      </div>
                    )}
                    content={() => componentRef.current}
                  />
                </Tooltip>
              </div>
            </div>
          </div>
          <div className="px-5">
            <table className="w-full" ref={componentRef}>
              <thead>
                <tr>
                  <th>
                    <div className="flex w-full">
                      <Headerlaporan />
                      <div className="w-full px-5 bg-dark flex flex-col items-end justify-center text-white">
                        <div className="text-gray-100 text-[18px] font-bold">LAPORAN ANALISIS</div>
                        {/* <div className="text-gray-100 text-[15px] font-bold">
                  {item.satpamperusahaannama}
                </div> */}
                      </div>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    {month === '' ? (
                      <div className="text-center">
                        <div className="mt-10">Belum ada bulan yang dipilih.</div>
                      </div>
                    ) : loading ? (
                      <div className="flex min-h-screen-75 justify-center items-center">
                        <ClipLoader
                          color="#052D5A"
                          loading={true}
                          size={40}
                          speedMultiplier={0.7}
                        />
                      </div>
                    ) : (
                      <div className="overflow-auto scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100">
                        <div className="block w-full p-5">
                          {/* Laporan Kegiatan Harian */}
                          <table className="table-auto border-gray-50 text-sm mx-auto w-full">
                            <thead className="bg-primary font-bold text-gray-100">
                              <tr>
                                <th
                                  colSpan="8"
                                  className="border border-customGray px-2 py-2 text-left whitespace-nowrap">
                                  <p className="font-semibold">A. LAPORAN KEGIATAN HARIAN</p>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {/* Tabel Kegiatan Harian */}
                              <tr>
                                <td
                                  rowSpan="2"
                                  className="border border-customGray px-2 py-2 text-center whitespace-nowrap">
                                  <p className="font-semibold">No</p>
                                </td>
                                <td
                                  rowSpan="2"
                                  className="w-1/2 border border-customGray px-2 py-2 whitespace-nowrap">
                                  <p className="font-semibold">Kategori Kegiatan Harian</p>
                                </td>
                                <td
                                  colSpan="4"
                                  className="border border-customGray px-2 py-2 text-center whitespace-nowrap">
                                  <p className="font-semibold">Minggu Ke-</p>
                                </td>
                                <td
                                  rowSpan="2"
                                  className="border border-customGray px-2 py-2 text-center whitespace-nowrap">
                                  <p className="font-semibold">Total</p>
                                </td>
                                <td
                                  rowSpan="2"
                                  className="w-1/2 border border-customGray px-2 py-2 text-center whitespace-nowrap">
                                  <p className="font-semibold">Keterangan</p>
                                </td>
                              </tr>
                              <tr>
                                <td className="border border-customGray px-2 py-2 text-center">
                                  <p className="font-semibold">I</p>
                                </td>
                                <td className="border border-customGray px-2 py-2 text-center">
                                  <p className="font-semibold">II</p>
                                </td>
                                <td className="border border-customGray px-2 py-2 text-center">
                                  <p className="font-semibold">III</p>
                                </td>
                                <td className="border border-customGray px-2 py-2 text-center">
                                  <p className="font-semibold">VI</p>
                                </td>
                              </tr>
                              {dataKegiatan?.map((data, index) => (
                                <tr key={data.kategori_kode}>
                                  <td className="border border-customGray px-2 py-2 text-center">
                                    {index + 1}.
                                  </td>
                                  <td className="border border-customGray px-2 py-2 text-left">
                                    {data.kategori_name}
                                  </td>
                                  <td className="border border-customGray px-2 py-2 text-center">
                                    {data.minggu1}
                                  </td>
                                  <td className="border border-customGray px-2 py-2 text-center">
                                    {data.minggu2}
                                  </td>
                                  <td className="border border-customGray px-2 py-2 text-center">
                                    {data.minggu3}
                                  </td>
                                  <td className="border border-customGray px-2 py-2 text-center">
                                    {data.minggu4}
                                  </td>
                                  <td className="border border-customGray px-2 py-2 text-center">
                                    {parseInt(data.minggu1) +
                                      parseInt(data.minggu2) +
                                      parseInt(data.minggu3) +
                                      parseInt(data.minggu4)}
                                  </td>
                                  <td className="border border-customGray px-2 py-2 text-left"></td>
                                </tr>
                              ))}
                              <tr>
                                <td
                                  colSpan="2"
                                  className="border border-customGray px-2 py-2 text-left">
                                  <p className="font-semibold">Total</p>
                                </td>
                                <td className="border border-customGray px-2 py-2 text-center">
                                  <p className="font-semibold">{totalMinggu1.kegiatan}</p>
                                </td>
                                <td className="border border-customGray px-2 py-2 text-center">
                                  <p className="font-semibold">{totalMinggu2.kegiatan}</p>
                                </td>
                                <td className="border border-customGray px-2 py-2 text-center">
                                  <p className="font-semibold">{totalMinggu3.kegiatan}</p>
                                </td>
                                <td className="border border-customGray px-2 py-2 text-center">
                                  <p className="font-semibold">{totalMinggu4.kegiatan}</p>
                                </td>
                                <td className="border border-customGray px-2 py-2 text-center">
                                  <p className="font-semibold">
                                    {totalMinggu1.kegiatan +
                                      totalMinggu2.kegiatan +
                                      totalMinggu3.kegiatan +
                                      totalMinggu4.kegiatan}
                                  </p>
                                </td>
                                <td className="border border-customGray px-2 py-2 text-left">
                                  <p className="font-semibold"></p>
                                </td>
                              </tr>
                            </tbody>
                          </table>

                          {/* Laporan Kejadian */}
                          <table className="table-auto border-gray-50 text-sm mx-auto w-full">
                            <thead className="bg-primary font-bold text-gray-100">
                              <tr>
                                <th
                                  colSpan="8"
                                  className="border border-customGray px-2 py-2 text-left whitespace-nowrap">
                                  <p className="font-semibold">B. LAPORAN KEJADIAN</p>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {/* Tabel Kejadian */}
                              <tr>
                                <td
                                  rowSpan="2"
                                  className="border border-customGray px-2 py-2 text-center whitespace-nowrap">
                                  <p className="font-semibold">No</p>
                                </td>
                                <td
                                  rowSpan="2"
                                  className="w-1/2 border border-customGray px-2 py-2 whitespace-nowrap">
                                  <p className="font-semibold">Kategori Kejadian</p>
                                </td>
                                <td
                                  colSpan="4"
                                  className="border border-customGray px-2 py-2 text-center whitespace-nowrap">
                                  <p className="font-semibold">Minggu Ke-</p>
                                </td>
                                <td
                                  rowSpan="2"
                                  className="border border-customGray px-2 py-2 text-center whitespace-nowrap">
                                  <p className="font-semibold">Total</p>
                                </td>
                                <td
                                  rowSpan="2"
                                  className="w-1/2 border border-customGray px-2 py-2 text-center whitespace-nowrap">
                                  <p className="font-semibold">Keterangan</p>
                                </td>
                              </tr>
                              <tr>
                                <td className="border border-customGray px-2 py-2 text-center">
                                  <p className="font-semibold">I</p>
                                </td>
                                <td className="border border-customGray px-2 py-2 text-center">
                                  <p className="font-semibold">II</p>
                                </td>
                                <td className="border border-customGray px-2 py-2 text-center">
                                  <p className="font-semibold">III</p>
                                </td>
                                <td className="border border-customGray px-2 py-2 text-center">
                                  <p className="font-semibold">VI</p>
                                </td>
                              </tr>
                              {dataKejadian?.map((data, index) => (
                                <tr key={data.kategori_kode}>
                                  <td className="border border-customGray px-2 py-2 text-center">
                                    {index + 1}.
                                  </td>
                                  <td className="border border-customGray px-2 py-2 text-left">
                                    {data.kategori_name}
                                  </td>
                                  <td className="border border-customGray px-2 py-2 text-center">
                                    {data.minggu1}
                                  </td>
                                  <td className="border border-customGray px-2 py-2 text-center">
                                    {data.minggu2}
                                  </td>
                                  <td className="border border-customGray px-2 py-2 text-center">
                                    {data.minggu3}
                                  </td>
                                  <td className="border border-customGray px-2 py-2 text-center">
                                    {data.minggu4}
                                  </td>
                                  <td className="border border-customGray px-2 py-2 text-center">
                                    {parseInt(data.minggu1) +
                                      parseInt(data.minggu2) +
                                      parseInt(data.minggu3) +
                                      parseInt(data.minggu4)}
                                  </td>
                                  <td className="border border-customGray px-2 py-2 text-left"></td>
                                </tr>
                              ))}
                              <tr>
                                <td
                                  colSpan="2"
                                  className="border border-customGray px-2 py-2 text-left">
                                  <p className="font-semibold">Total</p>
                                </td>
                                <td className="border border-customGray px-2 py-2 text-center">
                                  <p className="font-semibold">{totalMinggu1.kejadian}</p>
                                </td>
                                <td className="border border-customGray px-2 py-2 text-center">
                                  <p className="font-semibold">{totalMinggu2.kejadian}</p>
                                </td>
                                <td className="border border-customGray px-2 py-2 text-center">
                                  <p className="font-semibold">{totalMinggu3.kejadian}</p>
                                </td>
                                <td className="border border-customGray px-2 py-2 text-center">
                                  <p className="font-semibold">{totalMinggu4.kejadian}</p>
                                </td>
                                <td className="border border-customGray px-2 py-2 text-center">
                                  <p className="font-semibold">
                                    {totalMinggu1.kejadian +
                                      totalMinggu2.kejadian +
                                      totalMinggu3.kejadian +
                                      totalMinggu4.kejadian}
                                  </p>
                                </td>
                                <td className="border border-customGray px-2 py-2 text-left">
                                  <p className="font-semibold"></p>
                                </td>
                              </tr>
                            </tbody>
                          </table>

                          {/* Laporan Temuan */}
                          <table className="table-auto border-gray-50 text-sm mx-auto w-full">
                            <thead className="bg-primary font-bold text-gray-100">
                              <tr>
                                <th
                                  colSpan="8"
                                  className="border border-customGray px-2 py-2 text-left whitespace-nowrap">
                                  <p className="font-semibold">C. LAPORAN TEMUAN</p>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {/* Tabel Temuan */}
                              <tr>
                                <td
                                  rowSpan="2"
                                  className="border border-customGray px-2 py-2 text-center whitespace-nowrap">
                                  <p className="font-semibold">No</p>
                                </td>
                                <td
                                  rowSpan="2"
                                  className="w-1/2 border border-customGray px-2 py-2 whitespace-nowrap">
                                  <p className="font-semibold">Kategori Temuan</p>
                                </td>
                                <td
                                  colSpan="4"
                                  className="border border-customGray px-2 py-2 text-center whitespace-nowrap">
                                  <p className="font-semibold">Minggu Ke-</p>
                                </td>
                                <td
                                  rowSpan="2"
                                  className="border border-customGray px-2 py-2 text-center whitespace-nowrap">
                                  <p className="font-semibold">Total</p>
                                </td>
                                <td
                                  rowSpan="2"
                                  className="w-1/2 border border-customGray px-2 py-2 text-center whitespace-nowrap">
                                  <p className="font-semibold">Keterangan</p>
                                </td>
                              </tr>
                              <tr>
                                <td className="border border-customGray px-2 py-2 text-center">
                                  <p className="font-semibold">I</p>
                                </td>
                                <td className="border border-customGray px-2 py-2 text-center">
                                  <p className="font-semibold">II</p>
                                </td>
                                <td className="border border-customGray px-2 py-2 text-center">
                                  <p className="font-semibold">III</p>
                                </td>
                                <td className="border border-customGray px-2 py-2 text-center">
                                  <p className="font-semibold">VI</p>
                                </td>
                              </tr>
                              {dataTemuan?.map((data, index) => (
                                <tr key={data.kategori_kode}>
                                  <td className="border border-customGray px-2 py-2 text-center">
                                    {index + 1}.
                                  </td>
                                  <td className="border border-customGray px-2 py-2 text-left">
                                    {data.kategori_name}
                                  </td>
                                  <td className="border border-customGray px-2 py-2 text-center">
                                    {data.minggu1}
                                  </td>
                                  <td className="border border-customGray px-2 py-2 text-center">
                                    {data.minggu2}
                                  </td>
                                  <td className="border border-customGray px-2 py-2 text-center">
                                    {data.minggu3}
                                  </td>
                                  <td className="border border-customGray px-2 py-2 text-center">
                                    {data.minggu4}
                                  </td>
                                  <td className="border border-customGray px-2 py-2 text-center">
                                    {parseInt(data.minggu1) +
                                      parseInt(data.minggu2) +
                                      parseInt(data.minggu3) +
                                      parseInt(data.minggu4)}
                                  </td>
                                  <td className="border border-customGray px-2 py-2 text-left"></td>
                                </tr>
                              ))}
                              <tr>
                                <td
                                  colSpan="2"
                                  className="border border-customGray px-2 py-2 text-left">
                                  <p className="font-semibold">Total</p>
                                </td>
                                <td className="border border-customGray px-2 py-2 text-center">
                                  <p className="font-semibold">{totalMinggu1.temuan}</p>
                                </td>
                                <td className="border border-customGray px-2 py-2 text-center">
                                  <p className="font-semibold">{totalMinggu2.temuan}</p>
                                </td>
                                <td className="border border-customGray px-2 py-2 text-center">
                                  <p className="font-semibold">{totalMinggu3.temuan}</p>
                                </td>
                                <td className="border border-customGray px-2 py-2 text-center">
                                  <p className="font-semibold">{totalMinggu4.temuan}</p>
                                </td>
                                <td className="border border-customGray px-2 py-2 text-center">
                                  <p className="font-semibold">
                                    {totalMinggu1.temuan +
                                      totalMinggu2.temuan +
                                      totalMinggu3.temuan +
                                      totalMinggu4.temuan}
                                  </p>
                                </td>
                                <td className="border border-customGray px-2 py-2 text-left">
                                  <p className="font-semibold"></p>
                                </td>
                              </tr>
                            </tbody>
                          </table>

                          {/* <table className="table-auto border-gray-50 text-sm mx-auto w-full">
                            <thead className="bg-primary font-bold text-gray-100">
                              <tr>
                                <th
                                  colSpan="8"
                                  className="border border-customGray px-2 py-2 text-left whitespace-nowrap">
                                  <p className="font-semibold">B. LAPORAN KELUHAN</p>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td
                                  rowSpan="2"
                                  className="border border-customGray px-2 py-2 text-center whitespace-nowrap">
                                  <p className="font-semibold">No</p>
                                </td>
                                <td
                                  rowSpan="2"
                                  className="w-1/2 border border-customGray px-2 py-2 whitespace-nowrap">
                                  <p className="font-semibold">Kategori Kejadian</p>
                                </td>
                                <td
                                  colSpan="4"
                                  className="border border-customGray px-2 py-2 text-center whitespace-nowrap">
                                  <p className="font-semibold">Minggu Ke-</p>
                                </td>
                                <td
                                  rowSpan="2"
                                  className="border border-customGray px-2 py-2 text-center whitespace-nowrap">
                                  <p className="font-semibold">Total</p>
                                </td>
                                <td
                                  rowSpan="2"
                                  className="w-1/2 border border-customGray px-2 py-2 text-center whitespace-nowrap">
                                  <p className="font-semibold">Keterangan</p>
                                </td>
                              </tr>
                              <tr>
                                <td className="border border-customGray px-2 py-2 text-center">
                                  <p className="font-semibold">I</p>
                                </td>
                                <td className="border border-customGray px-2 py-2 text-center">
                                  <p className="font-semibold">II</p>
                                </td>
                                <td className="border border-customGray px-2 py-2 text-center">
                                  <p className="font-semibold">III</p>
                                </td>
                                <td className="border border-customGray px-2 py-2 text-center">
                                  <p className="font-semibold">VI</p>
                                </td>
                              </tr>
                              {dataKeluhan?.map((kejadian, index) => (
                                <tr key={kejadian.satpamkeluhankategori}>
                                  <td className="border border-customGray px-2 py-2 text-center">
                                    {index + 1}.
                                  </td>
                                  <td className="border border-customGray px-2 py-2 text-left">
                                    {kejadian.satpamkeluhankategori}
                                  </td>
                                  <td className="border border-customGray px-2 py-2 text-center">
                                    {kejadian.minggu1}
                                  </td>
                                  <td className="border border-customGray px-2 py-2 text-center">
                                    {kejadian.minggu2}
                                  </td>
                                  <td className="border border-customGray px-2 py-2 text-center">
                                    {kejadian.minggu3}
                                  </td>
                                  <td className="border border-customGray px-2 py-2 text-center">
                                    {kejadian.minggu4}
                                  </td>
                                  <td className="border border-customGray px-2 py-2 text-center">
                                    {parseInt(kejadian.minggu1) +
                                      parseInt(kejadian.minggu2) +
                                      parseInt(kejadian.minggu3) +
                                      parseInt(kejadian.minggu4)}
                                  </td>
                                  <td className="border border-customGray px-2 py-2 text-left"></td>
                                </tr>
                              ))}
                              <tr>
                                <td
                                  colSpan="2"
                                  className="border border-customGray px-2 py-2 text-left">
                                  <p className="font-semibold">Total</p>
                                </td>
                                <td className="border border-customGray px-2 py-2 text-center">
                                  <p className="font-semibold">{totalMinggu1.keluhan}</p>
                                </td>
                                <td className="border border-customGray px-2 py-2 text-center">
                                  <p className="font-semibold">{totalMinggu2.keluhan}</p>
                                </td>
                                <td className="border border-customGray px-2 py-2 text-center">
                                  <p className="font-semibold">{totalMinggu3.keluhan}</p>
                                </td>
                                <td className="border border-customGray px-2 py-2 text-center">
                                  <p className="font-semibold">{totalMinggu4.keluhan}</p>
                                </td>
                                <td className="border border-customGray px-2 py-2 text-center">
                                  <p className="font-semibold">
                                    {totalMinggu1.keluhan +
                                      totalMinggu2.keluhan +
                                      totalMinggu3.keluhan +
                                      totalMinggu4.keluhan}
                                  </p>
                                </td>
                                <td className="border border-customGray px-2 py-2 text-left">
                                  <p className="font-semibold"></p>
                                </td>
                              </tr>
                            </tbody>
                          </table> */}
                          <table className="table-auto border-gray-50 text-sm mx-auto w-full">
                            <thead className="bg-primary font-bold text-gray-100">
                              <tr>
                                <th
                                  colSpan="8"
                                  className="border border-customGray px-2 py-2 text-left whitespace-nowrap">
                                  <p className="font-semibold">D. PERSONIL</p>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td
                                  rowSpan="2"
                                  className="border border-customGray px-2 py-2 text-center whitespace-nowrap">
                                  <p className="font-semibold">No</p>
                                </td>
                                <td
                                  rowSpan="2"
                                  className="w-1/2 border border-customGray px-2 py-2 whitespace-nowrap">
                                  <p className="font-semibold">Nama Personil</p>
                                </td>
                                <td
                                  colSpan="4"
                                  className="border border-customGray px-2 py-2 text-center whitespace-nowrap">
                                  <p className="font-semibold">Status</p>
                                </td>
                                <td
                                  rowSpan="2"
                                  className="border border-customGray px-2 py-2 text-center whitespace-nowrap">
                                  <p className="font-semibold">Total</p>
                                </td>
                                <td
                                  rowSpan="2"
                                  className="w-1/2 border border-customGray px-2 py-2 text-center whitespace-nowrap">
                                  <p className="font-semibold">Keterangan</p>
                                </td>
                              </tr>
                              <tr>
                                <td className="border border-customGray px-2 py-2 text-center">
                                  <p className="font-semibold">M</p>
                                </td>
                                <td className="border border-customGray px-2 py-2 text-center">
                                  <p className="font-semibold">S</p>
                                </td>
                                <td className="border border-customGray px-2 py-2 text-center">
                                  <p className="font-semibold">I</p>
                                </td>
                                <td className="border border-customGray px-2 py-2 text-center">
                                  <p className="font-semibold">A</p>
                                </td>
                              </tr>
                              {dataPersonil?.map((personil, index) => (
                                <tr key={personil.satpamnamalengkap}>
                                  <td className="border border-customGray px-2 py-2 text-center">
                                    {index + 1}.
                                  </td>
                                  <td className="border border-customGray px-2 py-2 text-left">
                                    <p className="font-semibold">{personil.satpamnamalengkap}</p>
                                    <p>{personil.satpamjabatan}</p>
                                  </td>
                                  <td className="border border-customGray px-2 py-2 text-center">
                                    {personil.sudah_absen}
                                  </td>
                                  <td className="border border-customGray px-2 py-2 text-center">
                                    {personil.izin}
                                  </td>
                                  <td className="border border-customGray px-2 py-2 text-center">
                                    {personil.sakit}
                                  </td>
                                  <td className="border border-customGray px-2 py-2 text-center">
                                    {personil.alfa}
                                  </td>
                                  <td className="border border-customGray px-2 py-2 text-center">
                                    {parseInt(personil.sudah_absen) +
                                      parseInt(personil.izin) +
                                      parseInt(personil.sakit) +
                                      parseInt(personil.alfa)}
                                  </td>
                                  <td className="border border-customGray px-2 py-2 text-left"></td>
                                </tr>
                              ))}
                              <tr>
                                <td
                                  colSpan="2"
                                  className="border border-customGray px-2 py-2 text-left">
                                  <p className="font-semibold">Total</p>
                                </td>
                                <td className="border border-customGray px-2 py-2 text-center">
                                  <p className="font-semibold">{totalMinggu1.personil}</p>
                                </td>
                                <td className="border border-customGray px-2 py-2 text-center">
                                  <p className="font-semibold">{totalMinggu2.personil}</p>
                                </td>
                                <td className="border border-customGray px-2 py-2 text-center">
                                  <p className="font-semibold">{totalMinggu3.personil}</p>
                                </td>
                                <td className="border border-customGray px-2 py-2 text-center">
                                  <p className="font-semibold">{totalMinggu4.personil}</p>
                                </td>
                                <td className="border border-customGray px-2 py-2 text-center">
                                  <p className="font-semibold">
                                    {totalMinggu1.personil +
                                      totalMinggu2.personil +
                                      totalMinggu3.personil +
                                      totalMinggu4.personil}
                                  </p>
                                </td>
                                <td className="border border-customGray px-2 py-2 text-left">
                                  <p className="font-semibold"></p>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <div className="page-break" />
                          {/* Input Analisis */}
                          <div className="flex md:flex-row flex-col gap-2 text-sm mt-10">
                            <div className="md:w-1/2 w-full p-2 flex flex-col justify-around">
                              <div className="md:flex mb-3">
                                <div className="md:w-1/3">
                                  <label className="block font-semibold mb-1 md:mb-0">
                                    Potensi
                                  </label>
                                </div>
                                <div className="md:w-2/3">
                                  <textarea
                                    className={`w-full inputForm`}
                                    rows={8}
                                    disabled={adminType !== 'Admin Project' ? true : false}
                                  />
                                  <p className="text-primary text-xs"></p>
                                </div>
                              </div>
                              <div className="md:flex">
                                <div className="md:w-1/3">
                                  <label className="block font-semibold mb-1 md:mb-0">
                                    Analisis
                                  </label>
                                </div>
                                <div className="md:w-2/3">
                                  <textarea
                                    className={`w-full inputForm`}
                                    rows={8}
                                    disabled={adminType !== 'Admin Project' ? true : false}
                                  />
                                  <p className="text-primary text-xs"></p>
                                </div>
                              </div>
                            </div>
                            <div className="md:w-1/2 w-full p-2 flex flex-col justify-around">
                              <div className="md:flex mb-3">
                                <div className="md:w-1/3">
                                  <label className="block font-semibold mb-1 md:mb-0">
                                    Kesimpulan
                                  </label>
                                </div>
                                <div className="md:w-2/3">
                                  <textarea
                                    className={`w-full inputForm`}
                                    rows={8}
                                    disabled={adminType !== 'Admin Project' ? true : false}
                                  />
                                </div>
                              </div>
                              <div className="md:flex mb-3">
                                <div className="md:w-1/3">
                                  <label className="block font-semibold mb-1 md:mb-0">
                                    Saran dan Rekomendasi
                                  </label>
                                </div>
                                <div className="md:w-2/3">
                                  <textarea
                                    className={`w-full inputForm`}
                                    rows={8}
                                    disabled={adminType !== 'Admin Project' ? true : false}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
