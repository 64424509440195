import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ClipLoader from 'react-spinners/ClipLoader';
import { login } from '../../redux/features/authSlice';

const initialState = {
  adminpetugasusername: '',
  password: ''
};

export default function Login() {
  const { error, loading, errorMessage } = useSelector((state) => ({ ...state.auth }));
  const [formValue, setFormValue] = useState(initialState);
  const [showPassword, setShowPassword] = useState(false);
  const { adminpetugasusername, password } = formValue;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    if (adminpetugasusername && password) dispatch(login({ formValue, navigate }));
  };
  const handleChange = (e) => {
    let { name, value } = e.target;
    setFormValue({ ...formValue, [name]: value });
  };

  return (
    <div className="h-screen md:flex bg-gray-100">
      <div className="relative overflow-hidden md:flex w-1/2 bgGradientGAP justify-around items-center hidden">
        <div className="flex flex-col items-center md:p-2 p-0 text-sm">
          <img
            src={require('../../images/logo.png')}
            alt=""
            className="hidden md:block h-60 mb-3"
          />
          <h4 className="text-center uppercase text-4xl font-bold md:-mt-2 text-gray-100">GAPS</h4>
          <h6 className="text-center text-lg font-semibold text-customGray">
            Gapara Asset Protection System
          </h6>
        </div>
      </div>
      <div className="flex flex-col md:w-1/2 justify-center py-5 items-center">
        <div className="md:hidden flex flex-col items-center md:p-2 p-0 text-sm mb-8">
          <img src={require('../../images/logo.png')} alt="" className="h-32 mb-3" />
          <h4 className="text-center uppercase text-2xl font-bold -mt-2 text-darkGray">GAPS</h4>
          <h6 className="text-center text-lg font-semibold text-gray-500">
            Gapara Asset Protection System
          </h6>
        </div>
        <h1 className="text-darkGray font-bold text-lg md:text-2xl mb-5">Welcome back!</h1>
        <form onSubmit={handleSubmit}>
          {error && !loading && (
            <div className="bg-primary text-white h-auto p-2 mb-2 rounded-md text-[11px] text-center sm:text-base">
              {errorMessage === 'Unauthorized'
                ? 'Username or Password is wrong'
                : 'Something went wrong. Please try again'}
            </div>
          )}
          <div className="flex flex-col gap-1 mb-3">
            <label htmlFor="email">Email</label>
            <input
              className="inputForm w-full md:w-72 rounded py-2 px-3"
              id="email"
              type="email"
              placeholder="Email"
              value={adminpetugasusername}
              name="adminpetugasusername"
              onChange={handleChange}
            />
          </div>
          <div className="flex flex-col gap-1 mb-5">
            <label htmlFor="password">Password</label>
            <div className="relative">
              <input
                className="inputForm w-full md:w-72 rounded py-2 px-3"
                type={showPassword ? 'text' : 'password'}
                placeholder="Password"
                value={password}
                name="password"
                onChange={handleChange}
              />
              {showPassword && (
                <i
                  className="fas fa-eye cursor-pointer absolute top-[30%] right-3"
                  onClick={() => setShowPassword(!showPassword)}></i>
              )}
              {!showPassword && (
                <i
                  className="fas fa-eye-slash cursor-pointer absolute top-[30%] right-3"
                  onClick={() => setShowPassword(!showPassword)}></i>
              )}
            </div>
            <button className="bgGradientGAP hoverGradientGAP text-gray-100 font-semibold py-2 w-full rounded flex items-center justify-center">
              {loading && (
                <ClipLoader color="white" loading={true} size={20} speedMultiplier={0.7} />
              )}
              &nbsp; LOGIN
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
