import React, { useState, useEffect } from 'react';
import * as api from '../../redux/api';
import Swal from 'sweetalert2';
import { Link } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { checkpointSchema } from '../../utils/schemaValidation';
import useImagePreview from '../../hooks/useImagePreview';
import imageCompression from 'browser-image-compression';
import { validateLatitude, validateLongitude } from '../../utils/latLongValidation';

export default function CheckpointCreate() {
  const [loading, setLoading] = useState(false);
  const [posOptions, setPosOptions] = useState([]);
  const [latitudeValidation, setLatitudeValidation] = useState({ error: { status: false } });
  const [longitudeValidation, setLongitudeValidation] = useState({ error: { status: false } });

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    reset
  } = useForm({
    resolver: yupResolver(checkpointSchema)
  });

  const [fotoPOSUpload] = useImagePreview(watch('satpamtitikfoto'));

  const compressOptions = {
    maxSizeMB: 1,
    maxWidthOrHeight: 1920,
    useWebWorker: true
  };

  useEffect(() => {
    const getDataPOS = async () => {
      await api.patroliGetPOS().then((res) => {
        res.data.data.map((data) => {
          return setPosOptions((prev) => [
            ...prev,
            { value: data.satpamposkode, label: data.satpamposnama }
          ]);
        });
      });
    };

    getDataPOS();
  }, []);

  const onSubmit = async (data) => {
    setLoading(true);
    const formData = new FormData();

    if (data.satpamtitikfoto.length > 0) {
      const compressedFile = await imageCompression(data.satpamtitikfoto[0], compressOptions);
      formData.append('satpamtitikfoto', compressedFile, compressedFile.name);
    }

    for (const key in data) {
      if (key === 'field') {
        formData.append(key, data[key][1]);
      } else {
        formData.append(key, data[key]);
      }
    }

    if (!latitudeValidation.error.status && !longitudeValidation.error.status) {
      await api
        .createCheckpoint(formData)
        .then((res) => {
          Swal.fire('Good job!', 'You successfully submit the form!', 'success');
          setLoading(false);
          reset({ satpamtitiknama: '', satpamtitikketerangan: '' });
        })
        .catch((error) => {
          setLoading(false);
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: error.response.data.message
          });
        });
    } else {
      setLoading(false);
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Latitude dan/atau Longitude tidak valid'
      });
    }
  };

  const handleValidateLatLong = (e) => {
    const { name, value } = e.target;
    if (name === 'satpamtitiklatitude') {
      setLatitudeValidation(validateLatitude(value));
    }
    if (name === 'satpamtitiklongitude') {
      setLongitudeValidation(validateLongitude(value));
    }
  };

  return (
    <div className="relative px-4 md:px-10 mx-auto w-full -mt-8 overflow-x-hidden scrollbar-thin scrollbar-track-gray-100 scrollbar-thumb-gray-300">
      <div className="w-full min-h-screen">
        <div className="w-full py-3 bg-gray-50 rounded-lg shadow-black/40 shadow-lg flex justify-center items-center mb-10 text-center text-lg font-semibold">
          Tambah Checkpoint
        </div>
        <form
          onSubmit={handleSubmit(onSubmit)}
          encType="multipart/form-data"
          className="w-full flex flex-col gap-5">
          <div className="flex flex-wrap lg:flex-nowrap text-sm gap-5">
            <div className="w-full flex flex-col gap-5">
              <div className=" bg-gray-50 p-5 rounded-lg shadowAll flex flex-col justify-around">
                <div className="md:flex mb-3 items-center">
                  <div className="md:w-1/3">
                    <label className="block font-semibold mb-1 md:mb-0">POS</label>
                  </div>
                  <div className="md:w-2/3">
                    <select
                      className={`w-full ${
                        errors.satpamtitiksatpamposkode ? 'invalidForm' : 'inputForm'
                      }`}
                      {...register('satpamtitiksatpamposkode')}>
                      <option value="">Pilih POS</option>
                      {posOptions.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                    <p className="text-errorText text-xs">
                      {errors.satpamtitiksatpamposkode?.message}
                    </p>
                  </div>
                </div>
                <div className="md:flex mb-3 items-center">
                  <div className="md:w-1/3">
                    <label className="block font-semibold mb-1 md:mb-0">Nama Checkpoint</label>
                  </div>
                  <div className="md:w-2/3">
                    <input
                      type="text"
                      className={`w-full ${errors.satpamtitiknama ? 'invalidForm' : 'inputForm'}`}
                      {...register('satpamtitiknama')}
                    />
                    <p className="text-errorText text-xs">{errors.satpamtitiknama?.message}</p>
                  </div>
                </div>
                <div className="md:flex">
                  <div className="md:w-1/3">
                    <label className="block font-semibold mb-1 md:mb-0">Keterangan</label>
                  </div>
                  <div className="md:w-2/3">
                    <textarea
                      rows={4}
                      className={`w-full ${
                        errors.satpamtitikketerangan ? 'invalidForm' : 'inputForm'
                      }`}
                      {...register('satpamtitikketerangan')}
                    />
                    <p className="text-errorText text-xs">
                      {errors.satpamtitikketerangan?.message}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full bg-gray-50 p-5 rounded-lg shadowAll flex flex-col justify-around">
              <div className="md:flex mb-3">
                <div className="md:w-1/3">
                  <label className="block font-semibold mb-1 md:mb-0">Foto Checkpoint</label>
                </div>
                <div className="md:w-2/3">
                  <div className="flex w-full">
                    <div className="w-full">
                      <div className="h-auto p-3 bg-white rounded-md border border-gray-200 shadow-md">
                        <div className="flex flex-col w-full">
                          {/* <div className="text-sm">Pilih foto</div> */}
                          <label className="p-2 flex flex-col w-full h-40 items-center justify-center border-4 border-dashed hover:bg-gray-100 hover:border-gray-300">
                            {fotoPOSUpload ? (
                              <div className="flex flex-col items-center justify-center">
                                <img
                                  src={fotoPOSUpload}
                                  className="h-36 w-full object-contain"
                                  alt="preview"
                                />
                              </div>
                            ) : (
                              <div className="flex flex-col items-center justify-center pt-7">
                                {/* <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="w-12 h-12 text-gray-400 group-hover:text-gray-600"
                                  viewBox="0 0 20 20"
                                  fill="currentColor">
                                  <path
                                    fillRule="evenodd"
                                    d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z"
                                    clipRule="evenodd"
                                  />
                                </svg> */}
                                <p className="pt-1 text-sm tracking-wider text-gray-400 group-hover:text-gray-600">
                                  Select a photo
                                </p>
                              </div>
                            )}
                            <input
                              type="file"
                              className="opacity-0"
                              {...register('satpamtitikfoto', {
                                required: true
                              })}
                              accept="image/*"
                            />
                          </label>
                          <p className="text-errorText text-xs">
                            {errors.satpamtitikfoto?.message}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="md:flex mb-3 items-center">
                <div className="md:w-1/3">
                  <label className="block font-semibold mb-1 md:mb-0">Radius</label>
                </div>
                <div className="md:w-2/3">
                  <input
                    type="number"
                    className={`w-full ${errors.satpamtitikradius ? 'invalidForm' : 'inputForm'}`}
                    {...register('satpamtitikradius')}
                  />
                  <p className="text-errorText text-xs">{errors.satpamtitikradius?.message}</p>
                </div>
              </div>

              <div className="md:flex mb-3 items-center">
                <div className="md:w-1/3">
                  <label className="block font-semibold mb-1 md:mb-0">Latitude</label>
                </div>
                <div className="md:w-2/3">
                  <input
                    type="text"
                    className={`w-full ${errors.satpamtitiklatitude ? 'invalidForm' : 'inputForm'}`}
                    {...register('satpamtitiklatitude')}
                    onKeyUp={handleValidateLatLong}
                  />
                  <p className="text-errorText text-xs">{errors.satpamtitiklatitude?.message}</p>
                  <p className="text-errorText text-xs">{latitudeValidation?.error?.message}</p>
                </div>
              </div>
              <div className="md:flex items-center">
                <div className="md:w-1/3">
                  <label className="block font-semibold mb-1 md:mb-0">Longitude</label>
                </div>
                <div className="md:w-2/3">
                  <input
                    type="text"
                    className={`w-full ${
                      errors.satpamtitiklongitude ? 'invalidForm' : 'inputForm'
                    }`}
                    {...register('satpamtitiklongitude')}
                    onKeyUp={handleValidateLatLong}
                  />
                  <p className="text-errorText text-xs">{errors.satpamtitiklongitude?.message}</p>
                  <p className="text-errorText text-xs">{longitudeValidation?.error?.message}</p>
                </div>
              </div>
            </div>
          </div>

          <div className="flex justify-between items-center w-full py-5 px-10 shadowAll rounded-lg">
            <button
              type="submit"
              className="text-gray-50 py-2 px-5 rounded-md text-sm bg-dark hover:opacity-80"
              disabled={loading === true ? true : false}>
              {loading ? (
                <ClipLoader color="white" loading={true} size={20} speedMultiplier={0.7} />
              ) : (
                'Simpan'
              )}
            </button>

            <Link to="/checkpoint">
              <button className="btn text-gray-50 py-2 px-5 rounded-md text-sm bg-dark hover:opacity-80">
                Kembali
              </button>
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
}
