import DatePicker from 'react-datepicker';
import id from 'date-fns/locale/id';
import moment from 'moment';
import Swal from 'sweetalert2';
import { ClipLoader } from 'react-spinners';
import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
// Import Files
import * as api from '../../redux/api';

export default function PatroliCreate() {
  const [errorTanggal, setErrorTanggal] = useState([{ message: '' }]);
  const [jamJadwal, setJamJadwal] = useState({ masuk: '', keluar: '' });
  const [loading, setLoading] = useState(false);
  const [posOptions, setPosOptions] = useState([]);
  const [ruteFields, setRuteFields] = useState([]);
  const [ruteOptions, setRuteOptions] = useState([]);
  const [satpamDataOptions, setSatpamDataOptions] = useState([]);
  const [tanggalJadwal, setTanggalJadwal] = useState([{ masuk: '', keluar: '' }]);
  const [searchSatpam, setSearchSatpam] = useState('');
  const [satpamSelected, setSatpamSelected] = useState([]);

  const initialFormValue = {
    satpampatrolisatpamkode: '',
    satpampatrolijenis: '',
    satpampatrolinama: '',
    satpampatrolisatpamposkode: '',
    satpampatrolirute: ''
  };

  const [formValue, setFormValue] = useState(initialFormValue);

  useEffect(() => {
    const getDataSatpam = async () => {
      await api.satpamData().then((res) => {
        res.data.data
          .filter((item) => item.satpamstatus === 'Aktif')
          .map((data) => {
            return setSatpamDataOptions((prev) => [
              ...prev,
              { value: data.satpamkode, label: data.satpamnamalengkap }
            ]);
          });
      });
    };
    const getDataPOS = async () => {
      await api.patroliGetPOS().then((res) => {
        res.data.data.map((data) => {
          return setPosOptions((prev) => [
            ...prev,
            { value: data.satpamposkode, label: data.satpamposnama }
          ]);
        });
      });
    };

    getDataPOS();
    getDataSatpam();
  }, []);

  useEffect(() => {
    const masuk = new Date();
    const keluar = new Date();

    masuk.setHours(0);
    masuk.setMinutes(0);
    masuk.setSeconds(0);
    keluar.setHours(23);
    keluar.setMinutes(59);
    keluar.setSeconds(59);
    setJamJadwal({ masuk, keluar });
  }, []);

  useEffect(() => {
    if (formValue.satpampatrolijenis === 'Patroli Jalan Kaki') {
      setFormValue((prev) => ({
        ...prev,
        satpampatrolinama: 'Patroli Jalan Kaki'
      }));
    } else {
      setFormValue((prev) => ({
        ...prev,
        satpampatrolinama: 'Patroli Berkendara'
      }));
    }
  }, [formValue.satpampatrolijenis]);

  useEffect(() => {
    setFormValue((prev) => ({
      ...prev,
      satpampatrolirute: ruteFields
    }));
  }, [ruteFields]);

  const handleSatpamSelected = (e) => {
    if (e.target.checked) {
      setSatpamSelected([...satpamSelected, { satpamkode: e.target.value }]);
    } else {
      setSatpamSelected(
        satpamSelected?.filter((personil) => personil.satpamkode !== e.target.value)
      );
    }
  };

  const selectAll = () => {
    setSatpamSelected([]);
    filteredSatpamOptions.map((option) => {
      return setSatpamSelected((prev) => [...prev, { satpamkode: option.value }]);
    });
  };

  const filteredSatpamOptions = satpamDataOptions.filter((personil) => {
    return personil.label.toLowerCase().includes(searchSatpam.toLowerCase());
  });

  const addTanggalJadwal = () => {
    setTanggalJadwal([...tanggalJadwal, { masuk: '', keluar: '' }]);
    setErrorTanggal([...errorTanggal, { message: '' }]);
  };

  const removeTanggalJadwal = (indexJadwal) => {
    if (indexJadwal > -1) {
      // Hapus tanggal by index
      const removedTanggalJadwal = tanggalJadwal.splice(indexJadwal, 1);

      const removeTanggal = tanggalJadwal.filter(
        (tanggalfilter) =>
          tanggalfilter.masuk !==
            removedTanggalJadwal.map((removedtanggal) => removedtanggal.masuk) &&
          tanggalfilter.keluar !==
            removedTanggalJadwal.map((removedtanggal) => removedtanggal.keluar)
      );
      setTanggalJadwal(removeTanggal);

      // Hapus error by tanggal
      const newErrorTanggal = [...errorTanggal];
      let valueToRemove = [newErrorTanggal[indexJadwal]];
      const newErrorTanggalFilter = newErrorTanggal.filter(
        (element) => !valueToRemove.includes(element)
      );
      setErrorTanggal(newErrorTanggalFilter);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setFormValue({ ...formValue, [name]: value });

    if (name === 'satpampatrolisatpamposkode') {
      setRuteOptions([]);
      setRuteFields([]);
      if (value !== '') {
        const getDataRute = async () => {
          await api.patroliGetRute(value).then((res) => {
            res.data.data.map((data) => {
              setRuteFields((prev) => [...prev, { rute: data.satpamtitikkode, target: 1 }]);
              return setRuteOptions((prev) => [
                ...prev,
                { rute: data.satpamtitikkode, label: data.satpamtitiknama, target: 1 }
              ]);
            });
          });
        };
        getDataRute();
      }
    }
  };

  const handleRute = (e) => {
    if (e.target.name === 'koderute') {
      if (e.target.checked) {
        setRuteFields([...ruteFields, { rute: e.target.value, target: 1 }]);
      } else {
        setRuteFields(ruteFields.filter((rute) => rute.rute !== e.target.value));
      }
    } else {
      let newRuteFields = [...ruteFields];
      const indexRute = ruteFields.findIndex((field) => field.rute === e.target.name);
      newRuteFields[indexRute] = { ...newRuteFields[indexRute], target: parseInt(e.target.value) };

      setRuteFields(newRuteFields);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const jamJadwalMasukFormatted = moment(jamJadwal.masuk).format('HH:mm');
    const jamJadwalKeluarFormatted = moment(jamJadwal.keluar).format('HH:mm');

    if (tanggalJadwal.length > 0) {
      tanggalJadwal.map(async (jadwal, index) => {
        let tanggalMasukFormatted = moment(jadwal.masuk).format('YYYY-MM-DD');
        let tanggalKeluarFormatted = moment(jadwal.keluar).format('YYYY-MM-DD');

        if (tanggalMasukFormatted > tanggalKeluarFormatted) {
          setLoading(false);
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Tanggal masuk tidak boleh lebih besar dari tanggal keluar!'
          });
        } else {
          if (satpamSelected.length > 0) {
            satpamSelected.map(async (satpam) => {
              formValue.satpampatrolisatpamkode = satpam.satpamkode;
              formValue.satpampatrolimulaitanggal =
                tanggalMasukFormatted + ' ' + jamJadwalMasukFormatted;
              formValue.satpampatroliselesaitanggal =
                tanggalKeluarFormatted + ' ' + jamJadwalKeluarFormatted;

              await api
                .createPatroli(formValue)
                .then((res) => {
                  if (index === tanggalJadwal.length - 1) {
                    Swal.fire('Good job!', 'You successfully submit the form!', 'success');
                    setTanggalJadwal([{ masuk: '', keluar: '' }]);
                    setLoading(false);
                  }
                })
                .catch((error) => {
                  if (index === tanggalJadwal.length - 1) {
                    setLoading(false);
                    Swal.fire({
                      icon: 'error',
                      title: 'Oops...',
                      text: error.response.data.message
                    });
                  }
                });
            });
          }
        }
      });
    } else {
      setLoading(false);
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Belum memasukkan tanggal'
      });
    }
  };

  return (
    <div className="relative px-4 md:px-10 mx-auto w-full -mt-8 overflow-x-hidden scrollbar-thin scrollbar-track-gray-100 scrollbar-thumb-gray-300">
      <div className="w-full min-h-screen">
        <div className="w-full py-3 bg-light rounded-lg shadow-black/40 shadow-lg flex justify-center items-center mb-5 text-center text-lg font-semibold">
          Tambah Patroli
        </div>
        <form
          onSubmit={handleSubmit}
          encType="multipart/form-data"
          className="w-full flex flex-col gap-5">
          <div className="flex flex-wrap lg:flex-nowrap text-sm gap-5">
            {/* Left Side */}
            <div className="w-full flex flex-col gap-5">
              <div className=" bg-light p-5 rounded-lg shadowAll flex flex-col justify-around">
                {/* Pos Patroli */}
                <div className="md:flex items-center mb-3">
                  <div className="md:w-1/3">
                    <label className="block font-semibold mb-1 md:mb-0">Pilih POS</label>
                  </div>
                  <div className="md:w-2/3">
                    <select
                      className="w-full inputForm"
                      name="satpampatrolisatpamposkode"
                      value={formValue.satpampatrolisatpamposkode}
                      onChange={handleInputChange}>
                      <option value="" disabled>
                        Pilih POS patroli
                      </option>
                      {posOptions.map((option, index) => (
                        <option key={index} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                {/* Jenis Patroli */}
                <div className="md:flex items-center mb-3">
                  <div className="md:w-1/3">
                    <label className="block font-semibold mb-1 md:mb-0">Jenis Patroli</label>
                  </div>
                  <div className="md:w-2/3">
                    <select
                      className="w-full inputForm"
                      name="satpampatrolijenis"
                      value={formValue.satpampatrolijenis}
                      onChange={handleInputChange}>
                      <option value="" disabled>
                        Pilih jenis patroli
                      </option>
                      <option value="Patroli Jalan Kaki">Patroli Jalan Kaki</option>
                      <option value="Patroli Berkendara">Patroli Berkendara</option>
                    </select>
                  </div>
                </div>

                {/* Nama Patroli */}
                <div className="md:flex items-center mb-3">
                  <div className="md:w-1/3">
                    <label className="block font-semibold mb-1 md:mb-0">Nama Patroli</label>
                  </div>
                  <div className="md:w-2/3">
                    <input
                      type="text"
                      className={`w-full inputForm`}
                      name="satpampatrolinama"
                      value={formValue.satpampatrolinama}
                      onChange={handleInputChange}
                      placeholder="Tulis nama patroli"
                      required
                    />
                  </div>
                </div>

                {/* Tanggal Patroli */}
                <div className="flex mb-1 items-center gap-2">
                  <div className="w-[45%]">
                    <label htmlFor="tanggal" className="block font-semibold mb-1 md:mb-0">
                      Tanggal masuk
                    </label>
                  </div>
                  <div className="w-[45%]">
                    <label htmlFor="tanggal" className="block font-semibold mb-1 md:mb-0">
                      Tanggal keluar
                    </label>
                  </div>
                </div>
                {tanggalJadwal.length > 0 &&
                  tanggalJadwal.map((tanggal, index) => (
                    <div key={index}>
                      <div className="flex mb-1 items-center gap-2">
                        <div className="w-[45%]">
                          <div className="relative block">
                            <DatePicker
                              selected={tanggal.masuk || tanggal.keluar}
                              className={`w-full text-sm cursor-pointer inputForm`}
                              excludeDates={[...tanggalJadwal.map((tgl) => tgl.masuk)]}
                              value={tanggal.masuk || tanggal.keluar}
                              onChange={(date) => {
                                const newTanggalJadwal = [...tanggalJadwal];
                                if (newTanggalJadwal[index].keluar === '') {
                                  newTanggalJadwal[index] = { masuk: date, keluar: date };
                                } else {
                                  newTanggalJadwal[index] = {
                                    masuk: date,
                                    keluar: newTanggalJadwal[index].keluar
                                  };
                                }
                                setTanggalJadwal(newTanggalJadwal);

                                const newErrorTanggal = [...errorTanggal];

                                if (newTanggalJadwal[index].keluar !== '') {
                                  if (date > newTanggalJadwal[index].keluar) {
                                    newErrorTanggal[index].message =
                                      'tanggal masuk tidak boleh lebih besar dari tanggal keluar';
                                  } else {
                                    newErrorTanggal[index].message = '';
                                  }
                                }

                                setErrorTanggal(newErrorTanggal);
                              }}
                              locale={id}
                              dateFormat="dd/MM/yyyy"
                              placeholderText="dd/MM/yyyy"
                              required
                            />
                            <div className="absolute top-1/2 transform -translate-y-1/2 right-3 pointer-events-none">
                              <i className="far fa-calendar"></i>
                            </div>
                          </div>
                        </div>
                        <div className="w-[45%]">
                          <div className="relative block">
                            <DatePicker
                              selected={tanggal.keluar || tanggal.masuk}
                              className={`w-full text-sm cursor-pointer inputForm`}
                              value={tanggal.keluar || tanggal.masuk}
                              excludeDates={[...tanggalJadwal.map((tgl) => tgl.masuk)]}
                              onChange={(date) => {
                                const newTanggalJadwal = [...tanggalJadwal];
                                if (newTanggalJadwal[index].masuk === '') {
                                  newTanggalJadwal[index] = { masuk: date, keluar: date };
                                } else {
                                  newTanggalJadwal[index] = {
                                    masuk: newTanggalJadwal[index].masuk,
                                    keluar: date
                                  };
                                }
                                setTanggalJadwal(newTanggalJadwal);

                                const newErrorTanggal = [...errorTanggal];

                                if (newTanggalJadwal[index].masuk !== '') {
                                  if (newTanggalJadwal[index].masuk > date) {
                                    newErrorTanggal[index].message =
                                      'tanggal masuk tidak boleh lebih besar dari tanggal keluar';
                                  } else {
                                    newErrorTanggal[index].message = '';
                                  }
                                }

                                setErrorTanggal(newErrorTanggal);
                              }}
                              locale={id}
                              dateFormat="dd/MM/yyyy"
                              placeholderText="dd/MM/yyyy"
                              required
                            />
                            <div className="absolute top-1/2 transform -translate-y-1/2 right-3 pointer-events-none">
                              <i className="far fa-calendar"></i>
                            </div>
                          </div>
                        </div>
                        <div className="w-[10%]">
                          <button
                            type="button"
                            onClick={() => removeTanggalJadwal(index)}
                            className={`block w-full btn text-gray-50 py-2 px-2 rounded-md text-sm bg-errorText hover:opacity-80`}>
                            <i className="fas fa-times-circle"></i>
                          </button>
                        </div>
                      </div>
                      <p className="text-errorText">{errorTanggal[index]?.message}</p>
                    </div>
                  ))}
                <button
                  type="button"
                  onClick={addTanggalJadwal}
                  className={`block w-full btn text-gray-50 py-2 px-2 rounded-md text-sm bg-dark hover:opacity-80`}>
                  <i className="fas fa-plus-circle"></i> Tambah Tanggal
                </button>
              </div>

              {/* Rute */}
              <div className="bg-light p-5 rounded-lg shadowAll">
                <div className="md:flex md:flex-col w-full">
                  <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 overflow-x-auto">
                    <div className="inline-block min-w-full shadow-md rounded-lg overflow-hidden">
                      <table className="min-w-full leading-normal">
                        <thead>
                          <tr>
                            <th className="w-[10%] px-2 py-1 border-b-2 border-gray-300 bg-gray-200 text-sm font-semibold text-center tracking-wider">
                              Aksi
                            </th>
                            <th className="w-[5%] px-2 py-1 border-b-2 align-middle border-gray-300 bg-gray-200 text-sm font-semibold text-center tracking-wider">
                              No
                            </th>
                            <th className="px-2 py-1 border-b-2 align-middle border-gray-300 bg-gray-200 text-sm font-semibold text-center tracking-wider">
                              Rute
                            </th>
                            <th className="w-[15%] px-2 py-1 border-b-2 align-middle border-gray-300 bg-gray-200 text-sm font-semibold text-center tracking-wider">
                              Target
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {ruteOptions.length > 0 ? (
                            ruteOptions.map((option, index) => (
                              <tr key={index}>
                                <td className="px-2 py-1 border-b-2 border-gray-200 text-sm text-center tracking-wider">
                                  <input
                                    type="checkbox"
                                    name="koderute"
                                    id={option.rute}
                                    defaultChecked={
                                      ruteFields.filter((field) => field.rute === option.rute)
                                        .length > 0
                                        ? true
                                        : false
                                    }
                                    value={option.rute}
                                    onChange={handleRute}
                                  />
                                </td>
                                <td className="px-2 py-1 border-b-2 align-middle border-gray-200 text-sm text-center tracking-wider">
                                  {index + 1}
                                </td>
                                <td className="px-2 py-1 border-b-2 align-middle border-gray-200 text-sm text-left tracking-wider">
                                  {option.label}
                                </td>
                                <td className="mx-2 px-1 py-1 border-b-2 align-middle border-gray-200 text-sm text-center tracking-wider">
                                  <input
                                    type="number"
                                    className={`w-full py-1 px-1 text-center flex flex-col inputForm`}
                                    name={option.rute}
                                    value={ruteFields
                                      .filter((field) => field.rute === option.rute)
                                      .map((value) => value.target)}
                                    onChange={handleRute}
                                  />
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td
                                colSpan="4"
                                className="px-2 py-1 border-b-2 border-gray-200 text-sm text-center tracking-wider">
                                Pilih pos terlebih dahulu
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Right Side */}
            <div className="w-full flex flex-col gap-5">
              {/* Personil */}
              <div className="bg-gray-50 p-5 rounded-lg shadowAll max-h-screen overflow-y-scroll scrollbar-thin">
                <div className="flex flex-col md:flex-row gap-2 mb-2 items-center">
                  <div className="md:flex md:flex-col w-full">
                    <div className="w-full">
                      <input
                        type="text"
                        className="w-full inputForm py-1.5"
                        placeholder="Cari nama"
                        value={searchSatpam}
                        onChange={(e) => setSearchSatpam(e.target.value)}
                      />
                    </div>
                    <div className="mt-2 sm:flex gap-2">
                      <div className="sm:w-1/2 mb-1 sm:mb-0">
                        <button
                          type="button"
                          onClick={selectAll}
                          className={`block w-full text-gray-50 py-2 px-2 rounded-md text-sm bg-dark hover:opacity-80`}>
                          <i className="fas fa-check mr-2"></i> Pilih semua
                        </button>
                      </div>
                      <div className="sm:w-1/2">
                        <button
                          type="button"
                          onClick={() => setSatpamSelected([])}
                          className={`block w-full text-gray-50 py-2 px-2 rounded-md text-sm ${
                            satpamSelected.length > 0 ? 'bg-errorText' : 'bg-customGray'
                          } hover:opacity-80`}>
                          <i className="fas fa-close mr-2"></i> Batal pilih
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col w-full">
                  <div className="inline-block min-w-full rounded-md overflow-auto scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100">
                    <table className="table-roster table-auto mx-auto w-full z-50">
                      <thead className="z-10 divide-y-2 divide-gray-400 rounded-md">
                        <tr>
                          <th className="w-[5%] bg-gray-300 font-bold text-sm py-1 whitespace-nowrap text-center border border-gray-300 border-t-0 border-l-0 align-middle ">
                            Aksi
                          </th>
                          <th className="bg-gray-300 w-1/3 font-bold text-sm py-1 whitespace-nowrap text-center border border-gray-300 border-t-0 border-l-0 align-middle">
                            Nama Personil
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {filteredSatpamOptions.length > 0 &&
                          filteredSatpamOptions.map((personil, index) => (
                            <tr key={index}>
                              <td className="px-2 py-1 border-b-2 border-gray-200 text-sm text-center">
                                <input
                                  type="checkbox"
                                  className="cursor-pointer"
                                  id={personil.value}
                                  value={personil.value}
                                  checked={
                                    satpamSelected?.filter(
                                      (selected) => selected.satpamkode === personil.value
                                    ).length > 0
                                      ? true
                                      : false
                                  }
                                  onChange={handleSatpamSelected}
                                />
                              </td>
                              <td className="px-2 py-1 border-b-2 align-middle border-gray-200 text-sm text-left">
                                <label htmlFor={personil.value} className="cursor-pointer">
                                  {personil.label}
                                </label>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Button Submit/cancel */}
          <div className="flex justify-between items-center w-full py-5 px-10 shadowAll rounded-lg my-5 md:my-10">
            <button
              type="submit"
              className="text-light py-2 px-5 rounded-md text-sm bg-dark hover:opacity-80"
              disabled={loading === true ? true : false}>
              {loading ? (
                <ClipLoader color="white" loading={true} size={20} speedMultiplier={0.7} />
              ) : (
                'Simpan'
              )}
            </button>

            <Link to="/patroli">
              <button className="btn text-light py-2 px-5 rounded-md text-sm bg-dark hover:opacity-80">
                Kembali
              </button>
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
}
