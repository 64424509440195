import DatePicker from 'react-datepicker';
import id from 'date-fns/locale/id';
import imageCompression from 'browser-image-compression';
import moment from 'moment';
import Select from 'react-select';
import Swal from 'sweetalert2';
import { ClipLoader } from 'react-spinners';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useState, useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
// Import Files
import * as api from '../../redux/api';
import { kegiatanHarianUpdateSchema } from '../../utils/schemaValidation';
import useImagePreview from '../../hooks/useImagePreview';

export default function KegiatanHarianUpdate() {
  const profile = localStorage.profile && JSON.parse(localStorage.profile);
  const kodePerusahaan = profile.user.adminpetugasperusahaankode;

  const [defaultValues, setDefaultValues] = useState({});
  const [kategoriDataOptions, setKategoriDataOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [satpamDataOptions, setSatpamDataOptions] = useState([]);
  const [satpamKode, setSatpamKode] = useState('');
  const [tanggalAktifitas, setTanggalAktifitas] = useState(new Date());
  // const [tanggalMelaporkan, setTanggalMelaporkan] = useState(new Date());

  const navigate = useNavigate();
  const { kode } = useParams();
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    reset
  } = useForm({
    defaultValues: defaultValues,
    resolver: yupResolver(kegiatanHarianUpdateSchema)
  });

  const compressOptions = {
    maxSizeMB: 1,
    maxWidthOrHeight: 1920,
    useWebWorker: true
  };

  function isValidDate(date) {
    return date instanceof Date && !isNaN(date);
  }

  useEffect(() => {
    const getKegiatanHarianByKode = async () => {
      await api.kegiatanHarianByKode({ satpamkegiatankode: kode }).then((res) => {
        if (res.data.data.length > 0) {
          const defaultValue = {
            satpamkegiatansatpamkegiatankategorikode:
              res.data.data[0].satpamkegiatansatpamkegiatankategorikode,
            uraian: res.data.data[0].satpamkegiatanuraian,
            keterangan: res.data.data[0].satpamkegiatanketerangan,
            alamat: res.data.data[0].satpamkegiatanalamat,
            status: res.data.data[0].satpamkegiatanstatus,
            latitude: res.data.data[0].satpamkegiatlatitude,
            longitude: res.data.data[0].satpamkegiatlongitude,
            patokan: res.data.data[0].satpamkegiatanalamatpatokan,
            keterangan_foto1: res.data.data[0].satpamkegiatanketeranganfotosatu
              ? res.data.data[0].satpamkegiatanketeranganfotosatu
              : '',
            keterangan_foto2: res.data.data[0].satpamkegiatanketeranganfotodua
              ? res.data.data[0].satpamkegiatanketeranganfotodua
              : '',
            keterangan_foto3: res.data.data[0].satpamkegiatanketeranganfototiga
              ? res.data.data[0].satpamkegiatanketeranganfototiga
              : '',
            keterangan_foto4: res.data.data[0].satpamkegiatanketeranganfotoempat
              ? res.data.data[0].satpamkegiatanketeranganfotoempat
              : '',
            keterangan_foto5: res.data.data[0].satpamkegiatanketeranganfotolima
              ? res.data.data[0].satpamkegiatanketeranganfotolima
              : '',
            foto_kegiatan1:
              res.data.data[0].satpamkegiatanfotosatu.split('/data-kegiatan/')[1].length > 0 &&
              res.data.data[0].satpamkegiatanfotosatu,
            foto_kegiatan2:
              res.data.data[0].satpamkegiatanfotodua.split('/data-kegiatan/')[1].length > 0 &&
              res.data.data[0].satpamkegiatanfotodua,
            foto_kegiatan3:
              res.data.data[0].satpamkegiatanfototiga.split('/data-kegiatan/')[1].length > 0 &&
              res.data.data[0].satpamkegiatanfototiga,
            foto_kegiatan4:
              res.data.data[0].satpamkegiatanfotoempat.split('/data-kegiatan/')[1].length > 0 &&
              res.data.data[0].satpamkegiatanfotoempat,
            foto_kegiatan5:
              res.data.data[0].satpamkegiatanfotolima.split('/data-kegiatan/')[1].length > 0 &&
              res.data.data[0].satpamkegiatanfotolima
          };
          setDefaultValues(defaultValue);
          setSatpamKode(res.data.data[0].satpamkegiatansatpamkode);
          // setTanggalAktifitas(new Date(res.data.data[0].satpamkegiatantanggalaktifitas));

          const rawDate = res.data.data[0].satpamkegiatantanggalaktifitas;
          const parsedDate = new Date(rawDate);
          if (isValidDate(parsedDate)) {
            setTanggalAktifitas(parsedDate);
          } else {
            console.error('Invalid date received:', rawDate);
            setTanggalAktifitas(new Date());
          }

          reset(defaultValue);
          // setTanggalMelaporkan(new Date(res.data.data[0].satpamkegiatantanggalmelaporkan));
        } else {
          setDefaultValues({});
        }
      });
    };
    getKegiatanHarianByKode();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kode]);

  const [fileDataURL] = useImagePreview(watch('foto_kegiatan1'));
  const [fileDataURL2] = useImagePreview(watch('foto_kegiatan2'));
  const [fileDataURL3] = useImagePreview(watch('foto_kegiatan3'));
  const [fileDataURL4] = useImagePreview(watch('foto_kegiatan4'));
  const [fileDataURL5] = useImagePreview(watch('foto_kegiatan5'));

  useEffect(() => {
    const getDataSatpam = async () => {
      await api.satpamData().then((response) => {
        response.data.data.map((data) => {
          return setSatpamDataOptions((prev) => [
            ...prev,
            { value: data.satpamkode, label: data.satpamnamalengkap }
          ]);
        });
      });
    };
    const getDataKategori = async () => {
      await api.kegiatanHarianKategori().then((response) => {
        setKategoriDataOptions(response.data.data);
      });
    };
    getDataKategori();
    getDataSatpam();
  }, []);

  const onSubmit = async (data) => {
    setLoading(true);
    const formData = new FormData();

    formData.append('satpamkegiatankode', kode);
    formData.append('perusahaankode', kodePerusahaan);
    formData.append('satpamkode', satpamKode);
    formData.append('tanggal', moment(tanggalAktifitas).format('YYYY-MM-DDTHH:mm'));
    formData.append('tanggal_aktifitas', moment(tanggalAktifitas).format('YYYY-MM-DDTHH:mm'));

    if (data.foto_kegiatan1.length > 0 && typeof data.foto_kegiatan1 !== 'string') {
      const compressedFile = await imageCompression(data.foto_kegiatan1[0], compressOptions);
      formData.append('foto_kegiatan1', compressedFile, compressedFile.name);
    }

    if (data.foto_kegiatan2.length > 0 && typeof data.foto_kegiatan2 !== 'string') {
      const compressedFile = await imageCompression(data.foto_kegiatan2[0], compressOptions);
      formData.append('foto_kegiatan2', compressedFile, compressedFile.name);
    }

    if (data.foto_kegiatan3.length > 0 && typeof data.foto_kegiatan3 !== 'string') {
      const compressedFile = await imageCompression(data.foto_kegiatan3[0], compressOptions);
      formData.append('foto_kegiatan3', compressedFile, compressedFile.name);
    }

    if (data.foto_kegiatan4.length > 0 && typeof data.foto_kegiatan4 !== 'string') {
      const compressedFile = await imageCompression(data.foto_kegiatan4[0], compressOptions);
      formData.append('foto_kegiatan4', compressedFile, compressedFile.name);
    }

    if (data.foto_kegiatan5.length > 0 && typeof data.foto_kegiatan5 !== 'string') {
      const compressedFile = await imageCompression(data.foto_kegiatan5[0], compressOptions);
      formData.append('foto_kegiatan5', compressedFile, compressedFile.name);
    }

    for (const key in data) {
      if (key === 'field') {
        formData.append(key, data[key][1]);
      } else {
        formData.append(key, data[key]);
      }
    }

    await api
      .kegiatanUpdate(formData)
      .then((res) => {
        Swal.fire('Good job!', 'You successfully submit the form!', 'success');
        setLoading(false);
        navigate('/kegiatan-harian');
      })
      .catch((error) => {
        setLoading(false);
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: error.response.data.message
        });
      });
  };

  return (
    <div className="relative px-4 md:px-10 mx-auto w-full -mt-8 scrollbar-thin scrollbar-track-gray-100 scrollbar-thumb-gray-300">
      <div className="w-full min-h-screen">
        <div className="w-full py-3 bg-gray-50 rounded-lg shadow-black/40 shadow-lg flex justify-center items-center mb-5 text-center text-lg font-semibold">
          Ubah Laporan Kegiatan Harian
        </div>
        <form
          onSubmit={handleSubmit(onSubmit)}
          encType="multipart/form-data"
          className="w-full flex flex-col gap-5">
          <div className="flex flex-wrap lg:flex-nowrap text-sm gap-5">
            <div className="w-full flex flex-col gap-5">
              <div className=" bg-gray-50 p-5 rounded-lg shadowAll flex flex-col justify-around">
                {/* Status Kegiatan */}
                <div className="md:flex items-center mb-3">
                  <div className="md:w-1/3">
                    <label className="block font-semibold mb-1 md:mb-0">Status kegiatan</label>
                  </div>
                  <div className="md:w-2/3">
                    <select
                      className={`w-full flex flex-col font-normal ${
                        errors.status ? 'invalidForm' : 'inputForm'
                      }`}
                      {...register('status')}>
                      <option disabled value="">
                        Pilih status kegiatan
                      </option>
                      <option value={0}>Open</option>
                      <option value={1}>Close</option>
                    </select>
                    <p className="text-errorText text-xs">{errors.status?.message}</p>
                  </div>
                </div>

                {/* Personil */}
                <div className="md:flex mb-3 items-center">
                  <div className="md:w-1/3">
                    <label className="block font-semibold mb-1 md:mb-0">Personil</label>
                  </div>
                  <div className="md:w-2/3">
                    <Select
                      options={satpamDataOptions}
                      value={satpamDataOptions.filter((option) => option.value === satpamKode)}
                      className="w-full"
                      placeholder="Pilih personil"
                      isSearchable={true}
                      defaultValue={satpamKode}
                      name="satpamkode"
                      required
                      onChange={(e) => setSatpamKode(e.value)}
                    />
                  </div>
                </div>

                {/* Tanggal Kegiatan */}
                <div className="md:flex mb-3 items-center">
                  <div className="md:w-1/3">
                    <label htmlFor="tanggal_aktifitas" className="block font-semibold mb-1 md:mb-0">
                      Tanggal kegiatan
                    </label>
                  </div>
                  <div className="md:w-2/3">
                    <div className="relative block">
                      <DatePicker
                        selected={tanggalAktifitas}
                        className={`w-full text-sm cursor-pointer inputForm`}
                        onChange={(date) => setTanggalAktifitas(date)}
                        locale={id}
                        showTimeInput
                        dateFormat="dd/MM/yyyy HH:mm"
                        placeholderText="dd/MM/yyyy HH:mm"
                        timeFormat="HH:mm"
                        required
                      />
                      <div className="absolute top-1/2 transform -translate-y-1/2 right-3 pointer-events-none">
                        <i className="far fa-calendar"></i>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Kategori */}
                <div className="md:flex items-center mb-3">
                  <div className="md:w-1/3">
                    <label className="block font-semibold mb-1 md:mb-0">Kategori</label>
                  </div>
                  <div className="md:w-2/3">
                    <select
                      defaultValue=""
                      className={`w-full ${
                        errors.satpamkegiatansatpamkegiatankategorikode
                          ? 'invalidForm'
                          : 'inputForm'
                      }`}
                      {...register('satpamkegiatansatpamkegiatankategorikode')}>
                      <option value="" disabled>
                        Pilih kategori
                      </option>
                      {kategoriDataOptions.length > 0 &&
                        kategoriDataOptions.map((kategori) => (
                          <option
                            key={kategori.satpamkegiatankategorikode}
                            value={kategori.satpamkegiatankategorikode}>
                            {kategori.satpamkegiatankategorinama}
                          </option>
                        ))}
                    </select>
                    <p className="text-errorText text-xs">
                      {errors.satpamkegiatansatpamkegiatankategorikode?.message}
                    </p>
                  </div>
                </div>

                {/* Lokasi */}
                <div className="mb-3">
                  <div>
                    <label className="block font-semibold mb-1">Lokasi</label>
                  </div>
                  <div>
                    <input
                      type="text"
                      className="w-full inputForm"
                      placeholder="Nama tempat/gedung (Opsional)"
                      {...register('patokan')}
                    />
                  </div>
                </div>

                {/* Alamat */}
                <div className="mb-3">
                  <div>
                    <label className="block font-semibold mb-1">Alamat</label>
                  </div>
                  <div>
                    <textarea
                      className={`w-full text-sm ${errors.alamat ? 'invalidForm' : 'inputForm'}`}
                      rows={3}
                      placeholder="Tulis alamat kegiatan"
                      {...register('alamat')}
                    />
                    <p className="text-errorText text-xs">{errors.alamat?.message}</p>
                  </div>
                </div>

                {/* Perubahan */}
                <div>
                  <div>
                    <label htmlFor="satpamprosesketerangan" className="block font-semibold mb-1">
                      Perubahan
                    </label>
                  </div>
                  <div>
                    <input
                      type="text"
                      placeholder="Tulis perubahan yg dilakukan"
                      className={`w-full text-sm cursor-pointer ${
                        errors.satpamprosesketerangan ? 'invalidForm' : 'inputForm'
                      }`}
                      {...register('satpamprosesketerangan')}
                    />
                    <p className="text-errorText text-xs">
                      {errors.satpamprosesketerangan?.message}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="w-full flex flex-col gap-5">
              <div className="bg-gray-50 p-5 rounded-lg shadowAll">
                {/* Uraian Kegiatan */}
                <div className="mb-3">
                  <div>
                    <label className="block font-semibold mb-1">Uraian kegiatan</label>
                  </div>
                  <div>
                    <textarea
                      className={`w-full text-sm ${errors.uraian ? 'invalidForm' : 'inputForm'}`}
                      rows={10}
                      placeholder="Tulis uraian kegiatan"
                      {...register('uraian')}
                    />
                    <p className="text-errorText text-xs">{errors.uraian?.message}</p>
                  </div>
                </div>

                {/* Keterangan */}
                <div>
                  <div>
                    <label className="block font-semibold mb-1">Keterangan</label>
                  </div>
                  <div>
                    <textarea
                      className={`w-full text-sm ${
                        errors.keterangan ? 'invalidForm' : 'inputForm'
                      }`}
                      rows={5}
                      placeholder="Tulis keterangan kegiatan"
                      {...register('keterangan')}
                    />
                    <p className="text-errorText text-xs">{errors.keterangan?.message}</p>
                  </div>
                </div>
                {/* <div className="md:flex mb-3 items-center">
                  <div className="md:w-1/3">
                    <label htmlFor="tanggal" className="block font-semibold mb-1 md:mb-0">
                      Tanggal melaporkan
                    </label>
                  </div>
                  <div className="md:w-2/3">
                    <div className="relative block">
                      <DatePicker
                        selected={tanggalMelaporkan}
                        className={`w-full text-sm cursor-pointer inputForm`}
                        onChange={(date) => setTanggalMelaporkan(date)}
                        locale={id}
                        showTimeInput
                        dateFormat="dd/MM/yyyy HH:mm"
                        timeFormat="HH:mm"
                        placeholderText="dd/MM/yyyy HH:mm"
                        required
                      />
                      <div className="absolute top-1/2 transform -translate-y-1/2 right-3 pointer-events-none">
                        <i className="far fa-calendar"></i>
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
              {/* <div className="bg-gray-50 p-5 rounded-lg shadowAll">
                
                <div className="hidden md:flex items-center">
                  <div className="hidden md:w-1/3">
                    <label className="hidden font-semibold mb-1 md:mb-0">Latitude</label>
                  </div>
                  <div className="hidden md:w-2/3">
                    <input
                      type="text"
                      className="hidden w-full inputForm"
                      {...register('latitude')}
                    />
                  </div>
                </div>
                <div className="hidden md:flex items-center">
                  <div className="hidden md:w-1/3">
                    <label className="hidden font-semibold mb-1 md:mb-0">Longtitude</label>
                  </div>
                  <div className="hidden md:w-2/3">
                    <input
                      type="text"
                      className="hidden w-full inputForm"
                      {...register('longtitude')}
                    />
                  </div>
                </div>
              </div> */}
            </div>
          </div>

          {/* Photos */}
          <div className="w-full bg-gray-50 p-5 rounded-lg shadowAll">
            <div className="mx-auto">
              <div className="flex flex-wrap -m-1 md:-m-2">
                <div className="flex flex-wrap lg:w-1/5 md:w-1/3 w-full">
                  <div className="w-full p-1 md:p-2">
                    <div className="h-auto p-3 bg-white rounded-md border border-gray-200 shadow-md">
                      <div className="flex flex-col w-full">
                        <div className="text-sm font-semibold text-center">Pilih Foto</div>
                        <label className="p-2 flex flex-col w-full h-40 items-center justify-center border-4 border-dashed hover:bg-gray-100 hover:border-gray-300 cursor-pointer">
                          {fileDataURL ? (
                            <div className="flex flex-col items-center justify-center">
                              <img
                                src={fileDataURL}
                                className="h-36 w-full object-contain"
                                alt="preview"
                              />
                            </div>
                          ) : (
                            <div className="flex flex-col items-center justify-center pt-7">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="w-12 h-12 text-gray-400 group-hover:text-gray-600"
                                viewBox="0 0 20 20"
                                fill="currentColor">
                                <path
                                  fillRule="evenodd"
                                  d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z"
                                  clipRule="evenodd"
                                />
                              </svg>
                              <p className="pt-1 text-sm text-center tracking-wider text-gray-400 group-hover:text-gray-600">
                                Click here
                              </p>
                            </div>
                          )}
                          <input
                            type="file"
                            className="opacity-0"
                            {...register('foto_kegiatan1')}
                            accept="image/*"
                          />
                        </label>
                        <p className="text-errorText text-xs">{errors.foto_kegiatan1?.message}</p>
                      </div>
                      <div className="mt-3">
                        <label className="text-sm font-semibold">Keterangan Foto 1</label>
                        <textarea
                          className="w-full inputForm text-xs"
                          placeholder="Opsional"
                          rows={3}
                          {...register('keterangan_foto1')}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex flex-wrap lg:w-1/5 md:w-1/3 w-full">
                  <div className="w-full p-1 md:p-2">
                    <div className="h-auto p-3 bg-white rounded-md border border-gray-200 shadow-md">
                      <div className="flex flex-col w-full">
                        <div className="text-sm font-semibold text-center">Pilih Foto</div>
                        <label className="p-2 flex flex-col w-full h-40 items-center justify-center border-4 border-dashed hover:bg-gray-100 hover:border-gray-300 cursor-pointer">
                          {fileDataURL2 ? (
                            <div className="flex flex-col items-center justify-center">
                              <img
                                src={fileDataURL2}
                                className="h-36 w-full object-contain"
                                alt="preview"
                              />
                            </div>
                          ) : (
                            <div className="flex flex-col items-center justify-center pt-7">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="w-12 h-12 text-gray-400 group-hover:text-gray-600"
                                viewBox="0 0 20 20"
                                fill="currentColor">
                                <path
                                  fillRule="evenodd"
                                  d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z"
                                  clipRule="evenodd"
                                />
                              </svg>
                              <p className="pt-1 text-sm text-center tracking-wider text-gray-400 group-hover:text-gray-600">
                                Click here
                              </p>
                            </div>
                          )}
                          <input
                            type="file"
                            className="opacity-0"
                            {...register('foto_kegiatan2')}
                            accept="image/*"
                          />
                        </label>
                      </div>
                      <div className="mt-3">
                        <label className="text-sm font-semibold">Keterangan Foto 2</label>
                        <textarea
                          className="w-full inputForm text-xs"
                          placeholder="Opsional"
                          rows={3}
                          {...register('keterangan_foto2')}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex flex-wrap lg:w-1/5 md:w-1/3 w-full">
                  <div className="w-full p-1 md:p-2">
                    <div className="h-auto p-3 bg-white rounded-md border border-gray-200 shadow-md">
                      <div className="flex flex-col w-full">
                        <div className="text-sm font-semibold text-center">Pilih Foto</div>
                        <label className="p-2 flex flex-col w-full h-40 items-center justify-center border-4 border-dashed hover:bg-gray-100 hover:border-gray-300 cursor-pointer">
                          {fileDataURL3 ? (
                            <div className="flex flex-col items-center justify-center">
                              <img
                                src={fileDataURL3}
                                className="h-36 w-full object-contain"
                                alt="preview"
                              />
                            </div>
                          ) : (
                            <div className="flex flex-col items-center justify-center pt-7">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="w-12 h-12 text-gray-400 group-hover:text-gray-600"
                                viewBox="0 0 20 20"
                                fill="currentColor">
                                <path
                                  fillRule="evenodd"
                                  d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z"
                                  clipRule="evenodd"
                                />
                              </svg>
                              <p className="pt-1 text-sm text-center tracking-wider text-gray-400 group-hover:text-gray-600">
                                Click here
                              </p>
                            </div>
                          )}
                          <input
                            type="file"
                            className="opacity-0"
                            {...register('foto_kegiatan3')}
                            accept="image/*"
                          />
                        </label>
                      </div>
                      <div className="mt-3">
                        <label className="text-sm font-semibold">Keterangan Foto 3</label>
                        <textarea
                          className="w-full inputForm text-xs"
                          placeholder="Opsional"
                          rows={3}
                          {...register('keterangan_foto3')}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex flex-wrap lg:w-1/5 md:w-1/3 w-full">
                  <div className="w-full p-1 md:p-2">
                    <div className="h-auto p-3 bg-white rounded-md border border-gray-200 shadow-md">
                      <div className="flex flex-col w-full">
                        <div className="text-sm font-semibold text-center">Pilih Foto</div>
                        <label className="p-2 flex flex-col w-full h-40 items-center justify-center border-4 border-dashed hover:bg-gray-100 hover:border-gray-300 cursor-pointer">
                          {fileDataURL4 ? (
                            <div className="flex flex-col items-center justify-center">
                              <img
                                src={fileDataURL4}
                                className="h-36 w-full object-contain"
                                alt="preview"
                              />
                            </div>
                          ) : (
                            <div className="flex flex-col items-center justify-center pt-7">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="w-12 h-12 text-gray-400 group-hover:text-gray-600"
                                viewBox="0 0 20 20"
                                fill="currentColor">
                                <path
                                  fillRule="evenodd"
                                  d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z"
                                  clipRule="evenodd"
                                />
                              </svg>
                              <p className="pt-1 text-sm text-center tracking-wider text-gray-400 group-hover:text-gray-600">
                                Click here
                              </p>
                            </div>
                          )}
                          <input
                            type="file"
                            className="opacity-0"
                            {...register('foto_kegiatan4')}
                            accept="image/*"
                          />
                        </label>
                      </div>
                      <div className="mt-3">
                        <label className="text-sm font-semibold">Keterangan Foto 4</label>
                        <textarea
                          className="w-full inputForm text-xs"
                          placeholder="Opsional"
                          rows={3}
                          {...register('keterangan_foto4')}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex flex-wrap lg:w-1/5 md:w-1/3 w-full">
                  <div className="w-full p-1 md:p-2">
                    <div className="h-auto p-3 bg-white rounded-md border border-gray-200 shadow-md">
                      <div className="flex flex-col w-full">
                        <div className="text-sm font-semibold text-center">Pilih Foto</div>
                        <label className="p-2 flex flex-col w-full h-40 items-center justify-center border-4 border-dashed hover:bg-gray-100 hover:border-gray-300 cursor-pointer">
                          {fileDataURL5 ? (
                            <div className="flex flex-col items-center justify-center">
                              <img
                                src={fileDataURL5}
                                className="h-36 w-full object-contain"
                                alt="preview"
                              />
                            </div>
                          ) : (
                            <div className="flex flex-col items-center justify-center pt-7">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="w-12 h-12 text-gray-400 group-hover:text-gray-600"
                                viewBox="0 0 20 20"
                                fill="currentColor">
                                <path
                                  fillRule="evenodd"
                                  d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z"
                                  clipRule="evenodd"
                                />
                              </svg>
                              <p className="pt-1 text-sm text-center tracking-wider text-gray-400 group-hover:text-gray-600">
                                Click here
                              </p>
                            </div>
                          )}
                          <input
                            type="file"
                            className="opacity-0"
                            {...register('foto_kegiatan5')}
                            accept="image/*"
                          />
                        </label>
                      </div>
                      <div className="mt-3">
                        <label className="text-sm font-semibold">Keterangan Foto 5</label>
                        <textarea
                          className="w-full inputForm text-xs"
                          placeholder="Opsional"
                          rows={3}
                          {...register('keterangan_foto5')}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="flex justify-between items-center w-full py-5 px-10 shadowAll rounded-lg mb-5">
            <button
              type="submit"
              className="text-gray-50 py-2 px-5 rounded-md text-sm bg-dark hover:opacity-80"
              disabled={loading === true ? true : false}>
              {loading ? (
                <ClipLoader color="white" loading={true} size={20} speedMultiplier={0.7} />
              ) : (
                'Simpan'
              )}
            </button>

            <Link to="/kegiatan-harian">
              <button className="btn text-gray-50 py-2 px-5 rounded-md text-sm bg-dark hover:opacity-80">
                Kembali
              </button>
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
}
