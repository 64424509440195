import React, { useState, useEffect } from 'react';
import { Modal } from '@mantine/core';
import * as api from '../../redux/api';
import moment from 'moment/moment';
import { parseToNewLine } from '../../utils/parseToNewLine';

const ModalKejadianDetail = ({ kode, openModalDetail, setOpenModalDetail }) => {
  const [dataKejadian, setDataKejadian] = useState({});

  useEffect(() => {
    if (kode !== '') {
      const getDataKejadianByKode = async () => {
        await api.kejadianByKode({ satpamkejadiankode: kode }).then((res) => {
          setDataKejadian(res.data.Data[0]);
        });
      };

      getDataKejadianByKode();
    }
  }, [kode]);

  return (
    <Modal
      opened={openModalDetail}
      size="xl"
      overflow="inside"
      centered
      closeOnClickOutside
      onClose={() => setOpenModalDetail(false)}>
      <div className="text-center text-lg font-bold mb-5">Detail Kejadian</div>
      <div>
        <div className="text-dark scrollbar-thin px-5">
          <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
            <div className="md:w-3/5 w-full font-bold">Kode Kejadian</div>
            <div className="w-full">{dataKejadian?.satpamkejadiankode}</div>
          </div>
          <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
            <div className="md:w-3/5 w-full font-bold">Kode Perusahaan</div>
            <div className="w-full">{dataKejadian?.satpamkejadiansatpamperusahaankode}</div>
          </div>
          <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
            <div className="md:w-3/5 w-full font-bold">Nama Perusahaan</div>
            <div className="w-full">{dataKejadian?.satpamperusahaannama}</div>
          </div>
          <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
            <div className="md:w-3/5 w-full font-bold">Kode Personil</div>
            <div className="w-full">{dataKejadian?.satpamkejadiansatpamkode}</div>
          </div>
          <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
            <div className="md:w-3/5 w-full font-bold">Nama Personil</div>
            <div className="w-full">{dataKejadian?.satpamnamalengkap}</div>
          </div>
          <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
            <div className="md:w-3/5 w-full font-bold">Tanggal Melaporkan</div>
            <div className="w-full">
              {moment(dataKejadian?.satpamkejadiantanggalmelaporkan).format('DD MMM YYYY HH:mm') ||
                '-'}
            </div>
          </div>
          <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
            <div className="md:w-3/5 w-full font-bold">Tanggal Aktifitas</div>
            <div className="w-full">
              {moment(dataKejadian?.satpamkejadiantanggalaktifitas).format('DD MMM YYYY HH:mm') ||
                '-'}
            </div>
          </div>
          <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
            <div className="md:w-3/5 w-full font-bold">Uraian Kejadian</div>
            <div className="w-full">
              {dataKejadian?.satpamkejadianuraian !== '' &&
                parseToNewLine(dataKejadian?.satpamkejadianuraian)}
            </div>
          </div>
          <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
            <div className="md:w-3/5 w-full font-bold">Uraian Tindakan</div>
            <div className="w-full">
              {dataKejadian?.satpamkejadiantindakan !== '' &&
                parseToNewLine(dataKejadian?.satpamkejadiantindakan)}
            </div>
          </div>
          <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
            <div className="md:w-3/5 w-full font-bold">Hasil Tindakan</div>
            <div className="w-full">
              {dataKejadian?.satpamkejadianhasil !== '' &&
                parseToNewLine(dataKejadian?.satpamkejadianhasil)}
            </div>
          </div>
          <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
            <div className="md:w-3/5 w-full font-bold">Potensi Kejadian</div>
            <div className="w-full">
              {dataKejadian?.satpamkejadianpotensi !== null
                ? dataKejadian?.satpamkejadianpotensi
                : 'Belum ada potensi'}
            </div>
          </div>
          <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
            <div className="md:w-3/5 w-full font-bold">Analisis Kejadian</div>
            <div className="w-full">
              {dataKejadian?.satpamkejadiananalisis !== null
                ? dataKejadian?.satpamkejadiananalisis
                : 'Belum ada analisis'}
            </div>
          </div>
          <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
            <div className="md:w-3/5 w-full font-bold">Kesimpulan Kejadian</div>
            <div className="w-full">
              {dataKejadian?.satpamkejadiankesimpulan !== null
                ? dataKejadian?.satpamkejadiankesimpulan
                : 'Belum ada kesimpulan'}
            </div>
          </div>
          <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
            <div className="md:w-3/5 w-full font-bold">Saran dan Rekomendasi</div>
            <div className="w-full">
              {dataKejadian?.satpamkejadianrekomendasi !== null
                ? dataKejadian?.satpamkejadianrekomendasi
                : 'Belum ada rekomendasi'}
            </div>
          </div>
          <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
            <div className="md:w-3/5 w-full font-bold">Pelaku</div>
            <div className="w-full">{dataKejadian?.satpamkejadianpelaku}</div>
          </div>
          <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
            <div className="md:w-3/5 w-full font-bold">Korban</div>
            <div className="w-full">{dataKejadian?.satpamkejadiankorban}</div>
          </div>
          <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
            <div className="md:w-3/5 w-full font-bold">Saksi</div>
            <div className="w-full">{dataKejadian?.satpamkejadiansaksi}</div>
          </div>
          {/* <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
            <div className="md:w-3/5 w-full font-bold">Latitude</div>
            <div className="w-full">{dataKejadian?.satpamkejadianlatitude || '-'}</div>
          </div>
          <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
            <div className="md:w-3/5 w-full font-bold">Longitude</div>
            <div className="w-full">{dataKejadian?.satpamkejadianlongitude || '-'}</div>
          </div> */}
          <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
            <div className="md:w-3/5 w-full font-bold">Alamat kejadian</div>
            <div className="w-full">{dataKejadian?.satpamkejadianalamat}</div>
          </div>
          <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
            <div className="md:w-3/5 w-full font-bold">Patokan</div>
            <div className="w-full">{dataKejadian?.satpamkejadianalamatpatokan}</div>
          </div>
          {dataKejadian?.satpamkejadianfotosatu?.split('/data-kejadian/')[1].length > 0 && (
            <>
              <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
                <div className="md:w-3/5 w-full font-bold">Foto 1</div>
                <div className="w-full">
                  <img
                    className="w-full h-64 object-contain"
                    src={dataKejadian?.satpamkejadianfotosatu}
                    alt=""
                  />
                </div>
              </div>
              <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
                <div className="md:w-3/5 w-full font-bold">Ket. Foto 1</div>
                <div className="w-full">{dataKejadian?.satpamkejadianketeranganfotosatu}</div>
              </div>
            </>
          )}
          {dataKejadian?.satpamkejadianfotodua?.split('/data-kejadian/')[1].length > 0 && (
            <>
              <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
                <div className="md:w-3/5 w-full font-bold">Foto 1</div>
                <div className="w-full">
                  <img
                    className="w-full h-64 object-contain"
                    src={dataKejadian?.satpamkejadianfotodua}
                    alt=""
                  />
                </div>
              </div>
              <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
                <div className="md:w-3/5 w-full font-bold">Ket. Foto 2</div>
                <div className="w-full">{dataKejadian?.satpamkejadianketeranganfotodua}</div>
              </div>
            </>
          )}
          {dataKejadian?.satpamkejadianfototiga?.split('/data-kejadian/')[1].length > 0 && (
            <>
              <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
                <div className="md:w-3/5 w-full font-bold">Foto 3</div>
                <div className="w-full">
                  <img
                    className="w-full h-64 object-contain"
                    src={dataKejadian?.satpamkejadianfototiga}
                    alt=""
                  />
                </div>
              </div>
              <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
                <div className="md:w-3/5 w-full font-bold">Ket. Foto 3</div>
                <div className="w-full">{dataKejadian?.satpamkejadianketeranganfototiga}</div>
              </div>
            </>
          )}
          {dataKejadian?.satpamkejadianfotoempat?.split('/data-kejadian/')[1].length > 0 && (
            <>
              <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
                <div className="md:w-3/5 w-full font-bold">Foto 4</div>
                <div className="w-full">
                  <img
                    className="w-full h-64 object-contain"
                    src={dataKejadian?.satpamkejadianfotoempat}
                    alt=""
                  />
                </div>
              </div>
              <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
                <div className="md:w-3/5 w-full font-bold">Ket. Foto 4</div>
                <div className="w-full">{dataKejadian?.satpamkejadianketeranganfotoempat}</div>
              </div>
            </>
          )}
          {dataKejadian?.satpamkejadianfotolima?.split('/data-kejadian/')[1].length > 0 && (
            <>
              <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
                <div className="md:w-3/5 w-full font-bold">Foto 5</div>
                <div className="w-full">
                  <img
                    className="w-full h-64 object-contain"
                    src={dataKejadian?.satpamkejadianfotolima}
                    alt=""
                  />
                </div>
              </div>
              <div className="flex flex-col md:flex-row w-full gap-2 mb-2">
                <div className="md:w-3/5 w-full font-bold">Ket. Foto 5</div>
                <div className="w-full">{dataKejadian?.satpamkejadianketeranganfotolima}</div>
              </div>
            </>
          )}
          <div className="flex justify-end mt-5">
            <div
              className="w-fit text-gray-50 py-2 px-3 rounded-md text-xs bg-dark hover:opacity-80 cursor-pointer"
              onClick={() => setOpenModalDetail(false)}>
              Kembali
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ModalKejadianDetail;
