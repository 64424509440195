// Import packages
import React, { useState, useEffect } from 'react';
import * as api from '../../redux/api';
import { Link } from 'react-router-dom';
import { Tooltip, Popover, Progress } from '@mantine/core';
import Cards from '../../components/general/Cards';
import FilterDate from '../../components/general/FilterDate';
import Table from '../../components/general/Table';
import { AiOutlineFileAdd } from 'react-icons/ai';
import { HiOutlinePrinter } from 'react-icons/hi';
import Swal from 'sweetalert2';
import ModalPatroliDetail from '../../components/patroli/ModalPatroliDetail';
import moment from 'moment';
import FilterKategori from '../../components/general/FilterKategori';
import { useDebouncedValue } from '@mantine/hooks';
import { useSelector } from 'react-redux';

export default function Patroli({ role }) {
  const [data, setData] = useState([]);
  const [datas, setDatas] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [showDate, setShowDate] = useState(false);
  const [openModalDetail, setOpenModalDetail] = useState(false);
  const [patroliDetailKode, setPatroliDetailKode] = useState('');
  const [shift, setShift] = useState('');
  const [posData, setPosData] = useState([]);
  const [pos, setPos] = useState('');

  const [query, setQuery] = useState('');
  const [debouncedQuery] = useDebouncedValue(query, 200);

  // Jenis Patroli (Jalan kaki / Berkendara)
  const [kategori, setKategori] = useState('Patroli Jalan Kaki');

  // Date
  const { perusahaanKode } = useSelector((state) => state.perusahaan);
  const [date, setDate] = useState({
    startDate: moment().format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD')
  });

  // Column Data Table
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const initialColumnData = [
    {
      title: 'Action',
      accessor: 'satpampatrolikode',
      width: 100,
      render: ({ satpampatrolikode, satpampatrolikemajuan, satpampatrolisatpamkode }) => (
        <div>
          <Popover width={110} position="right" withArrow shadow="xl">
            <Popover.Target>
              <div className="btnAksi">Action</div>
            </Popover.Target>
            <Popover.Dropdown className="popOverDropdown">
              <div
                size="sm"
                className="popOverItem"
                onClick={() => handleOpenModalDetail(satpampatrolikode)}>
                <i className="fa-solid fa-circle-info"></i> Detail
              </div>
              <Link to={`/patroli-printperpat/${satpampatrolikode}`}>
                <div className="popOverItem">
                  <i className="fa-solid fa-print"></i> Print
                </div>
              </Link>
              {role === 'Admin Project' && (
                <>
                  <div className="popOverItem" onClick={() => handleDelete(satpampatrolikode)}>
                    <i className="fa-solid fa-trash mr-[2px]"></i> Delete
                  </div>
                  {parseInt(satpampatrolikemajuan) <= 99 && (
                    <div
                      size="sm"
                      className="popOverItem"
                      onClick={() => handleSendNotification(satpampatrolisatpamkode)}>
                      <i className="fa-solid fa-bell"></i> Push Notif
                    </div>
                  )}
                </>
              )}
            </Popover.Dropdown>
          </Popover>
        </div>
      )
    },
    {
      title: 'Progress',
      accessor: 'satpampatrolikemajuan',
      width: 150,
      render: ({ satpampatrolikemajuan }) => (
        <>
          {!isNaN(satpampatrolikemajuan) ? (
            <div className="text-center">
              {Number.isInteger(parseFloat(satpampatrolikemajuan))
                ? parseFloat(satpampatrolikemajuan) + ' %'
                : parseFloat(satpampatrolikemajuan).toFixed(3) + ' %'}
              <Progress
                className="mb-2"
                color={
                  parseInt(satpampatrolikemajuan) <= 30
                    ? 'yellow'
                    : parseInt(satpampatrolikemajuan) <= 70
                    ? 'teal'
                    : 'blue'
                }
                value={parseInt(satpampatrolikemajuan)}
                size="md"
                radius="md"
              />
            </div>
          ) : (
            <div className="flex justify-center items-center mx-auto">0 %</div>
          )}
        </>
      )
    },
    {
      title: 'Nama Personil',
      accessor: 'satpamnamalengkap',
      cellsStyle: {
        verticalAlign: 'top'
      },
      width: 200
    },
    {
      title: 'POS',
      accessor: 'satpamposnama',
      cellsStyle: {
        verticalAlign: 'top'
      },
      width: 150
    },
    {
      title: 'Tanggal Mulai',
      accessor: 'satpampatrolimulaitanggal',
      width: 160,
      cellsStyle: {
        verticalAlign: 'top'
      },
      render: ({ satpampatrolimulaitanggal }) => (
        <>
          {satpampatrolimulaitanggal !== '' &&
            moment(satpampatrolimulaitanggal).format('DD MMM YYYY HH:mm')}
        </>
      )
    },
    {
      title: 'Tanggal Selesai',
      accessor: 'satpampatroliselesaitanggal',
      width: 160,
      cellsStyle: {
        verticalAlign: 'top'
      },
      render: ({ satpampatroliselesaitanggal }) => (
        <>
          {satpampatroliselesaitanggal !== '' &&
            moment(satpampatroliselesaitanggal).format('DD MMM YYYY HH:mm')}
        </>
      )
    }
  ];

  const [columnData, setColumnData] = useState(initialColumnData);

  const getData = async (formData) => {
    await api.patroli(formData).then((res) => {
      setData(
        res.data.data.sort((a, b) => {
          const posCompare = a.satpampatrolisatpamposkode.localeCompare(
            b.satpampatrolisatpamposkode
          );
          if (posCompare !== 0) return posCompare;

          const startCompare =
            new Date(a.satpampatrolimulaitanggal) - new Date(b.satpampatrolimulaitanggal);
          if (startCompare !== 0) return startCompare;

          const endCompare =
            new Date(a.satpampatroliselesaitanggal) - new Date(b.satpampatroliselesaitanggal);
          if (endCompare !== 0) return endCompare;

          return b.satpampatrolikemajuan - a.satpampatrolikemajuan;
        })
      );
      setDatas(res.data);
    });

    await api.patroliGetPOS().then((res) => setPosData(res.data.data));
  };

  // Get data
  useEffect(() => {
    const formData = {
      startdate: date.startDate,
      enddate: date.endDate,
      satpampatrolijenis: kategori
    };

    if (perusahaanKode) {
      formData.satpamperusahaankode = perusahaanKode;
    }

    if (kategori !== '') {
      kategori === 'Patroli Jalan Kaki'
        ? setColumnData(initialColumnData)
        : setColumnData((prev) => [
            ...prev,
            {
              title: 'Jarak Patroli',
              accessor: 'satpampatrolijarak',
              resizable: false,
              wrapText: true,
              autoHeight: true,
              width: 100
            }
          ]);
      getData(formData);
    }

    setShowDate(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date, kategori]);

  useEffect(() => {
    if (data?.length > 0) {
      setFilterData(
        data.filter(
          ({
            satpamnamalengkap,
            satpamposnama,
            satpampatrolimulaitanggal,
            satpampatroliselesaitanggal
          }) => {
            if (
              debouncedQuery !== '' &&
              !`${satpamnamalengkap} ${satpamposnama} ${satpampatrolimulaitanggal} ${satpampatroliselesaitanggal} `
                .toLowerCase()
                .includes(debouncedQuery.trim().toLowerCase())
            ) {
              return false;
            }
            return true;
          }
        )
      );
    }
  }, [data, debouncedQuery]);

  useEffect(() => {
    if (shift === 'siang') {
      setFilterData(
        data.filter(
          (item) =>
            new Date(item.satpampatroliselesaitanggal).toDateString() ===
            new Date(item.satpampatrolimulaitanggal).toDateString()
        )
      );
    } else if (shift === 'malam') {
      setFilterData(
        data.filter(
          (item) =>
            new Date(item.satpampatroliselesaitanggal).toDateString() !==
            new Date(item.satpampatrolimulaitanggal).toDateString()
        )
      );
    } else {
      setFilterData(data);
    }
  }, [shift, data]);

  useEffect(() => {
    if (pos === '') {
      setFilterData(data);
    } else {
      setFilterData(data.filter((item) => item.satpampatrolisatpamposkode === pos));
    }
  }, [pos, data]);

  const handleOpenModalDetail = (patrolikode) => {
    setPatroliDetailKode(patrolikode);
    setOpenModalDetail(true);
  };

  const handleSendNotification = async (patrolikode) => {
    await api.patroliSendNotif(patrolikode).then((res) => {
      Swal.fire('Good job!', res.data.message, 'success');
    });
  };

  const handleDelete = (kode) => {
    const formDate = {
      startdate: date.startDate,
      enddate: date.endDate,
      satpampatrolijenis: kategori
    };

    if (perusahaanKode) {
      formDate.satpamperusahaankode = perusahaanKode;
    }

    Swal.fire({
      text: 'Do you want to delete this patroli?',
      showCancelButton: true,
      confirmButtonText: 'Yes'
    }).then((result) => {
      if (result.isConfirmed) {
        const deleteData = async () => {
          await api
            .patroliDelete(kode)
            .then((res) => {
              Swal.fire('Good job!', res.data.message, 'success');
              getData(formDate);
            })
            .catch((err) => {
              Swal.fire({ icon: 'error', title: 'Oops...', text: err.response.data.message });
            });
        };
        deleteData();
      }
    });
  };

  return (
    <div className={`w-full min-h-screen p-5 ${role === 'Admin Project' ? 'mt-1' : '-mt-10'}`}>
      {/* Cards */}
      <Cards
        title={`Statistik ${kategori}`}
        desc={
          kategori === ''
            ? 'Silahkan pilih kategori terlebih dahulu'
            : `Berikut ini adalah statistik ${kategori.toLowerCase()}`
        }
        card1={{ title: 'Selesai', value: datas?.patroli_selesai || 0 }}
        card2={{ title: 'Belum Selesai', value: datas?.patroli_belum_selesai || 0 }}
        card3={{
          title: 'Total Patroli',
          value: datas?.total_patroli || 0
        }}
      />

      <div className="mt-10 w-full justify-center bg-gray-50 rounded-lg shadowAll">
        <div className="flex flex-wrap lg:flex-nowrap justify-beetween items-center">
          <div className="flex flex-col md:flex-row flex-wrap justify-start items-center w-full">
            <FilterDate
              date={date}
              setDate={setDate}
              showDate={showDate}
              setShowDate={setShowDate}
              defaultType="today"
            />
            <FilterKategori kategori={kategori} setKategori={setKategori} page="patroli" />

            {/* Filter shift */}
            <div className="flex flex-col w-full md:w-auto gap-1 md:my-3 my-2 pr-5 md:pr-2 pl-5">
              <div className="font-semibold text-xs mr-2 mb-1 md:mb-0">Filter shift</div>
              <div className="md:flex md:flex-row gap-2">
                <div className="flex flex-col justify-center items-center">
                  <select
                    className="text-xs cursor-pointer inputForm w-full"
                    onChange={(e) => setShift(e.target.value)}>
                    <option value="">All</option>
                    <option value="siang">Shift siang</option>
                    <option value="malam">Shift malam</option>
                  </select>
                </div>
              </div>
            </div>

            {/* Filter pos */}
            <div className="flex flex-col w-full md:w-auto gap-1 md:my-3 my-2 pr-5 md:pr-2 pl-5">
              <div className="font-semibold text-xs mr-2 mb-1 md:mb-0">Filter pos</div>
              <div className="md:flex md:flex-row gap-2">
                <div className="flex flex-col justify-center items-center">
                  <select
                    className="text-xs cursor-pointer inputForm w-full"
                    onChange={(e) => setPos(e.target.value)}>
                    <option value="">All</option>
                    {posData?.map((data) => (
                      <option key={data.satpamposid} value={data.satpamposkode}>
                        {data.satpamposnama}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div className="my-3 px-5 flex justify-end">
            <div className="flex gap-4 w-fit">
              {role === 'Admin Project' && (
                <Tooltip label="Tambah patroli">
                  <Link to="/patroli/create">
                    <div className="bg-customGray p-2 rounded-md cursor-pointer hover:opacity-80">
                      <AiOutlineFileAdd size={24} />
                    </div>
                  </Link>
                </Tooltip>
              )}
              <Link to={`/patroli-print`} state={{ data: data, date: date }}>
                <div className="bg-customGray p-2 rounded-md cursor-pointer hover:opacity-80 flex items-center text-gray-700">
                  <HiOutlinePrinter size={24} />
                </div>
              </Link>
            </div>
          </div>
        </div>
        {/* Table Component */}
        {data.length === 0 ? (
          <div className="text-center p-5">
            <div className="mt-10">
              Tidak ada data patroli {kategori === 'jalan_kaki' ? 'jalan kaki' : 'berkendara'}.
            </div>
            <div className="mb-72">Silahkan memilih rentang tanggal dan kategori lainnya!</div>
          </div>
        ) : (
          <div className="p-5">
            <Table
              data={filterData}
              columnData={columnData}
              setQuery={setQuery}
              id="satpampatrolikode"
            />
          </div>
        )}
      </div>

      {/* Modal Detail Patroli Jalan Kaki */}
      <ModalPatroliDetail
        kode={patroliDetailKode}
        openModalDetail={openModalDetail}
        setOpenModalDetail={setOpenModalDetail}
      />
    </div>
  );
}
