import React, { useEffect, useState, useRef } from 'react';
import * as api from '../../redux/api';
import { AiFillPrinter } from 'react-icons/ai';
import { useNavigate, useParams } from 'react-router-dom';
import ReactToPrint from 'react-to-print';
import KegiatanHarianCetakPerKeg from '../../components/kegiatan-harian/KegiatanHarianCetakPerKeg';
import TableCetakKegiatanPerKeg from '../../components/kegiatan-harian/TableCetakKegiatanPerKeg';
import { Helmet } from 'react-helmet';

export default function KegiatanHarianPrintPerKeg() {
  const [dataKegiatan, setDataKegiatan] = useState({});

  const { kode } = useParams();
  const navigate = useNavigate();

  const componentRef = useRef();

  useEffect(() => {
    const getDataKegiatan = async () => {
      await api
        .kegiatanHarianByKode({ satpamkegiatankode: kode })
        .then(async (resDetail) => {
          setDataKegiatan(resDetail.data.data[0]);
        })
        .catch((err) => console.log(err.message));
    };

    getDataKegiatan();
  }, [kode]);

  return (
    <>
      <Helmet>
        <meta name="viewport" content="width=1024" />
      </Helmet>
      <div className="absolute top-0 right-0 bottom-0 z-20 h-screen w-screen overflow-y-auto bg-white text-dark">
        <div className="flex p-8 justify-center items-center flex-col text-center">
          <div className="font-bold text-xl">Preview Cetak Kegiatan Satpam</div>
          <div className="flex gap-5 mb-3">
            <ReactToPrint
              trigger={() => (
                <button className="bg-dark hover:opacity-80 p-2 rounded-md mt-1 h-fit">
                  <AiFillPrinter size={18} color="white" />
                </button>
              )}
              content={() => componentRef.current}
            />
            <div className="hidden">
              <KegiatanHarianCetakPerKeg
                refPropWithAnotherName={componentRef}
                data={dataKegiatan}
              />
            </div>
            <button
              className="btnAksi h-fit py-[7px] mt-1 text-[13px]"
              onClick={() => navigate(-1)}>
              Kembali
            </button>
          </div>
          <div className="w-full border-4 border-primary p-3">
            <TableCetakKegiatanPerKeg data={dataKegiatan} />
          </div>
        </div>
      </div>
    </>
  );
}
