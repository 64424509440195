import moment from 'moment';
import Swal from 'sweetalert2';
import { AiOutlineFileAdd } from 'react-icons/ai';
import { Carousel } from '@mantine/carousel';
import { HiOutlinePrinter } from 'react-icons/hi';
import { Link } from 'react-router-dom';
import { Modal, Tooltip, Popover } from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import { useSelector } from 'react-redux';
import { useState, useEffect } from 'react';

// Import Files
import * as api from '../../redux/api';
import Cards from '../../components/general/Cards';
import FilterDate from '../../components/general/FilterDate';
import FilterKategori from '../../components/general/FilterKategori';
import FilterStatus from '../../components/general/FilterStatus';
import ModalKejadianDetail from '../../components/kejadian/ModalKejadianDetail';
import ReadMore from '../../components/general/ReadMore';
import Table from '../../components/general/Table';

export default function Kejadian({ role }) {
  const profile = localStorage.profile && JSON.parse(localStorage.profile);
  const adminType = profile.user.adminpetugastipe;

  const [data, setData] = useState([]);
  const [datas, setDatas] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [imageData, setImageData] = useState(null);
  const [kategori, setKategori] = useState('');
  const [kejadianDetailKode, setKejadianDetailKode] = useState('');
  const [openModalDetail, setOpenModalDetail] = useState(false);
  const [showDate, setShowDate] = useState(false);
  const [showImage, setShowImage] = useState(false);
  const [subKategori, setSubKategori] = useState('');

  const [query, setQuery] = useState('');
  const [debouncedQuery] = useDebouncedValue(query, 200);
  const { perusahaanKode, startDateAdmin, endDateAdmin } = useSelector((state) => state.perusahaan);
  const [date, setDate] = useState({
    startDate: startDateAdmin || moment().format('YYYY-MM-DD'),
    endDate: endDateAdmin || moment().format('YYYY-MM-DD')
  });

  useEffect(() => {
    setDate({
      startDate: startDateAdmin,
      endDate: endDateAdmin
    });
  }, [startDateAdmin, endDateAdmin]);

  // Get data
  const getData = async (formDate) => {
    await api.kejadian(formDate).then((res) => {
      setData(res.data.Data);
      setDatas(res.data);
    });
  };

  useEffect(() => {
    const formDate = {
      startdate: date.startDate,
      enddate: date.endDate,
      kategori: kategori,
      kategori_detail: subKategori
    };

    if (perusahaanKode) {
      formDate.satpamperusahaankode = perusahaanKode;
    }

    getData(formDate);
    setShowDate(false);
  }, [date, kategori, perusahaanKode, subKategori]);

  useEffect(() => {
    if (data?.length > 0) {
      setFilterData(
        data.filter(
          ({
            satpamkejadiankode,
            satpamkejadiantanggalmelaporkan,
            satpamkejadiananalisis,
            satpamnamalengkap,
            satpamkejadianuraian,
            satpamkejadiantindakan,
            satpamkejadianhasil,
            satpamkejadianpelaku,
            satpamkejadiankorban,
            satpamkejadiansaksi,
            satpamkejadianpatokan,
            satpamkejadianalamat
          }) => {
            if (
              debouncedQuery !== '' &&
              !`${satpamkejadiankode} ${satpamkejadiantanggalmelaporkan} ${satpamkejadiananalisis} ${satpamnamalengkap} ${satpamkejadianuraian} ${satpamkejadiantindakan} ${satpamkejadianhasil} ${satpamkejadianhasil} ${satpamkejadianpelaku} ${satpamkejadiankorban} ${satpamkejadiansaksi} ${satpamkejadianpatokan} ${satpamkejadianalamat}`
                .toLowerCase()
                .includes(debouncedQuery.trim().toLowerCase())
            ) {
              return false;
            }
            return true;
          }
        )
      );
    }
  }, [data, debouncedQuery]);

  const handleImageData = ({
    satpamkejadianfotosatu,
    satpamkejadianketeranganfotosatu,
    satpamkejadianfotodua,
    satpamkejadianketeranganfotodua,
    satpamkejadianfototiga,
    satpamkejadianketeranganfototiga,
    satpamkejadianfotoempat,
    satpamkejadianketeranganfotoempat,
    satpamkejadianfotolima,
    satpamkejadianketeranganfotolima
  }) => {
    setImageData([
      {
        image: satpamkejadianfotosatu?.split('/data-kejadian/')[1] && satpamkejadianfotosatu,
        keterangan: satpamkejadianketeranganfotosatu
      },
      {
        image: satpamkejadianfotodua?.split('/data-kejadian/')[1] && satpamkejadianfotodua,
        keterangan: satpamkejadianketeranganfotodua
      },
      {
        image: satpamkejadianfototiga?.split('/data-kejadian/')[1] && satpamkejadianfototiga,
        keterangan: satpamkejadianketeranganfototiga
      },
      {
        image: satpamkejadianfotoempat?.split('/data-kejadian/')[1] && satpamkejadianfotoempat,
        keterangan: satpamkejadianketeranganfotoempat
      },
      {
        image: satpamkejadianfotolima?.split('/data-kejadian/')[1] && satpamkejadianfotolima,
        keterangan: satpamkejadianketeranganfotolima
      }
    ]);
    setShowImage(!showImage);
  };

  const handleOpenModalDetail = (kejadiankode) => {
    setKejadianDetailKode(kejadiankode);
    setOpenModalDetail(true);
  };

  const handleDelete = (formData) => {
    const formDate = {
      startdate: date.startDate,
      enddate: date.endDate,
      kategori: kategori,
      kategori_detail: subKategori
    };

    if (perusahaanKode) {
      formDate.satpamperusahaankode = perusahaanKode;
    }

    Swal.fire({
      text: 'Do you want to delete this kejadian?',
      showCancelButton: true,
      confirmButtonText: 'Yes'
    }).then((result) => {
      if (result.isConfirmed) {
        const deleteData = async () => {
          await api
            .kejadianDelete(formData)
            .then((res) => {
              Swal.fire('Good job!', res.data.message, 'success');
              getData(formDate);
            })
            .catch((err) => {
              Swal.fire({ icon: 'error', title: 'Oops...', text: err.response.data.message });
            });
        };
        deleteData();
      }
    });
  };

  // Column Data Table
  const columnData = [
    {
      title: 'Action',
      accessor: 'satpamkejadiankode',
      width: 100,
      render: ({ satpamkejadiankode }) => (
        <div className="flex items-center gap-1">
          {/* Button Aksi */}
          <Popover width={110} position="right" withArrow shadow="xl">
            <Popover.Target>
              <div className="btnAksi my-1">Action</div>
            </Popover.Target>
            <Popover.Dropdown className="popOverDropdown">
              <div
                className="popOverItem"
                onClick={() => handleOpenModalDetail(satpamkejadiankode)}>
                <i className="fa-solid fa-circle-info"></i> Detail
              </div>
              <Link to={`/kejadian-printperkej/${satpamkejadiankode}`}>
                <div className="popOverItem">
                  <i className="fa-solid fa-print"></i> Print
                </div>
              </Link>
              {adminType === 'Admin Project' && (
                <>
                  <Link to={`/kejadian/update/${satpamkejadiankode}`}>
                    <div className="popOverItem">
                      <i className="fa-solid fa-pen"></i> Edit
                    </div>
                  </Link>
                  <div
                    className="popOverItem"
                    onClick={() => handleDelete({ kode: satpamkejadiankode })}>
                    <i className="fa-solid fa-trash mr-[2px]"></i> Delete
                  </div>
                </>
              )}
            </Popover.Dropdown>
          </Popover>
        </div>
      )
    },
    {
      title: 'Foto kejadian',
      accessor: 'satpamkejadianstatus',
      width: 150,
      cellsStyle: {
        verticalAlign: 'top'
      },
      render: ({
        satpamkejadianfotosatu,
        satpamkejadianketeranganfotosatu,
        satpamkejadianfotodua,
        satpamkejadianketeranganfotodua,
        satpamkejadianfototiga,
        satpamkejadianketeranganfototiga,
        satpamkejadianfotoempat,
        satpamkejadianketeranganfotoempat,
        satpamkejadianfotolima,
        satpamkejadianketeranganfotolima
      }) => (
        <div
          className="h-32 mt-1 mb-2 flex justify-center cursor-pointer hover:opacity-80"
          onClick={() =>
            handleImageData({
              satpamkejadianfotosatu,
              satpamkejadianketeranganfotosatu,
              satpamkejadianfotodua,
              satpamkejadianketeranganfotodua,
              satpamkejadianfototiga,
              satpamkejadianketeranganfototiga,
              satpamkejadianfotoempat,
              satpamkejadianketeranganfotoempat,
              satpamkejadianfotolima,
              satpamkejadianketeranganfotolima
            })
          }>
          <img
            src={
              satpamkejadianfotosatu?.split('/data-kejadian/')[1]
                ? satpamkejadianfotosatu
                : satpamkejadianfotodua?.split('/data-kejadian/')[1]
                ? satpamkejadianfotodua
                : satpamkejadianfototiga?.split('/data-kejadian/')[1]
                ? satpamkejadianfototiga
                : satpamkejadianfotoempat?.split('/data-kejadian/')[1]
                ? satpamkejadianfotoempat
                : satpamkejadianfotolima?.split('/data-kejadian/')[1]
                ? satpamkejadianfotolima
                : ''
            }
            alt=""
            className="h-full object-contain rounded-lg"
          />
        </div>
      )
    },
    {
      title: 'Status, Kode, dan Tanggal',
      accessor: 'satpamkejadiantanggalmelaporkan',
      width: 200,
      cellsStyle: {
        verticalAlign: 'top'
      },
      render: ({ satpamkejadiankode, satpamkejadiantanggalmelaporkan, satpamkejadianstatus }) => (
        <div className="py-0 my-0">
          <div className="py-0 my-0">{satpamkejadiankode} &nbsp;</div>
          <div className="py-0 my-0">
            {satpamkejadiantanggalmelaporkan !== undefined &&
              moment(satpamkejadiantanggalmelaporkan).format('DD MMM YYYY HH:mm')}
          </div>
          {Number(satpamkejadianstatus) === 0 ? (
            <div className="border-primary border-2 text-sm text-primary btnStatus block w-full rounded-full text-center mb-2">
              Open
            </div>
          ) : (
            <div className="border-secondary text-sm border-2 text-secondary btnStatus block w-full rounded-full text-center mb-2">
              Closed
            </div>
          )}
        </div>
      )
    },
    {
      title: 'Kategori',
      accessor: 'satpamkejadiankategori',
      width: 200,
      cellsStyle: {
        verticalAlign: 'top'
      },
      render: ({ satpamkejadiankategori }) => (
        <div className="py-0 my-0">
          {satpamkejadiankategori !== null ? satpamkejadiankategori : 'Belum ada kategori'}
        </div>
      )
    },
    // {
    //   title: 'Analisis',
    //   accessor: 'satpamkejadiananalisis',
    //   width: 300,
    //   cellsStyle: {
    //     verticalAlign: 'top'
    //   },
    //   render: ({ satpamkejadiananalisis }) => (
    //     <div className="py-0 my-0">
    //       {satpamkejadiananalisis !== null ? satpamkejadiananalisis : 'Belum ada analisis'}
    //     </div>
    //   )
    // },
    {
      title: 'Nama Personil',
      accessor: 'satpamnamalengkap',
      width: 200,
      cellsStyle: {
        verticalAlign: 'top'
      }
    },
    {
      title: 'Uraian Kejadian',
      accessor: 'satpamkejadianuraian',
      width: 400,
      cellsStyle: {
        verticalAlign: 'top'
      },
      render: ({ satpamkejadianuraian }) => (
        <>{satpamkejadianuraian !== '' && <ReadMore>{satpamkejadianuraian}</ReadMore>}</>
      )
    },
    {
      title: 'Uraian Tindakan',
      accessor: 'satpamkejadiantindakan',
      width: 400,
      cellsStyle: {
        verticalAlign: 'top'
      },
      render: ({ satpamkejadiantindakan }) => (
        <>{satpamkejadiantindakan !== '' && <ReadMore>{satpamkejadiantindakan}</ReadMore>}</>
      )
    },
    {
      title: 'Hasil Tindakan',
      accessor: 'satpamkejadianhasil',
      width: 400,
      cellsStyle: {
        verticalAlign: 'top'
      },
      render: ({ satpamkejadianhasil }) => (
        <>{satpamkejadianhasil !== '' && <ReadMore>{satpamkejadianhasil}</ReadMore>}</>
      )
    },
    {
      title: 'Pelaku',
      accessor: 'satpamkejadianpelaku',
      width: 125,
      cellsStyle: {
        verticalAlign: 'top'
      }
    },
    {
      title: 'Korban',
      accessor: 'satpamkejadiankorban',
      width: 125,
      cellsStyle: {
        verticalAlign: 'top'
      }
    },
    {
      title: 'Saksi',
      accessor: 'satpamkejadiansaksi',
      width: 125,
      cellsStyle: {
        verticalAlign: 'top'
      }
    },
    {
      title: 'Patokan lokasi',
      accessor: 'satpamkejadianalamatpatokan',
      width: 150,
      cellsStyle: {
        verticalAlign: 'top'
      }
    },
    {
      title: 'Alamat',
      accessor: 'satpamkejadianalamat',
      width: 300,
      cellsStyle: {
        verticalAlign: 'top'
      }
    }
  ];

  return (
    <div className={`w-full min-h-screen p-5 ${role === 'Admin Project' ? 'mt-1' : '-mt-10'}`}>
      {/* Cards */}
      <Cards
        title="Statistik Kejadian"
        desc="Berikut ini adalah jumlah kejadian yang terjadi"
        card1={{ title: 'Open', value: datas?.Total_Open || 0 }}
        card2={{ title: 'Close', value: datas?.Total_Close || 0 }}
        card3={{ title: 'Total', value: datas?.Total_Data || 0 }}
      />

      <div className="mt-10 w-full justify-center bg-gray-50 rounded-lg shadowAll">
        <div className="flex flex-wrap lg:flex-nowrap justify-beetween items-center">
          <div className="flex flex-col md:flex-row flex-wrap justify-start items-center w-full">
            <FilterDate
              date={date}
              setDate={setDate}
              showDate={showDate}
              setShowDate={setShowDate}
            />
            <FilterKategori
              kategori={kategori}
              setKategori={setKategori}
              subKategori={subKategori}
              setSubKategori={setSubKategori}
              page="kejadian"
            />
            <FilterStatus laporan="kejadian" data={data} setFilterData={setFilterData} />
          </div>
          <div className="my-3 px-5 flex justify-end">
            <div className="flex gap-4 w-fit">
              {role === 'Admin Project' && (
                <Tooltip label="Tambah kejadian">
                  <Link to="/kejadian/create">
                    <div className="bg-customGray p-2 rounded-md cursor-pointer hover:opacity-80">
                      <AiOutlineFileAdd size={24} />
                    </div>
                  </Link>
                </Tooltip>
              )}
              <Link to={`/kejadian-print`} state={{ data: data, date: date }}>
                <div className="bg-customGray p-2 rounded-md cursor-pointer hover:opacity-80 flex items-center text-gray-700">
                  <HiOutlinePrinter size={24} />
                </div>
              </Link>
            </div>
          </div>
        </div>
        {/* Table Component */}
        {datas.message === 'Data empty' ? (
          <div className="text-center p-5">
            <div className="mt-10">Tidak ada data kejadian.</div>
            <div className="mb-72">Silahkan memilih rentang tanggal dan kategori lainnya!</div>
          </div>
        ) : (
          <div className="p-5">
            <Table
              data={filterData}
              columnData={columnData}
              setQuery={setQuery}
              id="satpamkejadianid"
            />
          </div>
        )}
      </div>

      {/* Modal Detail Kejadian */}
      <ModalKejadianDetail
        kode={kejadianDetailKode}
        openModalDetail={openModalDetail}
        setOpenModalDetail={setOpenModalDetail}
      />

      {/* Modal Image */}
      <Modal
        centered
        size="xl"
        opened={showImage}
        onClose={() => setShowImage(false)}
        title="Detail Kejadian"
        className="overflow-auto bg-transparent scrollbar-thin">
        {/* Content image */}
        <Carousel mx="auto" withIndicators height="auto" dragFree slideGap="md" align="start">
          {imageData &&
            imageData
              .filter((data) => data.image.split('/data-kejadian/')[1])
              .map((item, i) => (
                <Carousel.Slide key={i} className="w-full">
                  {item.image && (
                    <img
                      src={item.image}
                      alt=""
                      className="w-full object-contain rounded-t-lg flex items-center justify-center"
                    />
                  )}
                  {item.keterangan && (
                    <div className="bg-primary text-gray-50 text-center text-[14px] rounded-b-lg py-2">
                      {item.keterangan}
                    </div>
                  )}
                </Carousel.Slide>
              ))}
          {/* ...other slides */}
        </Carousel>
      </Modal>
    </div>
  );
}
